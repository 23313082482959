<template>
    <div>
        <InputsContactInfo
            class="client-item-modal-contact-data"
            :isModal="true"
            :saving="saving"
            @validityChange="(e) => $emit('validityChange', { isValid: e.isValid })"
        />

        <hr class="mb-3" />

        <InputsInvoicesEmails :saving="saving" />

        <hr class="mt-3 mb-3" />

        <InputsMaintenanceEmails :saving="saving" />
    </div>
</template>

<script>
import InputsContactInfo from './InputsContactInfo.vue'
import InputsInvoicesEmails from './InputsInvoicesEmails.vue'
import InputsMaintenanceEmails from './InputsMaintenanceEmails.vue'

export default {
    name: 'ClientItemModalContactData',

    components: {
        InputsContactInfo,
        InputsInvoicesEmails,
        InputsMaintenanceEmails,
    },

    props: {
        saving: {
            type: Boolean,
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.client-item-modal-contact-data {
    max-height: 72vh;
    overflow: auto;

    &__buttons {
        display: flex;
        gap: 3.2rem;
    }
}
</style>
