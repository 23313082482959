import {
    apiPost,
    apiGet,
    apiDownload,
    handleError,
    refreshError,
} from '@/helpers/api'
import { add, parseISO, addBusinessDays } from 'date-fns'

const namespaced = true

const state = () => ({
    client: '',
    contract: '',
    contracts: [],

    existingServices: [],
    serviceName: '',
    serviceByOEM: false,
    serviceDates: [],
    serviceInterval: '',
    firstServiceDate: '',

    tracksDropdownOptions: [],
    track: '',
    servicesDatesDropdownOptions: [],
    serviceDate: '',
    filesUploadedOem: [],
    filesUploadedClient: [],
    uploadRouteAuthUser: '',

    // contract data
    contractId: '',
    contractBeginDate: '',
    contractEndingDate: '',

    // file upload section
    uploadRouteWithLink: '',
    protocolType: '',
    fileUploadLink: '',
    serviceId: '',

    overview: {
        pages: null,
        lastPage: 1,
    },
})

const mutations = {
    UPDATE_INPUT(state, { name, value }) {
        state[name] = value
    },

    RESET_CONTRACT_SELECT_STATE(state) {
        state.client = ''
        state.contract = ''
        state.contracts = []
    },

    RESET_SERVICE_TRACK_STATE(state) {
        state.serviceName = ''
        state.serviceByOEM = ''
        state.serviceDates = []
        state.serviceInterval = ''
        state.firstServiceDate = ''
    },

    UPDATE_OVERVIEW_PAGES(state, { page, pageArray }) {
        if (state.overview.pages === null) state.overview.pages = {}
        const newPage = { [page]: pageArray }
        state.overview.pages = { ...state.overview.pages, ...newPage }
    },

    SET_LAST_PAGE(state, { lastPage }) {
        state.overview.lastPage = lastPage
    },

    UPDATE_SERVICE(state, { service, page }) {
        const pageCopy = state.overview.pages[page]
        const index = pageCopy.findIndex((el) => el.id === service.id)
        const uniqueId = _.uniqueId()
        pageCopy[index] = { ...service, uniqueId }

        state.overview.pages[page] = pageCopy
    },
}

const actions = {
    updateInput({ commit }, { fieldName, value }) {
        commit('UPDATE_INPUT', { name: fieldName, value })
    },

    resetTrackState({ commit }) {
        commit('RESET_SERVICE_TRACK_STATE')
    },

    updateServiceProp({ rootState, commit }, { propName, serviceId }) {
        let serviceArrayCopy = []
        let pageNumber = 0

        Object.keys(rootState.pagination.pages).forEach((page) => {
            rootState.pagination.pages[page].forEach((service) => {
                if (service.id === serviceId) {
                    pageNumber = page
                    service[propName] = 1
                    serviceArrayCopy = [...rootState.pagination.pages[page]]
                }
            })
        })

        commit(
            'pagination/UPDATE_OVERVIEW_PAGES',
            {
                page: pageNumber,
                pageArray: serviceArrayCopy,
            },
            { root: true }
        )
    },

    async fetchContracts({ commit, dispatch }, { vm, client }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(vm.route('client.contracts', client))
            const data =
                Object.keys(response).length > 0
                    ? Object.keys(response).map((key) => ({
                          label: response[key],
                          value: key,
                      }))
                    : []
            commit('UPDATE_INPUT', { name: 'contracts', value: [...data] })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fetchContract({ commit, dispatch }, { vm, contract }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('services.show', { contract })
            )
            if (response === null) {
                return;
            }

            // create date from the response
            const parsedDay = parseISO(response.contract_begin)

            commit('UPDATE_INPUT', {
                name: 'contractId',
                value: response.contract_id,
            })

            // calculate 21 days from the contract begin
            const beginDate = addBusinessDays(parsedDay, 21)
            // add duration to calculate contract end
            const endDate = add(beginDate, {
                months: Number(response.contract_duration),
            })

            commit('UPDATE_INPUT', {
                name: 'contractBeginDate',
                value: beginDate,
            })
            commit('UPDATE_INPUT', {
                name: 'contractEndingDate',
                value: endDate,
            })

            const existingServices = [...response.contract_tracks]

            commit('UPDATE_INPUT', {
                name: 'existingServices',
                value: existingServices,
            })

            const tracksDropdownOptions = existingServices.map((el) => ({
                value: el,
                label: el,
            }))

            commit('UPDATE_INPUT', {
                name: 'tracksDropdownOptions',
                value: tracksDropdownOptions,
            })

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async saveServiceTrack({ commit, dispatch, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const dates = state.serviceDates.map((el) => el.id)
            const response = await apiPost(
                vm.route('services.store.dates', {
                    contract: state.contractId,
                }),
                {
                    track_name: state.serviceName,
                    dates,
                    service_by_oem: !!state.serviceByOEM,
                }
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                commit('modal/SET_CLOSE', null, { root: true })
                commit('RESET_SERVICE_TRACK_STATE')
                dispatch('fetchContract', { vm, contract: state.contractId })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async fetchServiceTrack({ state, dispatch, commit }, { vm, serviceName }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('services.show_track', {
                    contract: state.contract,
                    track_name: serviceName,
                })
            )
            if (response === null) {
                return;
            }
            const dates = Object.values(response).map((date) => {
                return {
                    date: parseISO(date),
                    id: date,
                }
            })

            commit('UPDATE_INPUT', { name: 'serviceName', value: serviceName })
            commit('UPDATE_INPUT', {
                name: 'firstServiceDate',
                value: dates[0].date,
            })
            commit('UPDATE_INPUT', { name: 'serviceDates', value: dates })

            // used in upload protocols
            const servicesDatesDropdownOptions = Object.keys(response).map(
                (key) => {
                    return {
                        value: key,
                        label: response[key].split('-').reverse().join('.'),
                    }
                }
            )
            commit('UPDATE_INPUT', {
                name: 'servicesDatesDropdownOptions',
                value: servicesDatesDropdownOptions,
            })

            dispatch('hideLoader', null, { root: true })

            // used in the new service modal
            commit(
                'modal/SET_OPEN',
                {
                    name: 'NewServiceModal',
                    size: 'small',
                    title: 'service_dashboard.modal_heading',
                },
                { root: true }
            )
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fetchAllContractServices({ dispatch, commit }, { vm, contract }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(
                vm.route('services.show', {
                    contract,
                })
            )
            if (data === null) {
                return;
            }

            const servicesDatesDropdownOptions = []
            const filesUploadedOem = []
            const filesUploadedClient = []

            data.contract_services.forEach((service) => {
                if (service.file_oem === 1) {
                    filesUploadedOem.push(service.id)
                }

                if (service.file_client === 1) {
                    filesUploadedClient.push(service.id)
                }

                servicesDatesDropdownOptions.push({
                    value: service.id,
                    label: service.date.split('-').reverse().join('.'),
                })
            })

            commit('UPDATE_INPUT', {
                name: 'servicesDatesDropdownOptions',
                value: servicesDatesDropdownOptions,
            })

            commit('UPDATE_INPUT', {
                name: 'filesUploadedOem',
                value: filesUploadedOem,
            })

            commit('UPDATE_INPUT', {
                name: 'filesUploadedClient',
                value: filesUploadedClient,
            })

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async setServicePropDone({ dispatch }, { vm, serviceId, propName, route }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(route)

            if (response.status === 'success') {
                dispatch('updateServiceProp', { serviceId, propName })
            }
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async fileDownload({ dispatch }, { link, fileName }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiDownload(link, fileName)
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async sendReminderEmail({ dispatch }, { vm, serviceId }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('services.send_reminder_email', { service: serviceId })
            )

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.service_reminder_email_sent'),
                }

                dispatch('notification/add', notification, { root: true })
            }
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getClient: (state) => state.client,
    getClients: (state) => state.clients,
    getContract: (state) => state.contract,
    getContracts: (state) => state.contracts,
    getProtocolType: (state) => state.protocolType,
    getServiceDates: (state) => state.serviceDates,
    getExistingServices: (state) => state.existingServices,
    isExistingService: (state) => (serviceName) =>
        state.existingServices.includes(serviceName),
    getTracksDropdownOptions: (state) => state.tracksDropdownOptions,
    getTrack: (state) => state.track,
    getServicesDatesDropdownOptions: (state) =>
        state.servicesDatesDropdownOptions,
    getServiceDate: (state) => state.serviceDate,
    getFilesUploadedOem: (state) => state.filesUploadedOem,
    getFilesUploadedClient: (state) => state.filesUploadedClient,
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
