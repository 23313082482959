<template>
    <div class="select-item-row mb-3" @click="handleClick">
        <CoreRadio
            :id="item.id"
            :value="$attrs.value"
            :optionValue="item.id"
            :name="radioGroupName"
            :label="item.name"
        />
        <span>{{ item.industry }}</span>
    </div>
</template>

<script>
import CoreRadio from '@/components/core/CoreRadio'

export default {
    name: 'ItemRow',

    components: { CoreRadio },

    props: {
        item: {
            type: Object,
            required: true,
        },

        radioGroupName: {
            type: String,
            required: true,
        },
    },

    computed: {
        classes() {
            return {
                'mb-3': true,
                'select-item-row': true,
                'select-item-row--selected': this.item.id === this.$attrs.value,
            }
        },
    },

    methods: {
        handleClick() {
            this.$emit('input', this.item.id)
        },
    },
}
</script>

<style scoped lang="scss">
.select-item-row {
    border: 1px solid $cn-75;
    outline: 1px solid transparent;
    border-radius: 8px;

    padding: 16px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    & > div,
    & > span {
        flex: 1 1 50%;
    }

    & > span {
        color: $cs-25;
    }

    &--selected {
        outline: 1px solid $cn-75;
    }

    &:hover {
        outline: 1px solid $cn-75;
        cursor: pointer;
    }
}
</style>
