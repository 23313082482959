<template>
    <div class="wrapper">
        <div>
            <BaseLabel
                :weight="labelsOptions.weight"
                :size="labelsOptions.size"
            >
                {{ $t('invite.email') }}
            </BaseLabel>
            <div class="input-group">
                <input
                    class="input-group__input"
                    :value="email"
                    ref="email"
                    @keydown="handleChange($event)"
                />
                <div
                    class="input-group__icon"
                    @click="callCopyOrSelectAction('email')"
                >
                    <span class="tooltiptext">
                        {{ getButtonText }}
                    </span>
                    <mdicon
                        class="icon--color-primary"
                        name="clipboard-plus-outline"
                    />
                </div>
            </div>

            <BaseInput class="mb-40" :value="validUntil" :disabled="true">
                <template #label>
                    <BaseLabel
                        :weight="labelsOptions.weight"
                        :size="labelsOptions.size"
                    >
                        {{ $t('invite.valid_until') }}
                    </BaseLabel>
                </template>
            </BaseInput>

            <BaseLabel
                :weight="labelsOptions.weight"
                :size="labelsOptions.size"
            >
                {{ $t('invite.invitation_link') }}
            </BaseLabel>
            <div class="input-group">
                <input
                    class="input-group__input"
                    :value="invitationLink"
                    ref="invitationLink"
                    @keydown="handleChange($event)"
                />
                <div
                    class="input-group__icon"
                    @click="callCopyOrSelectAction('invitationLink')"
                >
                    <span class="tooltiptext">
                        {{ getButtonText }}
                    </span>
                    <mdicon
                        class="icon--color-primary"
                        name="clipboard-plus-outline"
                    />
                </div>
            </div>
        </div>
        <div class="form__buttons">
            <BaseButton buttonClass="button--primary" @click="handleResend">
                {{ $t('ui.button_resend') }}
            </BaseButton>
            <BaseButton buttonClass="button--secondary" @click="handleClose">
                {{ $t('ui.button_close') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'InviteItemModal',

    data() {
        return {
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },
            id: '',
            email: '',
            validUntil: '',
            invitationLink: '',
        }
    },
    computed: {
        getButtonText() {
            if (navigator.clipboard) {
                return this.$t('invite.copy_to_clipboard')
            }
            return this.$t('invite.select')
        },
    },
    mounted() {
        this.handleData({ ...this.$store.state.modal.data })
    },

    methods: {
        ...mapActions({
            showNotification: 'notification/add',
            resendInvite: 'invite/resendInvite',
        }),
        handleResend() {
            this.resendInvite({ vm: this, id: this.id })
        },
        handleClose() {
            this.$emit('close-modal')
        },
        handleData(data) {
            this.id = data.id
            this.email = data.email
            this.validUntil = data.validUntil
            this.invitationLink = data.invitationLink
        },
        callCopyOrSelectAction(propName) {
            if (navigator.clipboard) {
                this.copyToClipboard(propName)
            } else {
                this.$refs.invite.select()
            }
        },
        async copyToClipboard(propName) {
            const triggerNotification = (notification) => {
                this.showNotification(notification)
            }

            const isAllowed = await this.checkClipboardPermission()

            if (isAllowed) {
                navigator.clipboard.writeText(this[propName]).then(
                    function () {
                        triggerNotification({
                            type: 'success',
                            text: 'notifications.success_clipboard_copy',
                        })
                    },
                    function () {
                        triggerNotification({
                            type: 'error',
                            text: 'notifications.error_clipboard_copy',
                        })
                    }
                )
            }
        },

        checkClipboardPermission() {
            // firefox has clipboard-write permission true by default
            if (navigator.userAgent.indexOf('Firefox') > -1) {
                return true
            }

            // safari does not support permission API
            if (navigator.userAgent.indexOf('Safari') > -1) {
                return true
            }

            const result = navigator.permissions.query({
                name: 'clipboard-write',
            })

            return result
        },

        // disable chaning value on key pressed
        handleChange(e) {
            e.preventDefault()
        },
    },
}
</script>

<style lang="scss" scoped>
.wrapper {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    .note {
        color: rgba($vc-primary-dark-1, 0.7);
        font-size: 18px;
        font-weight: $vfw-normal;
        margin: 15px 0 40px 0;
    }

    .input-group {
        display: flex;
        margin-bottom: 40px;

        &__input {
            flex-grow: 1;

            width: auto;
            border: 1px solid $cn-75;
            border-radius: 16px;
            padding: 10px 40px 10px 10px;

            overflow-x: hidden;

            text-align: left;
            font-size: 18px;
            color: $cs;
            white-space: nowrap;

            outline: none;
        }

        &__icon {
            height: 48px;
            flex: 0 0 48px;
            border-radius: 50px;
            background-color: $vc-gray-light;
            margin-left: 20px;
            cursor: pointer;

            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: $vc-gray;
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}

.tooltiptext {
    position: absolute;
    bottom: 130%;
    left: -40%;
    visibility: hidden;
    padding: 5px 15px;
    border-radius: 5px;
    background: rgba($vc-primary, 0.8);
    transition: 0.2s;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);

    font-size: 14px;
    color: $vc-white;

    &::after {
        content: '';
        position: absolute;
        bottom: -16px;
        left: 50%;
        transform: translate(-50%, 0);
        height: 1px;
        width: 1px;
        background: transparent;
        border: 8px solid transparent;
        border-top: 8px solid rgba($vc-primary, 0.8);
    }
}

.invite__icon:hover .tooltiptext {
    visibility: visible;
}
</style>
