<template>
    <div
        class="login-page"
        :style="{
            'background-image': `url('/assets/images/fe_background.png')`,
        }"
    >
        <img
            src="@/assets/images/linxfour.png"
            class="login-page__logo"
        />

        <div class="login">
            <h1 class="heading-300 text-center">
                {{ $t('§public.set_new_password_heading') }}
            </h1>
            <CoreStringInput
                v-model="inputs.email"
                :validator="v$.email"
                :fullWidth="true"
                placeholder="§ui.placeholder_login_email"
                class="mb-3"
            />

            <CorePasswordInput
                v-model="inputs.password"
                :validator="v$.password"
                class="mb-3"
                :fullWidth="true"
            />

            <CorePasswordInput
                v-model="inputs.passwordConfirm"
                :validator="v$.passwordConfirm"
                class="mb-3"
                :fullWidth="true"
            />

            <CoreButtonPrimary
                @click="handleSubmit"
                label="§ui_button.save"
                icon="pi-arrow-up-right"
                :loading="waiting"
                :disabled="!isValid"
            />
        </div>
        <NotificationContainer />
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength, maxLength } from '@vuelidate/validators'
import NotificationContainer from '@/components/NotificationContainer.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import CorePasswordInput from '@/components/core/CorePasswordInput.vue'
import { handleError } from '@/helpers/api'
import { getUserBrowserPreferredLocale } from '@/helpers/mixins.js'

const isSame = (inputs) => (value) => inputs.password === value

export default {
    name: 'SetNewPassword',

    components: {
        NotificationContainer,
        CoreButtonPrimary,
        CoreStringInput,
        CorePasswordInput,
    },

    mixins: [getUserBrowserPreferredLocale],

    data() {
        return {
            inputs: {
                email: '',
                password: '',
                passwordConfirm: '',
            },
            waiting: false,

            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },

        rules() {
            return {
                email: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(100),
                    email,
                },
                password: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(100),
                },
                passwordConfirm: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(100),
                    sameAsPassword: isSame(this.inputs),
                },
            }
        },
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.inputs)
        this.setLocale()
        this.setEmail()
    },

    methods: {
        async handleSubmit() {
            try {
                if (this.waiting) return

                this.waiting = true
                await this.$store.dispatch('user/setNewPassword', {
                    token: this.$attrs.token,
                    email: this.inputs.email,
                    passwordConfirm: this.inputs.passwordConfirm,
                    password: this.inputs.password,
                    vm: this,
                })
                this.waiting = false
            } catch (error) {
                this.waiting = false
                handleError(this.$store.dispatch, error)
            }
        },

        setEmail() {
            const url = new URL(window.location)
            const searchParams = new URLSearchParams(url.search)
            this.email = searchParams.get('email')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
@import '../../assets/styles/theme.css';

.login-page {
    width: 100%;
    min-height: 100vh;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__logo {
        position: absolute;
        left: 3vw;
        top: 0;

        max-width: 250px;
    }

    .login {
        position: relative;
        z-index: 1;

        background: $cn-10;
        box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        padding: 35px 100px;
        width: 40%;

        h1 {
            margin-bottom: 35px;
            font-weight: 300;
        }
    }

    &__forgot-password {
        display: flex;
    }
}
</style>
