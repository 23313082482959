<template>
    <div class="equipment-connectivity-modal">
        <CoreTwoColumnsRow class="mb-1">
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :condensed="true"
                >
                    <span>{{ $t('§equipment.equipment_connection_type') }}</span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreStringInput
                    v-model="inputs.connection_type"
                    placeholder="§ui.placeholder_equipment_connection_type"
                    :fullWidth="true"
                    :condensed="true"
                />
            </template>
        </CoreTwoColumnsRow>

        <JsonEditorVue
            v-model="inputs.configuration"
            :stringified="false"
            mode="text"
            class="json-editor mb-5"
        />

        <CoreButtonPrimary
            label="§ui_button.save"
            icon="pi-check"
            @click="save"
            :disabled="isSaveDisabled"
        />
    </div>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'

export default {
    name: 'EquipmentConnectivityModal',

    components: {
        JsonEditorVue,
        CoreTwoColumnsRow,
        CoreBorderWrapper,
        CoreStringInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    data() {
        return {
            unit: null,
            inputs: {
                connection_type: '',
                configuration: '',
            },
        }
    },

    computed: {
        isSaveDisabled() {
            return false
        },
    },

    mounted() {
        this.setData()
    },

    methods: {
        setData() {
            this.unit = this.$store.state.unit.inputs.id
            this.inputs.connection_type =
                this.$store.state.unit.connectivity_settings.connection_type
            this.inputs.configuration = JSON.parse(
                this.$store.state.unit.connectivity_settings.configuration
            )
        },

        async save() {
            await this.$store.dispatch('unit/saveConnectivity', {
                vm: this,
                id: this.$store.state.unit.inputs.id,
                connection_type: this.inputs.connection_type,
                configuration: JSON.stringify(this.inputs.configuration),
            })

            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss">
.equipment-connectivity-modal {
    .json-editor {
        & .jse-json-node.jse-root.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm,
        .jse-main.svelte-ybuk0j {
            min-height: 500px;
        }
    }
}
</style>
