<template>
    <div :class="wrapperClasses">
        <CoreTwoColumnsRow>
            <template #left><slot name="heading"></slot></template>
            <template #right>
                <div class="info-accordion__right-side-wrapper">
                    <div>
                        <slot name="additional-chip"></slot>
                    </div>
                    <div
                        class="count-chip"
                        @click="setIsOpen(!isOpen)"
                    >
                        <span class="count-chip__label">
                            {{ itemsCount }}
                            {{
                                itemsCount === 1
                                    ? $t(itemsCountSuffixSingular)
                                    : $t(itemsCountSuffixPlural)
                            }}
                        </span>
                        <span>
                            <mdicon
                                v-if="isOpen"
                                :class="chipsIconColor"
                                name="chevron-down"
                            />
                            <mdicon
                                v-else
                                :class="chipsIconColor"
                                name="chevron-left"
                            />
                        </span>
                    </div>
                </div>
            </template>
        </CoreTwoColumnsRow>
        <div
            v-if="isOpen"
            class="info-accordion__panel"
        >
            <slot name="panel"></slot>
        </div>
    </div>
</template>

<script>
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow.vue'

export default {
    name: 'InfoAccordion',

    components: { CoreTwoColumnsRow },

    props: {
        itemsCount: {
            required: true,
            type: [Number, String],
        },

        itemsCountSuffixSingular: {
            required: true,
            type: String,
        },

        itemsCountSuffixPlural: {
            required: true,
            type: String,
        },

        showBorders: {
            type: Boolean,
            default: true,
        },

        passIsOpen: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isOpen: false,
        }
    },

    watch: {
        isOpen(newValue) {
            this.$emit('isOpen', newValue)
        },

        passIsOpen(newValue) {
            this.setIsOpen(newValue)
        },
    },

    computed: {
        chipsIconColor() {
            if (this.itemsCount === 0) return 'icon--color-secondary-25'

            return 'icon--color-secondary'
        },

        wrapperClasses() {
            return {
                'info-accordion': true,
                'info-accordion--show-borders': this.showBorders,
            }
        },
    },

    methods: {
        setIsOpen(value) {
            if (this.itemsCount > 0 && value !== this.isOpen) {
                this.isOpen = value
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.info-accordion {
    margin: 4px 0;

    &--show-borders {
        border: 1px solid $cn-75;
        border-radius: 8px;
    }

    .count-chip {
        display: flex;
        align-items: center;
        gap: 12px;
        border-radius: 8px;
        padding: 4px 12px;
        background-color: $cn-75;

        cursor: pointer;

        &__label {
            white-space: nowrap;
        }
    }

    &__right-side-wrapper {
        display: flex;
        gap: 8px;
    }

    .info-accordion__panel {
        margin: 8px 0px 24px 0px;
    }

    &--show-borders .info-accordion__panel {
        margin: 8px 24px 24px 42px;
    }
}
</style>
