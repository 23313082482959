<template>
    <span
        v-if="to === null"
        :class="classes"
        @click="$emit('click')"
    >
        {{ $t(text) }}
        <mdicon
            v-if="iconName !== null"
            class="core-link-primary__icon"
            :name="iconName"
            size="16"
        />
    </span>

    <router-link
        v-else
        :class="classes"
        :to="to"
    >
        {{ $t(text) }}
        <mdicon
            v-if="iconName !== null"
            class="core-link-primary__icon"
            :name="iconName"
            size="16"
        />
    </router-link>
</template>

<script>
export default {
    name: 'CoreLinkPrimary',

    props: {
        to: {
            type: Object,
            default: null,
        },

        text: {
            type: String,
            default: '',
        },

        iconName: {
            type: String,
            default: null,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        linkColorBlue: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-link-primary': true,
                'core-link-primary--condensed': this.condensed,
                'core-link-primary--color-blue': this.linkColorBlue,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
span.core-link-primary,
a.core-link-primary,
a.core-link-primary:visited {
    text-decoration: none;
    font-size: 1.6rem;
    padding: 0.6rem 0.1rem;
    border: 1px solid transparent;
    border-bottom: 1px solid $cs;
    cursor: pointer;

    display: flex;
    align-items: center;
    gap: 16px;

    &:hover {
        border-bottom: 1px solid $cp-75;
    }

    &:focus {
        border: 1px solid $cp-75;
    }

    &:active {
        color: $cp-75;
        border-bottom: 1px solid $cp-75;
    }

    & > span.mdi {
        height: 16px;

        & > svg {
            vertical-align: top;
        }
    }
}

span.core-link-primary.core-link-primary--color-blue,
a.core-link-primary.core-link-primary--color-blue,
a.core-link-primary.core-link-primary--color-blue:visited {
    color: $color-link;

    &:hover {
        border-bottom: 1px solid $color-link;
    }

    &:focus {
        border: 1px solid $color-link;
    }

    &:active {
        color: $color-link;
        border-bottom: 1px solid $color-link;
    }

    & > span.mdi {
        color: $color-link;
    }
}

span.core-link-primary--condensed.core-link-primary,
a.core-link-primary--condensed.core-link-primary,
a.core-link-primary--condensed.core-link-primary:visited {
    padding: 0 0.1rem;
}
</style>
