import { store } from '../store'

export function blockForRoles(rolesToBlock, redirectRoute = '/app/home') {
    return function(to, from, next) {
        const checkPermissionsLoaded = () => {
            if (store.state.user.user.permissions.length === 0) {
                setTimeout(checkPermissionsLoaded, 100);
            } else {
                const userHasAnyOfTheRoles = rolesToBlock.some(role => store.state.user.user.permissions.includes(role));

                if (userHasAnyOfTheRoles) {
                    window.location.href = window.location.origin + redirectRoute;
                } else {
                    next();
                }
            }
        };

        checkPermissionsLoaded();
    }
}
