<template>
    <div class="cms-wrapper">
        <MultiSelect
            v-model="selected"
            :options="options"
            optionLabel="label"
            :class="classes"
            :disabled="disabled"
            :showToggleAll="false"
            :placeholder="placeholder"
            :name="name"
        >
            <template #value="slotProps">
                <span v-if="slotProps.value && slotProps.value.length === 1">
                    {{ slotProps.value[0].label }}
                </span>
                <span v-else-if="slotProps.value && slotProps.value.length > 1">
                    {{ slotProps.value[0].label }}, ...
                </span>
            </template>
        </MultiSelect>

        <InputNotification
            v-if="isInputNotificationShown"
            :validator="validator"
            :customNotificationMessage="customNotificationMessage"
        />
    </div>
</template>

<script>
import MultiSelect from 'primevue/multiselect'
import InputNotification from '@/components/InputNotification.vue'

export default {
    name: 'CoreMultiSelect',

    components: { MultiSelect, InputNotification },

    props: {
        name: {
            type: String,
            default: '',
        },

        options: {
            required: true,
            type: Array,
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        validator: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        placeholder: {
            type: String,
            default: null,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        selected: {
            get() {
                return this.options.filter((option) => this.$attrs.value.includes(option.value))
            },

            set(newValue) {
                const selectedValues = newValue.map((obj) => obj.value)
                this.$emit('input', selectedValues)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        classes() {
            return {
                'core-multi-select': true,
                'core-multi-select--full-width': this.fullWidth,
                'core-multi-select--condensed': this.condensed,
                'core-multi-select--valid':
                    this.validator && !this.validator.$invalid && this.validator.$dirty,
                'core-multi-select--invalid':
                    this.validator && this.validator.$invalid && this.validator.$dirty,
                'core-multi-select--warning': false,
            }
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },

        isInputNotificationShown() {
            if (this.customNotificationMessage !== null) return true

            if (this.validator && this.validator.$dirty) return true

            return false
        },
    },
}
</script>

<style lang="scss">
.cms-wrapper {
    position: relative;
}

.core-multi-select {
    width: 100%;

    & > .p-multiselect-label-container {
        padding: 1.6rem 1rem !important;
        font-size: 1.8rem !important;
    }

    & .p-hidden-accessible {
        position: absolute;
        visibility: hidden;
    }

    &--condensed > .p-multiselect-label-container {
        padding: 0.6rem !important;
        font-size: 1.6rem !important;
    }

    & > .p-multiselect-trigger {
        margin: 0 1.4rem;
    }

    & .p-multiselect-label.p-placeholder {
        color: $cs-25;
    }

    &.core-multi-select--invalid.p-multiselect.p-component,
    &.p-multiselect.p-component:focus,
    &.p-multiselect.p-component:hover {
        border: 1px solid $color-error;
    }

    &.core-multi-select--warning.p-multiselect.p-component,
    &.p-multiselect.p-component:focus,
    &.p-multiselect.p-component:hover {
        border: 1px solid $color-warning;
    }

    &.core-multi-select--valid.p-multiselect.p-component,
    &.p-multiselect.p-component:focus,
    &.p-multiselect.p-component:hover {
        border: 1px solid $cp;
    }
}
</style>
