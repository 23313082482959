<template>
    <div class="modal-wrapper">
        <CoreStringInput
            v-model="name"
            :fullWidth="true"
            placeholder="§ui.placeholder_input_name"
            class="mb-3"
        />
        <div class="create-industry-modal-buttons">
            <CoreButtonPrimary
                :disabled="!isValid"
                label="§ui_button.save"
                @click="handleSave"
            />
        </div>
    </div>
</template>

<script>
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'

export default {
    name: 'CreateItemModal',

    components: {
        CoreStringInput,
        CoreButtonPrimary,
    },

    data() {
        return {
            name: '',
        }
    },

    computed: {
        isValid() {
            return this.name.length > 1
        },
    },

    methods: {
        handleSave() {
            this.$store.dispatch('saveViewVarsItem', {
                vm: this,
                type: this.$store.state.modal.data.type,
                route: this.$store.state.modal.data.route,
                name: this.name,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    padding: 40px;

    .create-industry-modal-buttons {
        width: 100%;
        margin-top: 40px;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
