<template>
    <div>
        <slot
            :value="valueFromStore"
            :handleInput="emitChange"
        />
    </div>
</template>

<script>
export default {
    name: 'StoreAwareInputWrapper',
    props: {
        storePath: {
            type: String,
            required: true,
        },
    },
    computed: {
        valueFromStore() {
            let paths = this.storePath.split('.')
            return paths.reduce((acc, path) => acc[path], this.$store.state)
        },
        storeVariableToUpdate() {
            let paths = this.storePath.split('.')
            return paths.pop()
        },
    },
    methods: {
        emitChange(value) {
            this.$emit('update', { name: this.storeVariableToUpdate, value })
        },
    },
}
</script>
