<template>
    <form
        class="authorized-person-modal"
        @submit.prevent="handleSave"
    >
        <CorePageLoader :loading="saving" />

        <CoreTwoColumnsRow
            v-if="!showOnlyPassportUpload"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper
                    :condensed="true"
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                >
                    {{ $t('§eq_operator.auth_person_first_name') }}
                    {{ isFieldRequired('first_name') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    ref="firstNameInput"
                    placeholder="§ui.placeholder_auth_person_first_name"
                    name="first_name"
                    :condensed="true"
                    :disabled="disableInputs"
                    :validator="v$.first_name"
                    :value="inputs.first_name"
                    @input="setInput('first_name', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow
            v-if="!showOnlyPassportUpload"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper
                    :condensed="true"
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                >
                    {{ $t('§eq_operator.auth_person_last_name') }}
                    {{ isFieldRequired('last_name') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_auth_person_last_name"
                    name="last_name"
                    :condensed="true"
                    :disabled="disableInputs"
                    :validator="v$.last_name"
                    :value="inputs.last_name"
                    @input="setInput('last_name', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow
            v-if="!showOnlyPassportUpload"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    <span
                        >{{ $t('§eq_operator.auth_person_date_of_birth') }}
                        {{ isFieldRequired('date_of_birth') }}
                    </span>
                </CoreBorderWrapper>
            </template>
            <template #right>
                <CoreDateInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    name="date_of_birth"
                    :condensed="true"
                    :disabled="disableInputs"
                    :validator="v$.date_of_birth"
                    :value="inputs.date_of_birth"
                    @input="setInput('date_of_birth', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow
            v-if="!showOnlyPassportUpload"
            class="mb-3"
        >
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.auth_person_country') }}
                    {{ isFieldRequired('country_id') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreDropdown
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    name="person_country"
                    :placeholder="$t('§ui.placeholder_auth_person_select_country')"
                    :condensed="true"
                    :disabled="disableInputs"
                    :options="$store.state.country.countryFinancingAvailableOptions"
                    :validator="v$.country_id"
                    :value="inputs.country_id"
                    @input="setInput('country_id', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.auth_person_passport') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <DragAndDropUpload
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :disabled="disableInputs"
                    :fileName="displayFileName"
                    :fileId="file.fileId"
                    :filePath="inputs.passport_file_path"
                    :savedFile="savedFile"
                    :error="file.error"
                    @delete-file="handleDelete"
                    @download-file="handleDownload"
                />
            </template>
        </CoreTwoColumnsRow>

        <div class="flex justify-end flex-gap-2">
            <CoreButtonPrimary
                v-if="!showOnlyPassportUpload"
                label="§ui_button.save"
                icon="pi-check"
                @click="handleSave"
                :loading="saving"
                :disabled="!isValid || disableInputs"
            />

            <CoreButtonSecondary
                label="§ui_button.close"
                @click="handleClose"
            />
        </div>
    </form>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'
import CorePageLoader from '@/components/core/CorePageLoader'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreDropdown from '@/components/core/CoreDropdown'
import CoreDateInput from '@/components/core/CoreDateInput.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary.vue'
import uppyInstance from '@/helpers/uppyInstance'
import DragAndDropUpload from '@/components/DragAndDropUpload'

const rules = {
    first_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    last_name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    date_of_birth: {
        required,
    },
    country_id: {
        required,
    },
}

export default {
    name: 'AuthorizedPersonModal',

    components: {
        CorePageLoader,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreDropdown,
        CoreDateInput,
        CoreButtonPrimary,
        CoreButtonSecondary,
        DragAndDropUpload,
    },

    data() {
        return {
            saving: false,
            closeModalAfterUploading: false,

            file: {
                fileId: null,
                uppyFileName: '',
                error: '',
                isFileSelected: false,
            },

            v$: {},
        }
    },

    computed: {
        uppy() {
            return uppyInstance
        },

        inputs() {
            return this.$store.state.client.person
        },

        isValid() {
            return !this.v$.$invalid
        },

        displayFileName() {
            if (this.file.uppyFileName !== '') {
                return this.file.uppyFileName
            }

            if (this.inputs.passport_file_path !== '') {
                return this.$t('eq_operator.auth_person_passport_file')
            }

            return ''
        },

        savedFile() {
            return {
                path: this.inputs.passport_file_path,
            }
        },

        showOnlyPassportUpload() {
            return this.$store.state.modal.data.showOnlyPassportUpload
        },

        disableInputs() {
            return this.$store.state.modal.data.disableInputs
        },
    },

    created() {
        this.uppy.on('file-added', this.handleFileAdded)
        this.uppy.on('file-removed', this.handleFileRemoved)
        this.uppy.on('upload-success', this.handleSuccess)

        this.uppy.setOptions({
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['.jpg', '.jpeg', '.png', '.pdf'],
            },
        })
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules, this.inputs)

            if (
                this.$refs.firstNameInput &&
                typeof this.$refs.firstNameInput.focus === 'function'
            ) {
                this.$refs.firstNameInput.focus()
            }
        })
    },

    beforeDestroy() {
        this.uppy.off('file-added')
        this.uppy.off('file-removed')
        this.uppy.off('upload-success')

        this.clearUppy()
        this.uppy.close({ reason: 'unmount' })

        this.$store.commit('client/RESET_PERSON')
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_PERSON_INPUT', { name, value })

            this.$nextTick(() => {
                this.v$[name] && this.v$[name].$touch()
            })
        },

        handleFileAdded(file) {
            this.file.fileId = file.id
            this.file.uppyFileName = file.name
            this.file.isFileSelected = true

            if (this.inputs.id) {
                this.handleUpload()
            }
        },

        handleFileRemoved() {
            this.file.fileId = null
            this.file.uppyFileName = ''
            this.file.isFileSelected = false
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },

        async handleSave() {
            this.saving = true
            this.file.isFileSelected = false

            if (this.inputs.id) {
                await this.$store.dispatch('client/updateAuthPerson', {
                    vm: this,
                    uppy: this.uppy,
                })
                this.saving = false
            } else {
                await this.$store.dispatch('client/saveAuthPerson', {
                    vm: this,
                    uppy: this.uppy,
                })

                this.saving = false
                if (this.file.fileId) {
                    this.handleUpload(true)
                } else {
                    this.handleClose()
                }
            }
        },

        handleUpload(closeModal = false) {
            this.saving = true
            this.$store.dispatch('client/uploadPersonPassport', {
                vm: this,
                uppy: this.uppy,
                closeModal,
            })
        },

        handleSuccess(file, response) {
            this.saving = false
            this.file.isFileSelected = false
        },

        handleDelete() {
            this.clearUppy()
            this.$store.dispatch('client/deletePassport', { vm: this, personId: this.inputs.id })
        },

        clearUppy() {
            const addedFiles = this.uppy.getFiles()
            addedFiles.forEach((file) => this.uppy.removeFile(file.id))
            this.uppyFileName = ''
            this.fileId = null
        },

        handleDownload() {
            this.$store.dispatch('client/downloadPassport', {
                vm: this,
                personId: this.inputs.id,
                first_name: this.inputs.first_name,
                last_name: this.inputs.last_name,
            })
        },
    },
}
</script>
