<template>
    <div class="select-client-modal">
        <ItemsSearchAndSelect
            v-model="selected"
            :list="list"
            radioGroupName="clientsList"
            inputName="client_select"
            itemCreateModalName="ClientItemModal"
            itemCreateModalTitle="§eq_operator.create_new_operator_modal_title"
            searchPlaceholder="§ui.search_equipment_operator"
            class="mb-6"
        />

        <div class="select-client-modal__buttons">
            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="$emit('close-modal')"
            />

            <CoreButtonPrimary
                :disabled="selected === null"
                label="§ui_button.select"
                icon="pi pi-arrow-right"
                @click="handleSet"
            />
        </div>
    </div>
</template>

<script>
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import ItemsSearchAndSelect from './ItemsSearchAndSelect.vue'

export default {
    name: 'SelectClientModal',

    components: {
        ItemsSearchAndSelect,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    data() {
        return {
            list: null,
            selected: null,
        }
    },

    mounted() {
        this.getItemsList()
    },

    methods: {
        async getItemsList() {
            const route = this.route('client.index', { page: -1 })
            this.list = await this.$store.dispatch('caf/getItemsList', {
                vm: this,
                route,
            })
        },

        handleSet() {
            this.$store.commit('caf/SET_INPUT', {
                name: 'client_select',
                value: this.selected,
            })

            const client = this.list.filter((item) => item.id === this.selected)[0]

            this.$store.commit('caf/SET_INPUT', {
                name: 'client_select_name',
                value: client.name,
            })

            this.$store.commit('caf/SET_INPUT', {
                name: 'client_country_id',
                value: client.client_country_id,
            })

            if (this.$store.state.caf.inputs.unit_country_id === '') {
                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_country_id',
                    value: client.client_country_id,
                })
            }

            this.$emit('close-modal')
        },
    },
}
</script>

<style lang="scss" scoped>
.select-client-modal {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
}
</style>
