<template>
    <div class="contract_creation_data_access">
        <p>{{ $t('contract.the_machine_data_is_accessed_via') }}:</p>

        <div class="contract_creation_data_access__inputs">
            <div
                class="
                    contract_creation_data_access__radio
                    contract_creation_data_access__radio--with_input
                "
            >
                <input
                    :id="radioOptions[0].value"
                    type="radio"
                    :name="radioOptions[0].value"
                    :checked="checkedValue === radioOptions[0].value"
                    @change="
                        handleChange(
                            'contract_pdf_asset_data_interface',
                            $event.target.name
                        )
                    "
                />
                <label
                    :key="radioOptions[0].value"
                    :for="radioOptions[0].value"
                >
                    {{ $t(radioOptions[0].label) }}
                </label>
                <div class="contract_creation_data_access__text_input">
                    <BaseInput
                        :value="
                            formData.contract_pdf_asset_data_interface_description
                        "
                        size="small"
                        :disabled="checkedValue !== radioOptions[0].value"
                        @input="
                            handleChange(
                                'contract_pdf_asset_data_interface_description',
                                $event
                            )
                        "
                    />
                </div>
            </div>

            <div class="contract_creation_data_access__radio">
                <input
                    :id="radioOptions[1].value"
                    type="radio"
                    :name="radioOptions[1].value"
                    :checked="checkedValue === radioOptions[1].value"
                    @change="
                        handleChange(
                            'contract_pdf_asset_data_interface',
                            $event.target.name
                        )
                    "
                />
                <label
                    :key="radioOptions[1].value"
                    :for="radioOptions[1].value"
                >
                    {{ $t(radioOptions[1].label) }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'S6_DataInterface',

    props: {
        formData: {
            type: Object,
            required: true,
        },
    },

    computed: {
        radioOptions() {
            return [
                {
                    label: this.$t('contract.other_interface'),
                    value: '1',
                },
                {
                    label: this.$t('contract.at_this_point_no_info'),
                    value: '0',
                },
            ]
        },

        checkedValue() {
            return this.formData.contract_pdf_asset_data_interface
        },
    },

    watch: {
        'formData.contract_pdf_asset_data_interface'(newValue) {
            if (newValue === '1') {
                this.$emit('isValid', { isValid: false })
            } else if (newValue === '0') {
                this.handleChange(
                    'contract_pdf_asset_data_interface_description',
                    ''
                )
                this.$emit('isValid', { isValid: true })
            }
        },

        'formData.contract_pdf_asset_data_interface_description'(newValue) {
            if (
                this.formData.contract_pdf_asset_data_interface === '1' &&
                newValue.length >= 1
            ) {
                this.$emit('isValid', { isValid: true })
            } else if (
                this.formData.contract_pdf_asset_data_interface === '1' &&
                newValue.length === 0
            ) {
                this.$emit('isValid', { isValid: false })
            }
        },
    },

    mounted() {
        if (this.formData.contract_pdf_asset_data_interface === null) {
            this.$emit('isValid', { isValid: false })
        } else {
            this.$emit('isValid', { isValid: true })
        }
    },

    methods: {
        handleChange(key, e) {
            this.$emit('input', {
                category: 'formData',
                key,
                value: e,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_data_access {
    margin-bottom: 40px;

    & > p {
        color: $vc-primary;
        margin-bottom: 20px;
    }

    &__inputs {
        margin-left: 60px;
        display: flex;
        justify-items: flex-start;
        flex-direction: column;
    }

    &__radio {
        input[type='radio'] {
            display: none;
        }

        label {
            line-height: 1;
            cursor: pointer;
            display: block;
            height: 22px;
            margin: 10px 0;
            padding: 4px 5px 0 40px;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;

            font-family: $vff-primary;
            color: $vc-gray;

            transition: 0.15s ease;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 6px solid $vc-gray-light;
            }

            &:focus,
            &:hover,
            &:active {
                color: $vc-primary;

                &:before {
                    border: 6px solid $vc-primary;
                }
            }
        }

        input:checked + label {
            color: rgba($vc-primary, 0.7);

            &:before {
                border: 6px solid $vc-primary;
                background: $vc-primary;
            }
        }
    }

    &__radio--with_input {
        display: flex;

        & .contract_creation_data_access__text_input {
            margin-left: 12px;
            margin-top: 5px;
        }
    }
}
</style>
