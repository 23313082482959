<template>
    <div class="contract-saved-modal">
        <p class="text-200 mt-4 mb-3">
            {{ $t('§caf.contract_saved_modal-contract_id:') }} {{ contractId }}
        </p>

        <CoreLinkPrimary
            class="mb-6"
            text="§ui_button.download_pdf"
            iconName="arrow-right"
            @click="downloadPdf"
        />

        <div class="contract-saved-modal__buttons">
            <CoreButtonSecondary
                label="§ui_button.back_to_caf"
                @click="goToCaf"
            />
            <CoreButtonPrimary
                label="§ui_button.continue_to_contract_overview"
                icon="pi-arrow-right"
                @click="goToContractView"
            />
        </div>
    </div>
</template>

<script>
import CoreLinkPrimary from '@/components/core/CoreLinkPrimary'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'

export default {
    name: 'ContractSavedModal',

    components: {
        CoreLinkPrimary,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    computed: {
        contractId() {
            return this.$store.state.modal.data.contractId
        },
    },

    destroyed() {
        if (this.$route.name !== 'ContractShow') {
            this.$store.commit('caf/SET_FORM_STATUS', {
                status: 'valid',
            })
        }
    },

    methods: {
        downloadPdf() {
            console.log('method')
            this.$store.dispatch('caf/downloadPdf', {
                vm: this,
            })
        },

        goToCaf() {
            this.$store.commit('caf/SET_FORM_STATUS', {
                status: 'valid',
            })
            this.$store.commit('modal/SET_CLOSE')
        },

        goToContractView() {
            this.$router.push({
                name: 'ContractShow',
                params: { id: this.contractId },
            })
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss" scoped>
.contract-saved-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}
</style>
