<template>
    <div class="select-unit-type-modal">
        <ItemsSearchAndSelect
            v-model="selected"
            :list="list"
            radioGroupName="unitTypesList"
            inputName="unit_type"
            itemCreateModalName="UnitTypeItemModal"
            itemCreateModalTitle="§eq_type.create_new_type_modal_title"
            searchPlaceholder="§ui.search_equipment_type"
            class="mb-6"
        />
        <div class="select-unit-type-modal__buttons">
            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="$emit('close-modal')"
            />
            <CoreButtonPrimary
                :disabled="selected === null"
                label="§ui_button.select"
                icon="pi pi-arrow-right"
                @click="handleSet"
            />
        </div>
    </div>
</template>

<script>
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import ItemsSearchAndSelect from './ItemsSearchAndSelect.vue'

export default {
    name: 'SelectUnitTypeModal',

    components: {
        ItemsSearchAndSelect,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    data() {
        return {
            list: null,
            selected: null,
        }
    },

    mounted() {
        this.getItemsList()
    },

    methods: {
        async getItemsList() {
            const route = this.route('unit_type.index', { page: -1 })
            this.list = await this.$store.dispatch('caf/getItemsList', {
                vm: this,
                route,
            })
        },

        handleSet() {
            this.$store.commit('caf/SET_INPUT', {
                name: 'unit_type',
                value: this.selected,
            })

            const type = this.list.filter((item) => item.id === this.selected)[0]

            this.$store.commit('caf/SET_INPUT', {
                name: 'unit_type_name',
                value: type.name,
            })

            this.$store.commit('caf/SET_INPUT', {
                name: 'unit_type_max_usage',
                value: type.max_usage,
            })

            this.$store.commit('caf/SET_INPUT', {
                name: 'unit_type_planned_usage',
                value: type.planned_usage,
            })

            const billingUnitKeys = Object.keys(type.billing_units)

            if (billingUnitKeys.length > 0) {
                const billingUnits = Object.keys(type.billing_units).map((label) => ({
                    label,
                    value: type.billing_units[label],
                }))

                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type_billing_units',
                    value: [...billingUnits],
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'billing_unit',
                    value: billingUnits.at(0).value,
                })
            }

            this.$store.commit('caf/SET_INPUT', {
                name: 'net_list_price',
                value: type.net_list_price,
            })

            if (this.$store.state.caf.inputs.expected_utilization === null) {
                this.$store.commit('caf/SET_INPUT', {
                    name: 'expected_utilization',
                    value: type.planned_usage,
                })
            }

            this.$emit('close-modal')
        },
    },
}
</script>

<style lang="scss" scoped>
.select-unit-type-modal {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
    }
}
</style>
