<template>
    <div class="unit-type-item-modal">
        <ModalUnitTypeDetails
            v-if="tab < 1"
            @validityChange="handleValidityChange('isDetailsPageValid', $event)"
            class="modal-overflow-y-auto"
        />
        <ModalUsageDetails
            v-else-if="tab >= 1 && tab < 2"
            @validityChange="handleValidityChange('isUsagePageValid', $event)"
            class="modal-overflow-y-auto"
        />
        <ModalResidualValuesTable
            v-else
            :saving="saving"
            @rvt="handleRvt"
            class="modal-overflow-y-auto"
        />

        <div class="unit-type-item-modal__buttons mt-5">
            <CoreLinkPrimary
                v-if="tab >= 1"
                text="§ui_button.back"
                class="unit-type-item-modal__back-button"
                @click="back"
            />

            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="handleClose"
            />

            <CoreButtonPrimary
                v-if="showNextButton"
                :disabled="isNextButtonDisabled"
                label="§ui_button.continue"
                @click="next"
            />

            <CoreButtonPrimary
                v-if="showSaveButton"
                :disabled="isSaveButtonDisabled"
                :label="saveButtonLabel"
                icon="pi-arrow-right"
                :loading="saving"
                @click="save"
            />
        </div>
    </div>
</template>

<script>
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreLinkPrimary from '@/components/core/CoreLinkPrimary'
import ModalUsageDetails from './components/ModalUsageDetails'
import ModalUnitTypeDetails from './components/ModalUnitTypeDetails'
import ModalResidualValuesTable from './components/ModalResidualValuesTable'

export default {
    name: 'UnitTypeItemModal',

    components: {
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreLinkPrimary,
        ModalUsageDetails,
        ModalUnitTypeDetails,
        ModalResidualValuesTable,
    },

    data() {
        return {
            tab: 0,
            saving: false,
            rvt: null,

            isDetailsPageValid: false,
            isUsagePageValid: false,
        }
    },

    computed: {
        saveButtonLabel() {
            return !this.saving ? '§ui_button.save' : '§ui_button.saving'
        },

        showNextButton() {
            if (this.tab === 0) return true

            if (this.tab === 1 && this.$store.state.unitType.inputs.buy_back) {
                return true
            }

            return false
        },

        isNextButtonDisabled() {
            if (this.tab === 0 && !this.isDetailsPageValid) {
                return true
            }

            if (
                this.tab === 1 &&
                this.$store.state.unitType.inputs.buy_back &&
                !this.isUsagePageValid
            ) {
                return true
            }

            return false
        },

        showSaveButton() {
            if (this.tab === 1 && !this.$store.state.unitType.inputs.buy_back) {
                return true
            }

            if (this.tab === 2) return true

            return false
        },

        isSaveButtonDisabled() {
            if (
                this.tab === 1 &&
                !this.$store.state.unitType.inputs.buy_back &&
                !this.isUsagePageValid
            ) {
                return true
            }

            return false
        },

        expectedUtilization() {
            if (
                this.$store.state.caf.inputs === undefined ||
                this.$store.state.caf.inputs.expected_utilization === undefined
            )
                return null

            return this.$store.state.caf.inputs.expected_utilization
        },
    },

    destroyed() {
        this.$store.commit('unitType/RESET_INPUTS')
    },

    methods: {
        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },

        handleValidityChange(page, { isValid }) {
            this[page] = isValid
        },

        next() {
            this.tab += 1
        },

        back() {
            this.tab -= 1
        },

        async save() {
            this.saving = true
            const response = await this.$store.dispatch('unitType/save', {
                vm: this,
                rvt: this.rvt,
            })
            this.saving = false

            if (response && !response.errors) {
                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type',
                    value: response.unit_type_id,
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type_name',
                    value: response.unit_type_name,
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type_max_usage',
                    value: response.maximum_usage_per_a,
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type_planned_usage',
                    value: response.planned_usage_per_a,
                })

                const billingUnits = Object.keys(response.billing_units).map((label) => ({
                    label,
                    value: response.billing_units[label],
                }))

                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_type_billing_units',
                    value: [...billingUnits],
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'billing_unit',
                    value: billingUnits.at(0).value,
                })

                this.$store.commit('caf/SET_INPUT', {
                    name: 'net_list_price',
                    value: this.$store.state.unitType.inputs.value,
                })

                if (this.expectedUtilization === null) {
                    this.$store.commit('caf/SET_INPUT', {
                        name: 'expected_utilization',
                        value: response.planned_usage_per_a,
                    })
                }

                this.$emit('close-modal')
            }
        },

        handleRvt(rvt) {
            this.rvt = rvt
        },
    },
}
</script>

<style lang="scss" scoped>
.unit-type-item-modal {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    &__back-button {
        margin-right: auto;
    }
}
</style>
