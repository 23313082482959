<template>
    <div>
        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.contact_person') }}
                    {{ isFieldRequired('contact_person') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_contact_person"
                    name="contact_person"
                    :condensed="true"
                    :disabled="saving"
                    :value="inputs.contact_person"
                    :validator="v$.contact_person"
                    @input="setInput('contact_person', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.contact_email') }}
                    {{ isFieldRequired('contact_email') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_contact_email"
                    name="contact_email"
                    :condensed="true"
                    :disabled="saving"
                    :validator="v$.contact_email"
                    :value="inputs.contact_email"
                    @input="setInput('contact_email', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.phone') }}
                    &nbsp;&nbsp;&nbsp;
                    <span class="text-100 font-italic">
                        {{ $t('§eq_operator.optional') }}
                    </span>
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_phone"
                    name="phone"
                    :disabled="saving"
                    :condensed="true"
                    :validator="v$.phone"
                    :value="inputs.phone"
                    @input="setInput('phone', $event)"
                />
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import rules from '../validationRules.js'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
export default {
    name: 'InputsContactInfo',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
    },

    props: {
        isModal: {
            type: Boolean,
            default: false,
        },

        saving: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            v$: {},
        }
    },

    computed: {
        inputs() {
            return this.$store.state.client.inputs
        },

        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules(this.isModal), this.$store.state.client.inputs)
        })
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_INPUT', { name, value })
        },
    },
}
</script>
