<template>
    <div
        class="form"
        @submit.prevent="handleSave"
    >
        <BaseInput
            v-model="inputs.customer_uid"
            :validator="v$.lessee_internal_purchase_number"
        />
        <div class="form__buttons">
            <BaseButton
                buttonClass="button--primary"
                :disabled="!isValid"
                @click="handleSave()"
            >
                {{ $t('§ui_button.save') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength } from '@vuelidate/validators'

export default {
    name: 'CustomerUidModal',

    data() {
        return {
            inputs: {
                customer_uid: '',
            },

            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },

        rules() {
            return {
                customer_uid: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(64),
                },
            }
        },
    },

    created() {
        this.inputs.customer_uid = this.$store.state.contract.contractData.customer_uid
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.inputs)
    },

    methods: {
        ...mapActions({
            updateCustomerUid: 'contract/updateCustomerUid',
        }),

        handleSave() {
            this.updateCustomerUid({
                vm: this,
                formData: {
                    customer_uid: this.inputs.customer_uid,
                },
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.form {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 50px;
    }
}
</style>
