<template>
    <div class="contract-creation-display-tab-number">
        <span>{{ currentTab }}</span>
        <span>|</span>
        <span>{{ lastTab }}</span>
    </div>
</template>

<script>
export default {
    name: 'DisplayTabNumber',
    props: {
        currentTab: {
            type: Number,
            required: true,
        },
        lastTab: {
            type: Number,
            required: true,
        },
    },
}
</script>

<style scoped lang="scss">
.contract-creation-display-tab-number {
    width: 70px;
    height: 37px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    font-family: $vff-primary;
    font-size: 20px;
    color: $vc-secondary;
    padding: 3px 10px;
    border: 1px solid $vc-secondary;
    border-radius: 20px;

    // & > {
    //     display: flex;
    //     justify-items: center;
    //     align-items: center;
    // }
}
</style>
