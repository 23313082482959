<template>
    <div
        class="invalid-invite-page"
        :style="{
            'background-image': `url('/assets/images/fe_background.png')`,
        }"
    >
        <img
            src="@/assets/images/linxfour.png"
            class="invalid-invite-page__logo"
        />

        <div class="invalid-invite-page__message-container">
            <p>{{ $t('§public.invalid_invite_heading') }}</p>
        </div>
    </div>
</template>

<script>
import { getUserBrowserPreferredLocale } from '@/helpers/mixins.js'

export default {
    name: 'InvalidInvite',

    mixins: [getUserBrowserPreferredLocale],

    mounted() {
        this.setLocale()
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
@import '../../assets/styles/theme.css';

.invalid-invite-page {
    width: 100%;
    min-height: 100vh;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__logo {
        position: absolute;
        left: 3vw;
        top: 0;

        max-width: 250px;
    }

    .invalid-invite-page__message-container {
        position: relative;
        z-index: 1;

        background: $cn-10;
        box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        padding: 35px 100px;
        width: 40%;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
