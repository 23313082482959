<template>
    <div class="core-radio" @click="handleClick">
        <RadioButton
            v-model="selected"
            :name="name"
            :value="optionValue"
            :inputId="`${id}-${optionValue}`"
            class="core-radio"
        />
        <span class="core-radio__label">{{ displayLabel }}</span>
    </div>
</template>

<script>
import RadioButton from 'primevue/radiobutton'

export default {
    name: 'CoreRadio',

    components: { RadioButton },

    props: {
        label: {
            type: [String, Number],
            required: true,
        },

        name: {
            type: [String, Number],
            required: true,
        },

        id: {
            type: [String, Number],
            required: true,
        },

        optionValue: {
            type: [String, Number],
            required: true,
        },
    },

    computed: {
        selected: {
            get() {
                return this.$attrs.value
            },

            set(newValue) {
                this.$emit('input', newValue)
            },
        },

        displayLabel() {
            return this.$t(this.label)
        },
    },

    methods: {
        handleClick() {
            this.$emit('input', this.optionValue)
        },
    },
}
</script>

<style lang="scss">
.core-radio {
    display: flex;
    align-items: center;
    cursor: default;

    &__label {
        margin-left: 16px;
    }

    & div.p-hidden-accessible {
        display: none;
    }

    // default state
    & div.p-radiobutton-box {
        background: white;
        border: 1px solid #040f0f;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .p-radiobutton-icon {
            border-radius: 50px;
            background: white;
        }
    }

    // selected state
    & div.p-radiobutton-box.p-highlight {
        background: white;
        border: 1px solid #040f0f;
        display: flex;
        justify-content: center;
        align-items: center;

        & > .p-radiobutton-icon {
            background: #55e38e;
        }
    }

    // hover
    &:hover div.p-radiobutton-box {
        border: 1px solid #040f0f;

        & > .p-radiobutton-icon {
            background: #b1f0ca;
        }
    }

    & div.p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
        border: 1px solid #040f0f;
    }
}
</style>
