<template>
    <div :class="classes">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'CoreBorderWrapper',

    props: {
        noBorder: {
            type: Boolean,
            default: false,
        },

        condensed: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-border-wrapper': true,
                'core-border-wrapper--no-border': this.noBorder,
                'core-border-wrapper--condensed': this.condensed,
            }
        },
    },
}
</script>

<style lang="scss">
.core-border-wrapper {
    height: 71px;
    border: 1px solid $cn-75;
    border-radius: 8px;

    padding: 0 16px;

    display: flex;
    align-items: center;

    &--no-border {
        border-color: transparent;
    }

    &--condensed {
        height: 50px;
    }
}
</style>
