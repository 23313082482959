<template>
    <Layout>
        <template #loading>
            <Loading
                :active.sync="loader"
                :can-cancel="false"
                :is-full-page="fullPage"
                color="#1AA176"
                loader="dots"
                :width="128"
                :height="128"
                backgroundColor="#ffffff"
                :opacity="0.5"
                :zIndex="999"
            />
        </template>

        <template #main>
            <router-view v-if="$store.state.user.user.id" />
        </template>

        <Footer />

        <template #notification>
            <NotificationContainer />
        </template>

        <template #modal>
            <Modal v-if="isModalOpen" />
        </template>
    </Layout>
</template>

<script>
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import * as Sentry from '@sentry/vue'
import { mapGetters } from 'vuex'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import NotificationContainer from '@/components/NotificationContainer.vue'

import Modal from '@/components/Modal.vue'
import Layout from '@/layout/Layout.vue'

export default {
    name: 'App',

    components: {
        NotificationContainer,
        Modal,
        Loading,
        Layout,
    },

    data() {
        return {
            modal: false,
            modalName: '',
            isLoading: false,
            fullPage: true,
            modalSize: 'small',
            heading: '',
        }
    },

    computed: {
        ...mapGetters({
            loader: 'loader',
        }),

        isModalOpen() {
            return this.$store.state.modal.isOpen
        },
    },

    created() {
        this.setLocale()
    },

    async mounted() {
        await this.$store.dispatch('user/getUserData', { vm: this })

        Sentry.setUser({
            id: this.$store.state.user.user.id,
            email: this.$store.state.user.user.email,
            permissions: this.$store.state.user.user.permissions,
            preferred_locale: this.$store.state.user.user.preferred_locale,
        })
    },

    methods: {
        mountModal(payload) {
            if (payload.size !== undefined) this.modalSize = payload.size
            this.modalName = payload.name
            this.modal = true
        },

        appClicked(event) {
            this.$root.$emit('app-clicked', event)
        },

        setLocale() {
            this.$i18n.locale = navigator.language.substring(0, 2)
        },
    },
}
</script>

<style lang="scss">
.page {
    // width: 100%;
    // height: 100%;
    // padding-top: 50px;

    // .bottom-right {
    //     position: fixed;
    //     bottom: -10px;
    //     right: -330px;
    //     z-index: 0;
    //     overflow: hidden;
    // }
}

.vue-wrapper {
    // width: 100%;
    // max-width: 1880px;
    // margin: 0 auto;
    // padding: 0 40px;
    // position: relative;

    // display: grid;
    // grid-template-columns: repeat(12, 1fr);
    // gap: 10px 20px;

    // .main-header {
    //     grid-column: 1 / -1;
    // }

    // &__header {
    //     grid-column: 1 / -1;
    //     display: flex;
    //     justify-content: space-between;
    //     align-items: flex-end;
    // }

    // &__sidebar {
    //     grid-column: 1 / 4;
    //     z-index: 2;
    // }

    // &__main {
    //     grid-column: 4 / -1;

    //     background: rgba($vc-white, 0.6);
    //     box-shadow: 6px 10px 31px 9px rgba($vc-black, 0.1);
    //     backdrop-filter: blur(30px);
    //     border-radius: 20px;
    //     z-index: 1;
    //     overflow: hidden;

    //     position: relative;
    // }

    &__home {
        grid-column: 1 / -1;
    }

    // @include respond(tab) {
    //     &__sidebar {
    //         grid-column: 1 / 6;
    //         z-index: 2;
    //     }

    //     &__main {
    //         grid-column: 6 / -1;
    //     }
    // }
}
</style>
