<template>
    <p
        name="help-link"
        class="help-link"
    >
        <span class="text-100">
            {{ $t('§footer.help_email_need_help') }} &nbsp;
        </span>
        <mdicon
            class="help-link__icon"
            name="email-outline"
            size="16"
        />
        <span
            @click="handleClick"
            class="help-link__link text-100"
        >
            {{ $t('§footer.help_email_email_us') }}
        </span>
    </p>
</template>

<script>
import { openMailClient } from '@/helpers/mixins.js'

export default {
    name: 'HelpLink',

    mixins: [openMailClient],
}
</script>

<style lang="scss" scoped>
.help-link {
    margin-top: auto;
    color: $cs;
    position: relative;
    z-index: 1;

    p {
        letter-spacing: 1px;
    }

    &__link {
        text-decoration: none;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }

    &__icon.mdi > svg {
        vertical-align: text-bottom;
    }
}
</style>
