<template>
    <div class="client-item-modal-details">
        <InputsBasicInfo @validityChange="handleValidityChange('basicInfoInputsValid', $event)" />

        <hr class="mt-3" />

        <InputsAdditionalInfo
            @validityChange="handleValidityChange('additionalInfoInputsValid', $event)"
            class="mt-4"
        />
    </div>
</template>

<script>
import CoreLinkSecondary from '@/components/core/CoreLinkSecondary.vue'
import InputsBasicInfo from './InputsBasicInfo.vue'
import InputsAdditionalInfo from './InputsAdditionalInfo.vue'

export default {
    name: 'ClientItemModalDetails',

    components: {
        CoreLinkSecondary,
        InputsBasicInfo,
        InputsAdditionalInfo,
    },

    data() {
        return {
            basicInfoInputsValid: false,
            additionalInfoInputsValid: false,
        }
    },

    methods: {
        toggleAdditionalInputs() {
            this.$emit('toggle-additional-inputs')
        },

        handleValidityChange(section, { isValid }) {
            this[section] = isValid
            this.emitValidityChange()
        },

        emitValidityChange() {
            let isValid = false
            if (this.basicInfoInputsValid && this.additionalInfoInputsValid) {
                isValid = true
            }
            this.$emit('validityChange', { isValid })
        },
    },
}
</script>

<style lang="scss" scoped>
.client-item-modal-details {
    max-height: 72vh;
}
</style>
