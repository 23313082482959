import { required, minLength, maxLength, email } from '@vuelidate/validators'

export default (modal) => {
    const rules = {
        name: {
            required,
            minLength: minLength(2),
            maxLength: maxLength(100),
        },
        enduser_industry_id: {
            required,
        },
        country_id: {
            required,
        },
        street: {
            maxLength: maxLength(200),
        },
        postal_code: {
            maxLength: maxLength(32),
        },
        city: {
            minLength: minLength(2),
            maxLength: maxLength(100),
        },
        vat_identification_number: {
            maxLength: maxLength(25),
        },
        contact_person: {
            maxLength: maxLength(100),
        },
        contact_email: {
            email,
        },
        invoice_email: { email },
        maintenance_email: { email },
        phone: {},
    }

    if (!modal) {
        rules.name.required = required
        rules.name.postal_code = required
        rules.name.city = required
        rules.name.contact_person = required
        rules.name.contact_email = required
    }

    return rules
}
