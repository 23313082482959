<template>
    <div :class="classes">
        <slot name="left"></slot>
        <slot name="right"></slot>
    </div>
</template>

<script>
export default {
    name: 'CoreTwoColumnsRow',

    props: {
        column: {
            type: Boolean,
            default: false,
        },

        showBorder: {
            type: Boolean,
            default: false,
        },

        padding: {
            type: Boolean,
            default: false,
        },

        error: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-two-columns-row': true,
                'core-two-columns-row--show-border': this.showBorder,
                'core-two-columns-row--add-padding': this.padding,
                'core-two-columns-row--error': this.error,
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.core-two-columns-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    gap: 16px;

    &--show-border {
        border: 1px solid #e8e8e8;
        border-radius: 8px;
        padding: 24px 16px;
    }

    &--error {
        border: 1px solid $color-error;
    }

    &--add-padding {
        padding: 26px 18px;
    }
}
</style>
