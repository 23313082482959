<template>
    <div class="page">
        <div class="top-left">
            <topLeft />
        </div>
        <div class="bottom-right">
            <bottomRight />
        </div>
        <Logo class="logo" />
        <div class="service-upload-non-auth">
            <h1>{{ $t('service_dashboard.upload_service_protocol') }}</h1>
            <div v-if="uploaded === null">
                <DragAndDropUpload
                    :fileName="displayFileName"
                    :fileId="fileId"
                    :error="error"
                    @delete-file="handleDeleteClick"
                />
                <BaseButton
                    :class="!isUploadButtonDisabled && 'mt-40'"
                    buttonClass="button--primary"
                    :disabled="isUploadButtonDisabled"
                    @click="handleUpload"
                >
                    {{ $t('ui.button_upload') }}
                </BaseButton>
            </div>
            <div v-if="uploaded">
                <p>{{ $t('service_dashboard.file_successfully_uploaded') }}</p>
            </div>
            <div v-if="uploaded === false">
                <p>{{ $t('service_dashboard.error_file_not_uploaded') }}</p>
            </div>
        </div>
        <NotificationContainer />
    </div>
</template>

<script>
import XHRUpload from '@uppy/xhr-upload'
import NotificationContainer from '@/components/NotificationContainer.vue'
import Logo from '../../assets/images/logo.svg?inline'
import topLeft from '../../assets/images/top-left.svg?inline'
import bottomRight from '../../assets/images/bottom-right.svg?inline'
import uppyInstance from '@/helpers/uppyInstance'
import DragAndDropUpload from '@/components/DragAndDropUpload.vue'

export default {
    name: 'UploadProtocolNonAuth',

    components: {
        topLeft,
        bottomRight,
        Logo,
        NotificationContainer,
        DragAndDropUpload,
    },

    props: {
        service: {
            type: Number,
            required: true,
        },

        file_upload_link: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            fileId: null,
            isUploadButtonDisabled: null,
            uppyFileName: '',
            error: '',
            uploaded: null,
        }
    },

    computed: {
        uppy() {
            return uppyInstance
        },

        fileUid() {
            return this.file_upload_link.uid
        },

        displayFileName() {
            if (this.uppyFileName !== '') {
                return this.uppyFileName
            }

            return ''
        },
    },

    created() {
        this.setLocale()
        this.uppy.on('file-added', this.handleFileAdded)
        this.uppy.on('file-removed', this.handleFileRemoved)
        this.uppy.on('upload-success', this.handleSuccess)

        this.uppy.setOptions({
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['.jpg', '.jpeg', '.png', '.pdf'],
            },
        })
    },

    beforeDestroy() {
        this.uppy.off('file-added')
        this.uppy.off('file-removed')
        this.uppy.off('upload-success')

        this.removeUppyFiles()
        this.uppy.close({ reason: 'unmount' })
    },

    methods: {
        setLocale() {
            this.$i18n.locale = navigator.language.substring(0, 2)
        },

        handleFileAdded(file) {
            this.fileId = file.id
            this.uppyFileName = file.name
            this.isUploadButtonDisabled = false
        },

        handleFileRemoved() {
            this.fileId = null
            this.uppyFileName = ''
            this.isUploadButtonDisabled = true
        },

        handleDeleteClick() {
            if (this.uppyFileName) {
                this.removeUppyFiles()
            }
        },

        handleUpload() {
            try {
                const headers = {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr(
                        'content'
                    ),
                }

                this.uppy.use(XHRUpload, {
                    endpoint: this.route('file_upload_store', [
                        this.file_upload_link.uid,
                        this.service,
                    ]),
                    headers,
                    formData: true,
                    fieldName: 'file',
                    bundle: true,
                    allowedMetaFields: null,
                })

                this.uppy.upload()
            } catch (error) {
                this.uploaded = false
            }
        },

        handleSuccess(file, response) {
            if (response.status === 200) {
                this.uploaded = true
            }
        },

        removeUppyFiles() {
            const addedFiles = this.uppy.getFiles()
            addedFiles.forEach((file) => this.uppy.removeFile(file.id))
            this.uppyFileName = ''
            this.fileId = null
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
.page {
    width: 100%;
    min-height: 100vh;
    background-color: $vc-white;

    display: flex;
    justify-content: center;
    align-items: center;

    .top-left {
        position: absolute;
        top: 7%;
        left: 3%;
        z-index: 0;
    }

    .bottom-right {
        position: absolute;
        right: -330px;
        bottom: 0;
        z-index: 0;
    }

    .logo {
        position: absolute;
        left: 6%;
        top: 5%;
    }
}

.service-upload-non-auth {
    position: relative;
    z-index: 1;

    background: rgba($vc-white, 0.6);
    box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 20px;

    padding: 35px 100px;
    width: 40%;

    h1 {
        text-align: center;
        font-family: $vff-primary;
        font-size: 64px;
        line-height: 75px;
        color: $vc-primary;
        font-weight: 400;
        position: relative;

        margin-bottom: 35px;
    }

    p {
        font-family: $vff-primary;
        color: $vc-primary;
        font-size: 18px;
    }
}
</style>
