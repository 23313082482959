<template>
    <div class="contract_creation_buy_back mb-40">
        <p>{{ $t('contract.do_you_want_option_to_purchase_the_asset') }}</p>
        <div>
            <BaseRadio
                :options="radioOptions"
                :checked-value="formData.contract_pdf_buy_back"
                @input="handleChange($event)"
            />
        </div>
    </div>
</template>

<script>
import uppyInstance from '@/helpers/uppyInstance'

export default {
    name: 'S4_PurchaseOption',
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        radioOptions() {
            return [
                {
                    label: this.$t('contract.yes'),
                    value: '1',
                },
                {
                    label: this.$t('contract.no'),
                    value: '0',
                },
            ]
        },
        uppy() {
            return uppyInstance
        },
    },
    watch: {
        'formData.contract_pdf_buy_back'(newValue) {
            const isValid = newValue == '0' || newValue == '1'
            this.$emit('isValid', { isValid })
        },
    },
    mounted() {
        const isValid = this.formData.contract_pdf_buy_back !== null
        this.$emit('isValid', { isValid })
    },
    beforeDestroy() {
        // if user has went trough this part of form, and uploaded rvt
        // and comes back, and un-select buy back, check is rvt pdf uploaded and if it is, delete it
        if (this.formData.contract_pdf_buy_back === '0') {
            const rvtUploadId = this.uppy
                .getFiles()
                .reduce((holder, current) => {
                    if (current.relation === 'rvt') {
                        holder = current.id
                    }
                    return holder
                }, '')
            if (rvtUploadId !== '') {
                this.uppy.removeFile(rvtUploadId)
            }
        }
    },
    methods: {
        handleChange(e) {
            this.$emit('input', {
                category: 'formData',
                key: 'contract_pdf_buy_back',
                value: e,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_buy_back {
    & > p {
        color: $vc-primary;
        margin-bottom: 20px;
    }

    & > div {
        margin-left: 60px;
    }
}
</style>
