<template>
    <MultiEmailList
        :saving="saving"
        group="invoice_emails"
        headingText="§ui_button.show_invoice_email"
        placeholder="§ui.placeholder_input_invoice_email"
    />
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import rules from '../validationRules.js'

import MultiEmailList from './MultiEmailList.vue'

const IS_MODAL = true

export default {
    name: 'InputsInvoicesEmails',

    components: {
        MultiEmailList,
    },

    props: {
        saving: {
            type: Boolean,
            required: true,
        },
    },

    data() {
        return {
            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules(IS_MODAL), this.$store.state.client.inputs)
        })
    },
}
</script>
