<template>
    <div class="new-service-modal-wrapper">
        <BaseInput
            class="mb-40"
            :value="$store.state.service.serviceName"
            :disabled="disableChanges"
            @input="updateInput('serviceName', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('service_dashboard.modal_service_name') }}
                </BaseLabel>
            </template>
        </BaseInput>
        <BaseFieldGroup class="full-width mb-40">
            <BaseLabel
                :weight="labelsOptions.weight"
                :size="labelsOptions.size"
            >
                {{ $t('service_dashboard.service_by_oem') }}
            </BaseLabel>
            <BaseSingleCheckbox
                name="serviceByOEM"
                :value="!!$store.state.service.serviceByOEM"
                @input="updateInput('serviceByOEM', $event)"
            />
        </BaseFieldGroup>

        <BaseDatePicker
            v-if="!disableChanges"
            class="mb-40"
            :disablePassedDates="false"
            :availableDates="getServiceFirstServiceAvailableDates"
            :value="$store.state.service.firstServiceDate"
            @input="updateFirstServiceDate($event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('service_dashboard.modal_first_service_date') }}
                </BaseLabel>
            </template>
        </BaseDatePicker>

        <BaseInput
            v-if="disableChanges"
            class="mb-40"
            :disabled="true"
            :value="getFirstServiceInputDate"
            @input="updateInput('contractEndingDate', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('service_dashboard.modal_first_service_date') }}
                </BaseLabel>
            </template>
        </BaseInput>

        <BaseInput
            :disabled="true"
            class="mb-40"
            :value="getContractEndInputDate"
            @input="updateInput('contractEndingDate', $event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('service_dashboard.modal_contract_end') }}
                </BaseLabel>
            </template>
        </BaseInput>

        <BaseSelect
            v-if="
                !!$store.state.service.serviceName &&
                !!$store.state.service.firstServiceDate &&
                !disableChanges
            "
            class="mb-40"
            placeholder=""
            :options="intervalOptions"
            :value="$store.state.service.serviceInterval"
            @input="handleServiceIntervalChange($event)"
        >
            <template #label>
                <BaseLabel
                    :weight="labelsOptions.weight"
                    :size="labelsOptions.size"
                >
                    {{ $t('service_dashboard.modal_service_interval') }}
                </BaseLabel>
            </template>
        </BaseSelect>

        <div
            v-if="showServiceDatePicker || disableChanges"
            class="mb-40"
        >
            <vc-date-picker
                ref="calendar"
                :key="datepickerKey"
                :locale="$store.state.user.user.preferred_locale"
                is-expanded
                color="purple"
                :first-day-of-week="2"
                :available-dates="getServiceMainCalendarAvailableDates"
                :value="serviceDates"
                :attributes="attributes"
                @dayclick="onDayClick"
            />
        </div>

        <div
            v-if="!disableChanges"
            class="form__buttons"
        >
            <BaseButton
                buttonClass="button--primary"
                @click="handleSave"
            >
                {{ $t('ui.button_save') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    addMonths,
    isBefore,
    isAfter,
    lightFormat,
    parse,
    toDate,
    addDays,
    parseISO,
} from 'date-fns'

export default {
    name: 'NewServiceModal',
    data() {
        return {
            labelsOptions: {
                weight: 'normal',
                size: 'small',
            },
            intervalOptions: [
                {
                    value: 'month',
                    label: this.$t('service_dashboard.monthly'),
                },
                {
                    value: 'quarter',
                    label: this.$t('service_dashboard.quarterly'),
                },
                {
                    value: 'year',
                    label: this.$t('service_dashboard.annually'),
                },
                {
                    value: 'individual',
                    label: this.$t('service_dashboard.individual'),
                },
            ],
            showServiceDatePicker: false,
            // add key update to force datepicke re-render
            datepickerKey: 0,
            // to make calendar go on the month page after date is clicked
            monthOfClickedDay: '',
            yearOfClickedDay: '',
            // disable any changes if existed service track is opened
            disableChanges: false,
        }
    },
    computed: {
        ...mapGetters({
            serviceDates: 'service/getServiceDates',
            isExistingService: 'service/isExistingService',
        }),
        dates() {
            return this.$store.state.service.serviceDates.map((day) => day.date)
        },
        attributes() {
            return this.dates.map((date) => ({
                highlight: true,
                dates: date,
            }))
        },
        getContractEndInputDate() {
            return this.displayAsDate(
                this.$store.state.service.contractEndingDate
            )
        },
        getFirstServiceInputDate() {
            const { firstServiceDate } = this.$store.state.service
            return firstServiceDate === ''
                ? ''
                : this.displayAsDate(this.$store.state.service.firstServiceDate)
        },
        getServiceFirstServiceAvailableDates() {
            return {
                start: this.$store.state.service.contractBeginDate,
                end: this.$store.state.service.contractEndingDate,
            }
        },
        getServiceMainCalendarAvailableDates() {
            return this.disableChanges
                ? {
                      start: toDate(new Date(1970, 1, 11, 11, 30, 30)),
                      end: toDate(new Date(1970, 1, 11, 11, 30, 30)),
                  }
                : {
                      start: parseISO(
                          this.$store.state.service.firstServiceDate
                      ),
                      end: this.$store.state.service.contractEndingDate,
                  }
        },

        dueDuration() {
            const { serviceInterval } = this.$store.state.service

            if (serviceInterval === 'month') return 1
            if (serviceInterval === 'quarter') return 3
            return 12
        },

        intervalArray() {
            const { serviceInterval, firstServiceDate } =
                this.$store.state.service

            if (serviceInterval === '') return []

            const firstServiceDateObject = parse(
                firstServiceDate,
                'dd-MM-yyyy',
                new Date()
            )

            return this.createIntervalArray(this.dueDuration, [
                {
                    date: firstServiceDateObject,
                    id: lightFormat(firstServiceDateObject, 'yyyy-MM-dd'),
                },
            ])
        },
    },
    watch: {
        datepickerKey() {
            this.$nextTick(async () => {
                const { calendar } = this.$refs
                await calendar.move({
                    month: this.monthOfClickedDay,
                    year: this.yearOfClickedDay,
                })
            })
        },
    },
    mounted() {
        if (this.$store.state.service.serviceInterval !== '') {
            this.showServiceDatePicker = true
        }

        // disable any changes for the saved services
        if (this.isExistingService(this.$store.state.service.serviceName)) {
            this.disableChanges = true
        }
    },
    beforeDestroy() {
        this.resetTrackState()
    },
    methods: {
        ...mapActions({
            updateInputAction: 'service/updateInput',
            saveServiceTrack: 'service/saveServiceTrack',
            resetTrackState: 'service/resetTrackState',
        }),

        handleSave() {
            this.saveServiceTrack({ vm: this })
        },

        updateInput(fieldName, value) {
            this.updateInputAction({ fieldName, value })
        },

        updateFirstServiceDate(value) {
            const firstUpdate = this.$store.state.service.serviceInterval === ''
            this.updateInput('serviceDates', [])
            this.updateInput('firstServiceDate', value)

            if (!firstUpdate) {
                this.handleServiceIntervalChange(
                    this.$store.state.service.serviceInterval
                )
            }
        },

        createIntervalArray(duration, dates) {
            const date = addMonths(dates[dates.length - 1].date, duration)

            if (isBefore(date, this.$store.state.service.contractEndingDate)) {
                dates.push({ date, id: lightFormat(date, 'yyyy-MM-dd') })
                this.createIntervalArray(duration, dates)
            }

            return dates
        },

        handleServiceIntervalChange(interval) {
            this.$store.commit('service/UPDATE_INPUT', {
                name: 'serviceDates',
                value: [],
            })

            this.$store.commit('service/UPDATE_INPUT', {
                name: 'serviceInterval',
                value: interval,
            })

            this.showServiceDatePicker = true

            if (interval !== 'individual') {
                this.$store.commit('service/UPDATE_INPUT', {
                    name: 'serviceDates',
                    value: this.intervalArray,
                })
            }
        },

        onDayClick(day) {
            const firstDayOfContract = parse(
                this.$store.state.service.firstServiceDate,
                'dd-MM-yyyy',
                new Date()
            )

            if (
                isBefore(day.date, addDays(firstDayOfContract, 1)) ||
                isAfter(
                    day.date,
                    this.$store.state.service.contractEndingDate
                ) ||
                this.disableChanges
            ) {
                return null
            }
            const dateIndex = this.serviceDates.findIndex(
                (date) => date.id === day.id
            )
            this.monthOfClickedDay = day.month
            this.yearOfClickedDay = day.year

            const datesCopy = [...this.serviceDates]

            if (dateIndex >= 0) {
                datesCopy.splice(dateIndex, 1)
            } else {
                datesCopy.push({
                    id: day.id,
                    date: day.date,
                })
            }

            this.updateInput('serviceDates', datesCopy)
            this.datepickerKey += 1
        },
    },
}
</script>

<style lang="scss" scoped>
.new-service-modal-wrapper {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
