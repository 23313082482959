<template>
    <div
        id="user-menu-button"
        class="user-menu-button"
        :class="$store.state.showUserMenu && 'user-menu-button--bg-c-primary'"
        ref="userMenuButtonRef"
        @click="toggleUserMenu()"
    >
        <mdicon
            v-if="!$store.state.showUserMenu"
            name="account-circle"
            size="56"
        />
        <mdicon v-else name="window-close" size="32" class="icon--white" />
    </div>
</template>

<script>
const HEIGHT_AND_BOTTOM_SPACE = 46 + 20
const USER_MENU_WRAPPER_ID = 'user-menu'

export default {
    name: 'UserMenuButton',

    computed: {
        menuOffsetTop() {
            return (
                Number(this.$refs.userMenuButtonRef.offsetTop) +
                HEIGHT_AND_BOTTOM_SPACE
            )
        },
    },

    created() {
        this.$root.$on('app-clicked', this.clickOutside)
    },

    beforeDestroy() {
        this.$root.$off('app-clicked', this.clickOutside)
    },

    methods: {
        clickOutside(event) {
            const condition = this.$store.state.showUserMenu
            this.clickOutsideAction(
                event,
                condition,
                [USER_MENU_WRAPPER_ID, 'user-menu-button'],
                this.toggleUserMenu
            )
        },

        toggleUserMenu() {
            this.$store.commit('TOGGLE_USER_MENU', {
                menuOffsetTop: this.menuOffsetTop,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-menu-button {
    width: 46px;
    height: 46px;
    background-color: $vc-white;
    border-radius: 100px;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    &--bg-c-primary {
        background-color: $cs;
    }
}
</style>
