<template>
    <div
        class="register-page"
        :style="{
            'background-image': `url('/assets/images/fe_background.png')`,
        }"
    >
        <img
            src="@/assets/images/linxfour.png"
            class="register-page__logo"
        />

        <div class="register">
            <h1 class="heading-300 text-center">
                {{ $t('§public.register_heading') }}
            </h1>
            <CoreStringInput
                v-model="inputs.email"
                :validator="v$.email"
                :fullWidth="true"
                :disabled="true"
                placeholder="§ui.placeholder_login_email"
                class="mb-3"
            />

            <CorePasswordInput
                v-model="inputs.password"
                :validator="v$.password"
                class="mb-3"
                :fullWidth="true"
            />

            <CorePasswordInput
                v-model="inputs.passwordConfirm"
                :validator="v$.passwordConfirm"
                class="mb-3"
                :fullWidth="true"
            />

            <CoreDropdown
                v-model="inputs.preferred_locale"
                :options="getLocaleOptions"
                class="mb-3"
                :validator="v$.preferred_locale"
                :placeholder="$t('§ui.placeholder_select_language')"
            />

            <div class="register__checkbox mb-5">
                <CoreCheckbox
                    v-model="inputs.accept"
                    label=""
                    name="accept"
                />
                <label class="register__label">
                    {{
                        $t('§public.register-checkbox_text-part_1', {
                            company1_name: $t('§Corporate_info.company1_name'),
                            company2_name: $t('§Corporate_info.company2_name'),
                        })
                    }}
                    <span @click="handleLinkClick('privacy_data_policy_link')">
                        {{ $t('§public.register-checkbox_text-privacy_data_policy') }}
                    </span>
                    {{ $t('§public.register-checkbox_text-part_2') }}
                    <span @click="handleLinkClick('privacy_data_link')">
                        {{ $t('§public.register-checkbox_text-privacy_data') }}
                    </span>
                    {{ $t('§public.register-checkbox_text-part_3') }}
                </label>
            </div>

            <CoreButtonPrimary
                @click="handleSubmit"
                label="§ui_button.save"
                icon="pi-arrow-up-right"
                :loading="waiting"
                :disabled="!isValid"
            />
        </div>
        <NotificationContainer />
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { email, required, minLength, maxLength } from '@vuelidate/validators'
import NotificationContainer from '@/components/NotificationContainer.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import CorePasswordInput from '@/components/core/CorePasswordInput.vue'
import CoreDropdown from '@/components/core/CoreDropdown.vue'
import CoreCheckbox from '@/components/core/CoreCheckbox.vue'
import { handleError } from '@/helpers/api'
import { localeOptions } from '@/helpers/localeOptions'
import { getUserBrowserPreferredLocale } from '@/helpers/mixins.js'

const isSame = (inputs) => (value) => inputs.password === value

export default {
    name: 'Register',

    components: {
        NotificationContainer,
        CoreButtonPrimary,
        CoreStringInput,
        CoreDropdown,
        CorePasswordInput,
        CoreCheckbox,
    },

    mixins: [getUserBrowserPreferredLocale],

    props: {
        privacy_data_link_de: {
            type: String,
            required: true,
        },
        privacy_data_link_en: {
            type: String,
            required: true,
        },
        privacy_data_policy_link_de: {
            type: String,
            required: true,
        },
        privacy_data_policy_link_en: {
            type: String,
            required: true,
        },
        user_email: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            inputs: {
                email: '',
                password: '',
                passwordConfirm: '',
                preferred_locale: '',
                accept: false,
            },
            invite: '',
            waiting: false,
            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },

        getLocaleOptions() {
            return localeOptions()
        },

        rules() {
            return {
                email: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(100),
                    email,
                },
                password: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(100),
                },
                passwordConfirm: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(100),
                    sameAsPassword: isSame(this.inputs),
                },
                preferred_locale: { required },
                accept: {
                    required,
                    checked: (value) => value === true,
                },
            }
        },
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.inputs)
        this.setLocale()
        this.setEmail()
        this.setInvite()
    },

    methods: {
        async handleSubmit() {
            try {
                if (this.waiting) return

                this.waiting = true
                await this.$store.dispatch('user/register', {
                    vm: this,
                    invite: this.invite,
                    name: this.inputs.email,
                    password: this.inputs.password,
                    preferred_locale: this.inputs.preferred_locale,
                })
                this.waiting = false
            } catch (error) {
                this.waiting = false
                handleError(this.$store.dispatch, error)
            }
        },

        setEmail() {
            this.inputs.email = this.user_email
        },

        setInvite() {
            const url = new URL(window.location)
            const pathParts = url.pathname.split('/')
            this.invite = pathParts[pathParts.length - 1]
        },

        handleLinkClick(documentName) {
            window.open(this[`${documentName}_${this.$i18n.locale}`], '_blank')
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
@import '../../assets/styles/theme.css';

.register-page {
    width: 100%;
    min-height: 100vh;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__logo {
        position: absolute;
        left: 3vw;
        top: 0;

        max-width: 250px;
    }

    .register {
        position: relative;
        z-index: 1;

        background: $cn-10;
        box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        padding: 35px 100px;
        width: 40%;

        h1 {
            margin-bottom: 35px;
            font-weight: 300;
        }
    }

    .register__checkbox {
        display: flex;
        align-items: flex-start;

        .register__label {
            margin-left: 10px;
            font-family: $vff-primary;
            color: $vc-dark;

            & > span {
                color: $vc-primary;
                cursor: pointer;
            }
        }
    }
}
</style>
