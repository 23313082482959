<template>
    <div
        class="application"
        v-click-handler="appClicked"
    >
        <slot name="loading" />

        <div class="application__always-visible-structure">
            <Header class="application__main-header" />

            <div class="application__main-content">
                <slot name="main" />

                <transition name="menuShowHide">
                    <div
                        v-if="$store.state.showUserMenu"
                        :style="userMenuStyle"
                    >
                        <UserMenu />
                    </div>
                </transition>
            </div>

            <Footer />
        </div>

        <div class="application__notification-wrapper">
            <slot name="notification" />
        </div>
        <slot name="modal" />
    </div>
</template>

<script>
/* eslint-disable func-names */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import { mapGetters } from 'vuex'

import Header from './Header.vue'
import Footer from './Footer.vue'

import UserMenu from '@/components/header/UserMenu.vue'

export default {
    name: 'Layout',

    directives: {
        'click-handler': {
            bind: function (el, binding) {
                const clickEventHandler = (event) => {
                    binding.value(event)
                }

                el.__vueClickEventHandler_ = clickEventHandler
                document.addEventListener('click', clickEventHandler)
            },
            unbind: function (el) {
                document.removeEventListener(
                    'click',
                    el.__vueClickEventHandler__
                )
            },
        },
    },

    components: {
        Header,
        Footer,
        UserMenu,
    },

    props: {
        filters: {
            default: null,
            type: Object,
        },
        filtersOptions: {
            default: () => ({}),
            type: Object,
        },
    },
    data() {
        return {
            modal: false,
            modalName: '',
            isLoading: false,
            fullPage: true,
            modalSize: 'small',
        }
    },
    computed: {
        ...mapGetters({
            loader: 'loader',
        }),

        userMenuStyle() {
            return {
                position: 'fixed',
                top: `${this.$store.state.menuOffsetTop}px`,
                right: '40px',
                'z-index': 1000,
                overflow: 'hidden',
                opacity: 1,
            }
        },
    },

    created() {
        this.setLocale()
    },

    methods: {
        mountModal(payload) {
            if (payload.size !== undefined) this.modalSize = payload.size
            this.modalName = payload.name
            this.modal = true
        },

        appClicked(event) {
            this.$root.$emit('app-clicked', event)
        },

        setLocale() {
            this.$i18n.locale = navigator.language.substring(0, 2)
        },
    },
}
</script>

<style scoped lang="scss">
.application {
    width: 100%;
    height: 100%;

    .bottom-right {
        position: fixed;
        bottom: -10px;
        right: -330px;
        z-index: 0;
        overflow: hidden;
    }

    &__always-visible-structure {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
}

.application__main-content {
    width: 100%;
    max-width: 1880px;
    margin: 0 auto;
    margin-top: 20px;
    padding: 0 40px;
    position: relative;
    min-height: calc(100vh - 401px);

    .main-header {
        grid-column: 1 / -1;
        margin-bottom: 20px;
    }

    .heading-filters-section {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
    }

    &__header {
        grid-column: 1 / -1;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }
}

.menuShowHide-enter-active {
    animation: show-user-menu 0.3s;
}

.menuShowHide-leave-active {
    animation: show-user-menu 0.3s reverse;
}

@keyframes show-user-menu {
    0% {
        transform: translate(250px, 0);
        opacity: 0;
    }

    30% {
        opacity: 0.1;
    }

    100% {
        transform: translate(0, 0);
        opacity: 1;
    }
}
</style>
