<template>
    <div
        class="login-page"
        :style="{
            'background-image': `url('/assets/images/fe_background.png')`,
        }"
    >
        <img
            src="@/assets/images/linxfour.png"
            class="login-page__logo"
        />

        <div class="login">
            <h1 class="heading-300 text-center">
                {{ $t('§public.login_heading') }}
            </h1>
            <CoreStringInput
                v-model="email"
                :fullWidth="true"
                placeholder="§ui.placeholder_login_email"
                class="mb-3"
            />

            <CorePasswordInput
                v-model="password"
                class="mb-1"
                :fullWidth="true"
            />

            <div class="login-page__forgot-password mb-5">
                <CoreLinkPrimary
                    text="§ui_button.forgot_password"
                    iconName="arrow-right"
                    @click="goToForgotPassword"
                />
            </div>

            <div class="login__checkbox mb-5">
                <CoreCheckbox
                    v-model="remember"
                    name="remember_me"
                    label="§ui_button.remember_me"
                />
            </div>

            <CoreButtonPrimary
                @click="handleSubmit"
                label="§ui_button.sign_in"
                icon="pi-arrow-up-right"
                :loading="waiting"
            />
        </div>
        <NotificationContainer />
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import NotificationContainer from '@/components/NotificationContainer.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import CorePasswordInput from '@/components/core/CorePasswordInput.vue'
import CoreLinkPrimary from '@/components/core/CoreLinkPrimary.vue'
import CoreCheckbox from '@/components/core/CoreCheckbox.vue'
import { handleError } from '@/helpers/api'
import { getUserBrowserPreferredLocale } from '@/helpers/mixins.js'

export default {
    name: 'Login',

    components: {
        NotificationContainer,
        CoreButtonPrimary,
        CoreStringInput,
        CorePasswordInput,
        CoreLinkPrimary,
        CoreCheckbox,
    },

    mixins: [getUserBrowserPreferredLocale],

    data() {
        return {
            email: '',
            password: '',
            remember: false,
            waiting: false,
        }
    },

    mounted() {
        this.setLocale()
    },

    methods: {
        ...mapActions({
            login: 'user/login',
        }),

        goToForgotPassword() {
            const forgotPassRoute = this.route('password.request')
            window.location.href = forgotPassRoute
        },

        async handleSubmit() {
            try {
                if (this.waiting) return

                this.waiting = true
                const response = await this.$store.dispatch('user/login', {
                    vm: this,
                    email: this.email,
                    password: this.password,
                    remember: this.remember,
                })
                this.waiting = false

                if (response.failed && response.failed === true) {
                    this.$store.dispatch('user/handleLoginError', {
                        message: response.message[0],
                    })
                } else if (response.success && response.success === true) {
                    window.location.href = '/app/home'
                }
            } catch (error) {
                this.waiting = false
                handleError(this.$store.dispatch, error)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.login-page {
    width: 100%;
    min-height: 100vh;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__logo {
        position: absolute;
        left: 3vw;
        top: 0;

        max-width: 250px;
    }

    .login {
        position: relative;
        z-index: 1;

        background: $cn-10;
        box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        padding: 35px 100px;
        width: 40%;

        h1 {
            margin-bottom: 35px;
            font-weight: 300;
        }
    }

    &__forgot-password {
        display: flex;
    }
}
</style>
