<template>
    <div
        class="request-password-reset"
        :style="{
            'background-image': `url('/assets/images/fe_background.png')`,
        }"
    >
        <img
            src="@/assets/images/linxfour.png"
            class="request-password-reset__logo"
        />

        <div class="login">
            <h1 class="heading-300 text-center">
                {{ $t('§public.request_password_reset_heading') }}
            </h1>
            <CoreStringInput
                v-model="email"
                :fullWidth="true"
                placeholder="§ui.placeholder_login_email"
                class="mb-3"
            />

            <CoreButtonPrimary
                @click="handleSubmit"
                label="§ui_button.request_password_reset"
                icon="pi-arrow-up-right"
                :loading="waiting"
            />
        </div>
        <NotificationContainer />
    </div>
</template>

<script>
import NotificationContainer from '@/components/NotificationContainer.vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary.vue'
import CoreStringInput from '@/components/core/CoreStringInput.vue'
import { handleError } from '@/helpers/api'
import { getUserBrowserPreferredLocale } from '@/helpers/mixins.js'

export default {
    name: 'ResetPasswordRequest',

    components: {
        NotificationContainer,
        CoreButtonPrimary,
        CoreStringInput,
    },

    mixins: [getUserBrowserPreferredLocale],

    data() {
        return {
            email: '',
            waiting: false,
        }
    },

    created() {
        this.setLocale()
    },

    methods: {
        async handleSubmit() {
            try {
                if (this.waiting) return

                this.waiting = true
                await this.$store.dispatch('user/requestPasswordReset', {
                    vm: this,
                    email: this.email,
                })
                this.waiting = false
            } catch (error) {
                this.waiting = false
                handleError(this.$store.dispatch, error)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/main.scss';
@import '../../assets/styles/theme.css';

.request-password-reset {
    width: 100%;
    min-height: 100vh;

    background-repeat: no-repeat;
    background-position: center;
    background-attachment: fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    &__logo {
        position: absolute;
        left: 3vw;
        top: 0;

        max-width: 250px;
    }

    .login {
        position: relative;
        z-index: 1;

        background: $cn-10;
        box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
        border-radius: 16px;

        padding: 35px 100px;
        width: 40%;

        h1 {
            margin-bottom: 35px;
            font-weight: 300;
        }
    }

    &__forgot-password {
        display: flex;
    }
}
</style>
