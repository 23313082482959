<template>
    <div class="modal-unit-type-details">
        <StoreAwareInputWrapper
            :storePath="'unitType.inputs.name'"
            @update="setInput"
        >
            <template #default="{ value, handleInput }">
                <CoreStringInput
                    :value="value"
                    :validator="v$.name"
                    @input="handleInput"
                    :fullWidth="true"
                    placeholder="§ui.placeholder_eq_type_name"
                    class="mb-3"
                />
            </template>
        </StoreAwareInputWrapper>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_type.customization') }}
                    {{ isFieldRequired('customization') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <StoreAwareInputWrapper
                    :storePath="'unitType.inputs.customization'"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CorePercentageInput
                            :value="value"
                            :validator="v$.customization"
                            @input="handleInput"
                            :min="0"
                            :max="100"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_type.oem_industry') }}
                    {{ isFieldRequired('industry') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <StoreAwareInputWrapper
                    :storePath="'unitType.inputs.industry'"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CoreDropdown
                            :value="value"
                            :validator="v$.industry"
                            @input="handleInput"
                            :options="$store.state.industry.oemIndustryOptions"
                            :placeholder="$t('§ui.placeholder_select_industry')"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_type.list_price') }}
                    {{ isFieldRequired('value') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <StoreAwareInputWrapper
                    :storePath="'unitType.inputs.value'"
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CoreCurrencyInput
                            :value="value"
                            :validator="v$.value"
                            @input="handleInput"
                            :maxFractionDigits="0"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreBorderWrapper class="mb-3">
            <CoreTwoColumnsRow>
                <template #left>
                    <span
                        >{{ $t('§eq_type.is_stationary') }}
                        {{ isFieldRequired('is_stationary') }}</span
                    >
                </template>
                <template #right>
                    <div class="modal-unit-type-details__is-stationary">
                        <StoreAwareInputWrapper
                            :storePath="'unitType.inputs.is_stationary'"
                            @update="setInput"
                        >
                            <template #default="{ value, handleInput }">
                                <CoreRadio
                                    :id="isStationaryOptions[1].value"
                                    :value="value"
                                    @input="handleInput"
                                    :optionValue="isStationaryOptions[0].value"
                                    name="isStationary"
                                    :label="isStationaryOptions[0].label"
                                />
                                <CoreRadio
                                    :id="isStationaryOptions[1].value"
                                    :value="value"
                                    @input="handleInput"
                                    :optionValue="isStationaryOptions[1].value"
                                    name="isStationary"
                                    :label="isStationaryOptions[1].label"
                                />
                            </template>
                        </StoreAwareInputWrapper>
                    </div>
                </template>
            </CoreTwoColumnsRow>
        </CoreBorderWrapper>

        <hr class="mb-3" />

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-55">
                    {{ $t('§eq_type.refurbishment') }}
                    {{ isFieldRequired('refurbishment') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <StoreAwareInputWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    :storePath="'unitType.inputs.refurbishment'"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CoreCurrencyInput
                            :value="value"
                            :validator="v$.refurbishment"
                            @input="handleInput"
                            :maxFractionDigits="0"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minLength, maxLength, numeric, minValue, maxValue } from '@vuelidate/validators'
import StoreAwareInputWrapper from '@/components/StoreAwareInputWrapper'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CorePercentageInput from '@/components/core/CorePercentageInput'
import CoreRadio from '@/components/core/CoreRadio'
import CoreDropdown from '@/components/core/CoreDropdown'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'

const rules = {
    name: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(100),
    },
    customization: {
        numeric,
        minValue: minValue(0),
        maxValue: maxValue(100),
    },
    industry: {
        required,
    },
    is_stationary: {
        required,
    },
    value: {
        required,
        minValue: minValue(1),
        maxValue: maxValue(9999999999),
    },
    refurbishment: {
        required,
        minValue: minValue(0),
        maxValue: maxValue(9999999999),
    },
}

export default {
    name: 'ModalUnitTypeDetails',

    components: {
        StoreAwareInputWrapper,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CorePercentageInput,
        CoreRadio,
        CoreDropdown,
        CoreCurrencyInput,
    },

    data() {
        return {
            isStationaryOptions: [
                { value: '1', label: '§ui_button.stationary' },
                { value: '0', label: '§ui_button.movable' },
            ],

            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules, this.$store.state.unitType.inputs)
        })
    },

    methods: {
        setInput({ name, value }) {
            this.$store.commit('unitType/SET_INPUT', { name, value })
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-unit-type-details__is-stationary > div {
    display: flex;
    gap: 0 12px;
}
</style>
