import Uppy from '@uppy/core'

const checkFileName = (fileName, files) => {
    let fileWithSameNameExist = false

    Object.keys(files).forEach((key) => {
        if (files[key].name === fileName) {
            fileWithSameNameExist = true
        }
    })

    return fileWithSameNameExist
}

const getFileName = (fileName, files) => {
    let name = fileName
    let index = 1

    const lastDotIndex = name.lastIndexOf('.')
    const [nameOfFile, extension] = [
        fileName.slice(0, lastDotIndex),
        fileName.slice(lastDotIndex + 1),
    ]

    while (checkFileName(name, files)) {
        name = `${nameOfFile} (${index}).${extension}`

        index += 1
    }

    return name
}

const uppyConfig = {
    onBeforeFileAdded: (currentFile, files) => {
        const name = getFileName(currentFile.name, files)
        const modifiedFile = {
            ...currentFile,
            meta: {
                name,
            },
            name,
        }

        return modifiedFile
    },
}

export default new Uppy(uppyConfig)
