import { apiPost, apiPatch, handleError } from '@/helpers/api'

const namespaced = true

const state = () => ({
    city: '',
    country: null,
    id: null,
    postal_code: '',
    street: '',
})

const mutations = {
    SET_INPUT(state, { key, value }) {
        state[key] = value
    },

    RESET(state) {
        state.city = ''
        state.country = null
        state.id = null
        state.postal_code = ''
        state.street = ''
    },
}

const actions = {
    async saveAddress({ state, commit, dispatch }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const address = {
                city: state.city,
                country_id: state.country,
                postal_code: state.postal_code,
                street: state.street,
            }

            const response = await apiPost(vm.route('address.store'), address)
            dispatch('hideLoader', null, { root: true })

            if (response.success) {
                commit('SET_INPUT', {
                    key: 'id',
                    value: response.id,
                })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updateAddress({ state, dispatch }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const address = {
                city: state.city,
                country_id: state.country,
                postal_code: state.postal_code,
                street: state.street,
            }

            await apiPatch(vm.route('address.update', [state.id]), address)
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
