<template>
    <div class="client-item-modal">
        <ClientItemModalDetails
            v-if="tab < 1"
            @validityChange="handleValidityChange('isDetailsPageValid', $event)"
        />
        <ClientItemModalContactData
            v-else
            @validityChange="handleValidityChange('isContactPageValid', $event)"
            :saving="saving"
        />

        <div class="client-item-modal__buttons mt-5">
            <CoreLinkPrimary
                v-if="tab >= 1"
                text="§ui_button.back"
                class="client-item-modal__back-button"
                @click="back"
            />

            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="handleClose"
            />

            <CoreButtonPrimary
                v-if="isContinueButtonShown"
                :disabled="!isDetailsPageValid"
                label="§ui_button.continue"
                @click="next"
            />

            <CoreButtonPrimary
                v-else
                :disabled="isSaveButtonDisabled"
                :label="saveButtonLabel"
                icon="pi-arrow-right"
                :loading="saving"
                @click="save"
            />
        </div>
    </div>
</template>

<script>
import ClientItemModalDetails from './components/ClientItemModalDetails'
import ClientItemModalContactData from './components/ClientItemModalContactData'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import CoreLinkPrimary from '@/components/core/CoreLinkPrimary'

export default {
    name: 'ClientItemModal',

    components: {
        ClientItemModalDetails,
        ClientItemModalContactData,
        CoreButtonPrimary,
        CoreButtonSecondary,
        CoreLinkPrimary,
    },

    data() {
        return {
            tab: 0,
            saving: false,
            isDetailsPageValid: false,
            isContactPageValid: false,
        }
    },

    computed: {
        saveButtonLabel() {
            return this.saving ? '§ui_button.saving' : '§ui_button.save'
        },

        isContinueButtonShown() {
            if (this.tab === 1) return false

            return true
        },

        isSaveButtonDisabled() {
            if (this.isDetailsPageValid && this.isContactPageValid) {
                return false
            }

            if (this.isDetailsPageValid) {
                return false
            }

            return true
        },
    },

    destroyed() {
        this.$store.commit('client/RESET_INPUTS')
    },

    methods: {
        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },

        handleValidityChange(page, { isValid }) {
            this[page] = isValid
        },

        next() {
            this.tab = 1
        },

        back() {
            this.tab = 0
        },

        async save() {
            this.saving = true
            const response = await this.$store.dispatch('client/save', {
                vm: this,
            })
            this.saving = false

            this.$store.commit('caf/SET_INPUT', {
                name: 'client_select',
                value: response.client_id,
            })

            this.$store.commit('caf/SET_INPUT', {
                name: 'client_select_name',
                value: this.$store.state.client.inputs.name,
            })

            this.$store.commit('caf/SET_INPUT', {
                name: 'client_country_id',
                value: this.$store.state.client.inputs.country_id,
            })

            if (this.$store.state.caf.inputs.unit_country_id === '') {
                this.$store.commit('caf/SET_INPUT', {
                    name: 'unit_country_id',
                    value: this.$store.state.client.inputs.country_id,
                })
            }

            this.$emit('close-modal')
        },
    },
}
</script>

<style scoped lang="scss">
.client-item-modal {
    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }

    &__back-button {
        margin-right: auto;
    }
}
</style>
