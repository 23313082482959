<template>
    <div>
        <div class="contract-creation-date-label-container">
            <p>
                {{
                    $t(
                        'contract.contract_creation-tab1-delivery_will_be_made_on_the'
                    )
                }}
            </p>
            <p class="contract-creation-display-date">
                {{ displayDate }}
            </p>
            <p>
                {{
                    $t(
                        'contract.contract_creation-tab1-by_the_supplier_non_binding'
                    )
                }}
            </p>
        </div>
        <div class="contract-creation-date-picker-container">
            <vc-date-picker
                color="purple"
                :first-day-of-week="2"
                :locale="$store.state.user.user.preferred_locale"
                :available-dates="availableDates"
                :value="formData.contract_pdf_expected_delivery_date"
                @input="handleChange($event)"
            />
        </div>
    </div>
</template>

<script>
import { format, formatISO, parseISO, isValid } from 'date-fns'

export default {
    name: 'S1_DeliveryDate',
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        availableDates() {
            return { start: new Date(), end: null }
        },
        dateObject() {
            return parseISO(this.formData.contract_pdf_expected_delivery_date)
        },
        displayDate() {
            return this.formData.contract_pdf_expected_delivery_date !== ''
                ? this.displayAsDate(this.dateObject)
                : ''
        },
        isValidDate() {
            return isValid(this.dateObject)
        },
    },
    watch: {
        isValidDate(newValue) {
            this.$emit('isValid', { isValid: newValue })
        },
    },
    mounted() {
        this.$emit('isValid', { isValid: this.isValidDate })
    },
    methods: {
        handleChange(e) {
            this.$emit('input', {
                category: 'formData',
                key: 'contract_pdf_expected_delivery_date',
                value: formatISO(e),
            })
        },
    },
}
</script>

<style scoped lang="scss">
.contract-creation-date-label-container {
    display: flex;
    align-items: center;
    color: $vc-primary;

    & .contract-creation-display-date {
        width: 100px;
        height: 33px;
        margin: 0 10px;
        padding: 5px 10px;
        border-radius: 5px;
        background: rgba($vc-primary, 0.1);
    }
}

.contract-creation-date-picker-container {
    display: flex;
    justify-content: center;
    margin: 60px 0;

    & > .vc-container {
        background: rgba($vc-primary, 0.2);
    }
}
</style>
