import { apiGet, refreshError } from '@/helpers/api'

const namespaced = true

const state = () => ({
    pages: {},
    currentPage: 1,
    lastPage: 1,
    // filters values from the back-end (oem industry, end-user industry...)
    filters: {},
    // additional data from the back-end
    additionalData: {},
    //
    appliedFilters: {},
    queryString: '',
    refreshKey: 0,
    fetching: false,

    // set by the component when it is mounted
    tableFilters: null,
    tableFiltersOptions: null,
    showFilters: false,
})

const mutations = {
    UPDATE_OVERVIEW_PAGES(state, { page, pageArray }) {
        if (state.pages === null) state.pages = {}
        state.pages = { ...state.pages, [page]: pageArray }
    },
    SET_CURRENT_PAGE(state, { currentPage }) {
        state.currentPage = currentPage
    },
    SET_LAST_PAGE(state, { lastPage }) {
        state.lastPage = lastPage
    },
    SET_FILTERS(state, { filters }) {
        state.filters = { ...filters }
    },
    SET_ADDITIONAL_DATA(state, { additionalData }) {
        state.additionalData = { ...additionalData }
    },
    DELETE_CURRENT_AND_LAST_PAGE_CONTENT(state) {
        delete state.pages[state.currentPage]
        delete state.pages[state.lastPage]
    },
    RESET_STATE(state) {
        state.pages = {}
        state.currentPage = 1
        state.lastPage = 1
        state.filters = {}
        state.appliedFilters = {}
        state.queryString = ''
    },
    RESET_PAGES(state) {
        state.pages = {}
    },
    SET_QUERY_STRING(state, { text }) {
        state.queryString = text
    },
    SET_APPLIED_FILTERS_CATEGORIES(state, { category, type }) {
        Vue.set(state.appliedFilters, category, type)
    },
    SET_APPLIED_FILTERS(state, { category, payload }) {
        Vue.set(state.appliedFilters, category, payload)
    },
    REMOVE_APPLIED_FILTERS_ITEM(state, { category, itemIndex }) {
        state.appliedFiltersCopy[category] = state.appliedFilters[
            category
        ].slice(itemIndex, itemIndex + 1)
    },
    UPDATE_REFRESH_KEY(state) {
        state.refreshKey += 1
    },
    SET_FETCHING_STATUS(state, { status }) {
        state.fetching = status
    },

    SET_TABLE_FILTERS_AND_OPTIONS(
        state,
        { tableFilters, tableFiltersOptions }
    ) {
        state.tableFilters = tableFilters
        state.tableFiltersOptions = tableFiltersOptions
    },

    RESET_TABLE_FILTERS_AND_OPTIONS(state) {
        state.tableFilters = null
        state.tableFiltersOptions = null
        state.appliedFilters = {}
    },

    SET_SHOW_FILTERS(state, { show }) {
        state.showFilters = show
    },
}

const actions = {
    async fetchPage(
        { commit, state, dispatch },
        { page, routeName, forceRefetch, vm }
    ) {
        try {
            dispatch('showLoader', null, { root: true })

            if (
                state.pages === null ||
                (state.pages !== null && state.pages[page] === undefined) ||
                forceRefetch
            ) {
                commit('SET_FETCHING_STATUS', { status: true })
                const response = await apiGet(routeName)
                commit('SET_FETCHING_STATUS', { status: false })

                const pageArray = response.data.map((item) => ({
                    ...item,
                    uniqueId: window._.uniqueId(),
                }))

                commit('SET_LAST_PAGE', { lastPage: response.last_page })
                commit('UPDATE_OVERVIEW_PAGES', {
                    page,
                    pageArray,
                })

                if (response.filters !== undefined) {
                    commit('SET_FILTERS', { filters: response.filters })
                }

                if (response.additionalData !== undefined) {
                    commit('SET_ADDITIONAL_DATA', {
                        additionalData: { ...response.additionalData },
                    })
                }
            }

            dispatch('hideLoader', null, { root: true })
            return true
        } catch (error) {
            await refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getPage: (state) => (page) => {
        return state.pages !== null ? state.pages[page] : null
    },
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
