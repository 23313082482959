import XHRUpload from '@uppy/xhr-upload'
import { apiGet, apiPost, apiDownload, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const state = () => ({
    contract: null,
    contractData: null,
    contractId: null,
    clientId: null,
    contract_approved: 0,
    contract_user_approved: 0,
    isDeletable: null,
    billing_unit: '',

    // download pdf language component
    calculationPdfLanguage: '',
})

const mutations = {
    SET_CONTRACT_PROP(state, value) {
        state[Object.keys(value)[0]] = Object.values(value)[0]
    },

    UPDATE_CONTRACT_DATA(state, { data }) {
        state.contractData = data
    },

    CLEAR_CONTRACT_STATE(state) {
        state.contract = null
        state.contractData = null
        state.contractId = null
        state.clientId = null
        state.contract_approved = 0
        state.contract_user_approved = 0
        state.isDeletable = null
        state.billing_unit = ''
    },

    RESET_CONTRACT_DATA(state) {
        state.contractData = null
    },

    UPDATE_CONTRACT_ID(state, { id }) {
        state.contractId = id
    },

    UPDATE_CLIENT_ID(state, { id }) {
        state.clientId = id
    },

    UPDATE_IS_DELETABLE(state, { status }) {
        state.isDeletable = status
    },
    UPDATE_EXPECTED_UTIL_UNIT(state, { unit }) {
        state.billing_unit = unit
    },
    UPDATE_CONTRACT_DATA_FIELD(state, { field, value }) {
        state.contractData[field] = value
    },

    UPDATE_PDF_CALCULATION_LANGUAGE(state, { value }) {
        state.calculationPdfLanguage = value
    },
}

const actions = {
    setContractId({ commit }, { id }) {
        commit('SET_CONTRACT_PROP', { contractId: id })
    },

    setApproved({ commit }, { approved }) {
        commit('SET_CONTRACT_PROP', { contract_approved: approved })
    },

    setUserApproved({ commit }, { user_approved }) {
        commit('SET_CONTRACT_PROP', { contract_user_approved: user_approved })
    },

    clearContractData({ commit }) {
        commit('SET_CONTRACT_PROP', { contractId: null })
        commit('SET_CONTRACT_PROP', { clientId: null })
        commit('SET_CONTRACT_PROP', {
            contract_approved: 0,
        })
        commit('SET_CONTRACT_PROP', {
            contract_user_approved: 0,
        })
        commit('UPDATE_IS_DELETABLE', { status: null })
        commit('UPDATE_EXPECTED_UTIL_UNIT', {
            unit: '',
        })

        commit('RESET_CONTRACT_DATA')
    },

    async fetchContract({ state, commit, dispatch }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(vm.route('contract.show', { contract: id }))

            if (data === null) {
                return
            }

            const { contract_frontend_data } = data

            commit('SET_CONTRACT_PROP', {
                contractId: contract_frontend_data.id,
            })
            commit('SET_CONTRACT_PROP', {
                clientId: contract_frontend_data.client_id,
            })
            commit('SET_CONTRACT_PROP', {
                contract_approved: contract_frontend_data.approved,
            })
            commit('SET_CONTRACT_PROP', {
                contract_user_approved: contract_frontend_data.user_approved,
            })
            commit('UPDATE_IS_DELETABLE', {
                status: contract_frontend_data.allow_delete,
            })
            commit('UPDATE_EXPECTED_UTIL_UNIT', {
                unit: contract_frontend_data.measuring_unit,
            })

            commit('UPDATE_CONTRACT_DATA', { data: contract_frontend_data })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async deleteContract({ state, dispatch, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('contract.delete', { contract: state.contractId })
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                commit('pagination/DELETE_CURRENT_AND_LAST_PAGE_CONTENT', null, { root: true })
                vm.$router.push({ name: 'ContractIndex' })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    setAdminApprove({ dispatch }, { vm }) {
        dispatch('setApprove', { vm, route: 'contract.approve' })
    },

    setUserApprove({ dispatch }, { vm }) {
        dispatch('setApprove', { vm, route: 'contract.user_approve' })
    },

    async setApprove({ state, dispatch }, { vm, route }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route(route, {
                    contract: state.contractId,
                })
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                dispatch('fetchContract', { vm, id: state.contractId })
            }
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async downloadPdf({ dispatch, state }, payload) {
        try {
            dispatch('showLoader', null, { root: true })
            const filename = 'Pay-per-Use Pricing'
            const { contractId } = state

            const locale = state.calculationPdfLanguage

            const downloadRoute = payload.route === undefined ? 'click_finance.pdf' : payload.route

            const id = await apiDownload(
                payload.vm.route(downloadRoute, {
                    locale,
                    contract: contractId,
                }),
                filename
            )
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async downloadContractFile({ dispatch }, { vm, fileId, filename }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiDownload(vm.route('contract.download', { contract_file: fileId }), filename)
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    fetchContractFiles(context, { contract, type, vm }) {
        return apiGet(vm.route(`contract.files.${type}`, { contract }))
    },

    async updateContract({ state, dispatch, commit }, { formData, vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            await apiPost(
                vm.route('contract.update_contract', {
                    contract: state.contractId,
                }),
                formData
            )

            Object.keys(formData).forEach((field) => {
                commit('UPDATE_CONTRACT_DATA_FIELD', {
                    field,
                    value: formData[field],
                })
            })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updateCustomerUid({ state, dispatch, commit }, { formData, vm }) {
        try {
            dispatch('showLoader', null, { root: true })
            commit('modal/SET_CLOSE', null, { root: true })
            await apiPost(
                vm.route('contract.update_customer_uid', {
                    contract: state.contractId,
                }),
                formData
            )

            commit('UPDATE_CONTRACT_DATA_FIELD', {
                field: 'lessee_internal_purchase_number',
                value: formData.customer_uid,
            })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    // create contract pdf
    commitSavedAddress({ commit, rootGetters }, { address }) {
        const { city, country_id, id, postal_code, street } = address
        const country = rootGetters['country/getCountryListItemById'](country_id)
        commit('address/SET_INPUT', { key: 'id', value: id }, { root: true })
        commit('address/SET_INPUT', { key: 'country', value: country }, { root: true })
        commit('address/SET_INPUT', { key: 'city', value: city }, { root: true })
        commit('address/SET_INPUT', { key: 'postal_code', value: postal_code }, { root: true })
        commit('address/SET_INPUT', { key: 'street', value: street }, { root: true })
    },

    async getContractCreateData({ state, dispatch }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiGet(
                vm.route('contract.create.get_saved_data', [state.contractId])
            )
            // set address
            if (response.address !== undefined) {
                if (
                    response.address.id !== state.contractData.client_address_id ||
                    response.address.id !== state.contractData.oem_address_id
                ) {
                    dispatch('commitSavedAddress', {
                        address: response.address,
                    })
                }
            }
            dispatch('hideLoader', null, { root: true })

            return response
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async createContract({ state, dispatch }, { vm, formData, uppy }) {
        try {
            const isFileAdded = uppy.getFiles().length

            if (isFileAdded) {
                const headers = {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
                }

                uppy.setMeta(formData)

                uppy.use(XHRUpload, {
                    endpoint: vm.route('contract.create.save_data', {
                        contract: state.contractId,
                    }),
                    headers,
                    formData: true,
                    fieldName: 'files[]',
                    bundle: true,
                    allowedMetaFields: null,
                })

                uppy.upload()
            } else {
                const response = await apiPost(
                    vm.route('contract.create.save_data', {
                        contract: state.contractId,
                    }),
                    formData
                )

                if (response.status === 'success') {
                    return {
                        status: 'success',
                        contractFileName: response.contract_file_name,
                    }
                }

                return 'error'
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async downloadFinalContract({ state, dispatch }, { vm, contractFileName }) {
        try {
            dispatch('showLoader', null, { root: true })

            await apiDownload(
                vm.route('contract.create.download_pdf', [state.contractId]),
                contractFileName
            )

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async sendFinalContract({ state, dispatch }, { vm, trigger }) {
        try {
            dispatch('showLoader', null, { root: true })

            const requestParams = [state.contractId]

            if (trigger === 1 || trigger === 0) {
                requestParams.push(trigger)
            }

            await apiGet(vm.route('contract.create.send_pdf', requestParams))

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async finalizeContractPdf({ state, dispatch }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            const requestParams = [state.contractId]
            await apiGet(vm.route('contract.create.finalize_pdf', requestParams))

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    // KYC

    kycFetchData(context, { vm, contractId }) {
        return apiGet(vm.route('kyc.form_data', contractId))
    },

    async kycSaveData({ dispatch }, { vm, contractId, formData }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiPost(vm.route('kyc.form_store', contractId), {
                ...formData,
            })

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_saved', {
                        name: 'data',
                    }),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async kycNoNoticeableTransaction({ dispatch }, { vm, contractId }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiGet(vm.route('kyc.no_noticeable_transactions', contractId))

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_updated', {
                        name: 'data',
                    }),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async kycReportToOfficer({ dispatch }, { vm, contractId }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiGet(vm.route('kyc.report_to_officer', contractId))

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                console.log('called')
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.kyc_report_sent'),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
