import { apiGet, apiDownload, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const DEFAULT_STATE = {
    general_info: {},
    portfolio_criteria: {},
    financial_terms: {},
    credit_insight: {},
    usage_insight: {},
}

const state = () => ({
    ...DEFAULT_STATE,
})

const mutations = {
    SET_ITEMS_DATA(state, { items }) {
        Object.keys(items).forEach((item) => {
            state[item] = { ...items[item] }
        })
    },
    RESET_ITEM_DATA(state) {
        Object.keys(DEFAULT_STATE).forEach((item) => {
            state[item] = { ...DEFAULT_STATE[item] }
        })
    },
}

const actions = {
    async fetchData({ dispatch, commit }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const data = await apiGet(
                vm.route('contract.approval.contract', {
                    contract: id,
                })
            )
            if (data === null) {
                return;
            }
            commit('SET_ITEMS_DATA', { items: data })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async setApprove({ state, dispatch, commit }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiGet(
                vm.route('contract.approve', {
                    contract: id,
                })
            )

            dispatch('hideLoader', null, { root: true })

            commit('pagination/RESET_STATE', null, {
                root: true,
            })
            commit('RESET_ITEM_DATA')
            vm.$router.push({ name: 'ContractShow', props: { id } })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async setDirectorApprove({ state, dispatch, commit }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('contract.director_approve', {
                    contract: id,
                })
            )

            dispatch('hideLoader', null, { root: true })

            commit('pagination/RESET_STATE', null, {
                root: true,
            })
            commit('RESET_ITEM_DATA')
            vm.$router.push({ name: 'ContractShow', props: { id } })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async deleteContract({ dispatch }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('contract.approve.reject', { contract: id })
            )
            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                vm.$router.push({ name: 'ContractAdminApprovalIndex' })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async downloadPdf({ dispatch, rootState, state }, payload) {
        try {
            dispatch('showLoader', null, { root: true })
            const filename = 'Pay-per-Use Pricing'
            const contractId = state.general_info.id

            const locale = rootState.contract.calculationPdfLanguage

            await apiDownload(
                payload.vm.route('click_finance.pdf', {
                    locale,
                    contract: contractId,
                }),
                filename
            )

            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },
}

const getters = {
    getValue: (state) => (category, item) => state[category][item],
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
