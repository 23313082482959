<template>
    <div v-if="loading" class="loader">
        <i class="pi-spin pi pi-spinner"/>
    </div>
</template>

<script>
export default {
    name: 'CorePageLoader',
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style lang="scss" scoped>
.loader {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: rgba($cn-10, 0.7);
    z-index: 2;
}

.loader .pi {
    font-size: 2.4rem;
    color: rgba($cs, 0.7);
}
</style>
