<template>
    <div :id="notificationId" class="notification">
        <div class="notification__header">
            <div class="notification__icon">
                <span
                    v-if="notification.type === 'success'"
                    class="notification__icon--success"
                >
                    <mdicon name="check-decagram-outline" />
                </span>
                <span
                    v-else-if="notification.type === 'info'"
                    class="notification__icon--info"
                >
                    <mdicon name="clipboard-alert-outline" />
                </span>
                <span
                    v-else-if="notification.type === 'warning'"
                    class="notification__icon--warning"
                >
                    &#33;
                </span>
                <span
                    v-else-if="
                        notification.type === 'error' ||
                        notification.type === 'error-refresh' ||
                        notification.type === 'error-re-login'
                    "
                    class="notification__icon--error"
                >
                    <mdicon name="close-thick" />
                </span>
            </div>
            <h4 class="notification__heading">
                {{ getHeading() }}
            </h4>
            <span class="notification__close" @click="handleClose" />
        </div>
        <div class="notification__content">
            <p class="notification__text">{{ $t(notification.text) }}</p>
        </div>
        <div class="notification__button">
            <BaseButton @click="handleClose" buttonClass="button--primary">
                {{ $t('ui.button_ok') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
    name: 'Notification',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    data() {
        return { timeout: null }
    },
    computed: {
        notificationId() {
            return `notification-${this.notification.id}`
        },
    },
    mounted() {
        if (!this.notification.type.includes('error')) {
            const TIME_OUT_LENGTH = 5000

            this.timeout = setTimeout(
                () => this.handleClose(this.notification),
                TIME_OUT_LENGTH
            )
        }
    },
    created() {
        this.$root.$on('app-clicked', this.clickOutside)
    },
    beforeDestroy() {
        this.$root.$off('app-clicked', this.clickOutside)

        if (this.notification.type !== 'error') {
            clearTimeout(this.timeout)
        }
    },
    methods: {
        ...mapMutations({
            popNotification: 'notification/NOTIFICATION_POP',
        }),
        getHeading() {
            let heading

            switch (this.notification.type) {
                case 'success':
                    heading = this.$t('ui.notification_heading_success')
                    break
                case 'info':
                    heading = this.$t('ui.notification_heading_info')
                    break
                case 'warning':
                    heading = this.$t('ui.notification_heading_warning')
                    break
                case 'error':
                    heading = this.$t('ui.notification_heading_error')
                    break
                case 'error-re-login':
                    heading = this.$t('ui.notification_heading_error')
                    break
                default:
                    break
            }

            return heading
        },
        handleClose() {
            if (this.notification.type === 'error-re-login') {
                this.$store.dispatch('goToPage', { path: '/' }, { root: true })
            } else if (
                this.notification.type === 'error-refresh' &&
                window.localStorage
            ) {
                if (!localStorage.getItem('reload')) {
                    localStorage.setItem('reload', true)
                    window.location.reload()
                } else {
                    localStorage.removeItem('reload')
                    this.popNotification(this.notification)
                }
            } else {
                this.popNotification(this.notification)
            }
        },

        clickOutside(e) {
            const path = e.path || e.composedPath()
            if (
                // if path does not include id of the notification
                !path.some((el) => el.id === this.notificationId)
            ) {
                this.handleClose()
            }
        },
    },
}
</script>

<style scoped lang="scss">
.notification {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 550px;
    max-width: 90%;
    box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);
    border-radius: 16px;
    border: 2px solid $cn;

    padding: 42px 67px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 20px;
    line-height: 23.4px;
    font-weight: 300;
    color: $cs;

    & > div {
        display: flex;
    }

    &__header {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &__icon {
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        margin-right: 35px;

        &--success,
        &--info,
        &--error,
        &--warning {
            width: 20px;
            height: 20px;

            border-radius: 50px;
            background-color: $vc-white;

            font-size: 14px;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        &--success {
            color: $vc-approved;
        }

        &--error {
            color: $vc-declined;
        }
    }

    &__close {
        margin-left: auto;
        margin-top: 1px;
        position: relative;
        width: 20px;
        height: 20px;

        cursor: pointer;

        &::before {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            height: 20px;
            width: 2px;
            background: rgba($vc-primary, 0.7);

            transform: rotate(45deg);
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            height: 20px;
            width: 2px;
            background: rgba($vc-primary, 0.7);

            transform: rotate(-45deg);
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 55px;
    }

    &__heading {
        color: inherit;
        font-size: 20px;
        font-weight: 600;
        line-height: 23.4px;
        margin: 20px 0;
    }

    &__text {
        white-space: pre-line;
        margin: 0;
    }

    &__button {
        margin-top: 20px;
        width: 100%;
        display: flex;
        justify-content: flex-end;
    }
}

// firefox background fix
@-moz-document url-prefix() {
    div.notification {
        background: rgba(255, 255, 255, 0.9);
    }
}
</style>
