<template>
    <div class="contract_creation_insurance mb-40">
        <p>{{ $t('contract.insurance_of_the_lead_asset') }}:</p>
        <div>
            <BaseRadio
                :options="radioOptions"
                :checked-value="
                    formData.contract_pdf_lessee_insurance_responsibility
                "
                @input="handleChange($event)"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'S3_Insurance',
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    computed: {
        radioOptions() {
            return [
                {
                    label: this.$t(
                        'contract.lessee_should_be_responsible_for_taking_out_machine_break-down_insurance'
                    ),
                    value: '1',
                },
                {
                    label: this.$t(
                        'contract.lessor_should_be_responsible_for_taking_out_machine_break-down_insurance'
                    ),
                    value: '0',
                },
            ]
        },
    },
    watch: {
        'formData.contract_pdf_lessee_insurance_responsibility'(newValue) {
            const isValid = newValue !== ''
            this.$emit('isValid', { isValid })
        },
    },
    mounted() {
        const isValid =
            this.formData.contract_pdf_lessee_insurance_responsibility !== ''
        this.$emit('isValid', { isValid })
    },
    methods: {
        handleChange(e) {
            this.$emit('input', {
                category: 'formData',
                key: 'contract_pdf_lessee_insurance_responsibility',
                value: e,
            })
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_insurance {
    & > p {
        color: $vc-primary;
        margin-bottom: 20px;
    }

    & > div {
        margin-left: 60px;
    }
}
</style>