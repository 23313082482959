<template>
    <div
        v-if="notifications.length > 0"
        class="notification-container"
    >
        <Notification :notification="notifications[0]" />
    </div>
</template>

<script>
import { mapState } from 'vuex'
import Notification from './Notification.vue'

export default {
    name: 'NotificationContainer',
    components: { Notification },
    computed: mapState('notification', ['notifications']),
}
</script>

<style scoped lang="scss">
.notification-container {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 10px 30px;
    z-index: 1000;
}
</style>
