<template>
    <div class="modal-usage-details">
        <p class="text-300 font-bold mb-5">
            {{ $t('§eq_type.equipment_usage') }}
        </p>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                    {{ $t('§eq_type.planned_usage_per_a') }}
                    {{ isFieldRequired('planned_usage_per_a') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <div
                    class="flex-grow-1 flex-shrink-1 flex-basis-55 modal-usage-details__two-inputs"
                >
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.planned_usage_per_a'"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreNumberInput
                                :value="value"
                                :validator="v$.planned_usage_per_a"
                                :customNotificationMessage="
                                    v$.planned_usage_per_a !== undefined &&
                                    v$.planned_usage_per_a.notGreaterThan.$invalid
                                        ? v$.planned_usage_per_a.notGreaterThan.$message
                                        : null
                                "
                                @input="handleInput"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.billing_units'"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreMultiSelect
                                :validator="v$.billing_units"
                                :value="value"
                                @input="handleInput"
                                :options="
                                    billingUnitOptionsPlural(
                                        $store.state.billingUnit.billingUnitOptions
                                    )
                                "
                                :placeholder="
                                    $t('§ui.placeholder_select_expected_utilization_unit')
                                "
                                :fullWidth="true"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </div>
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow class="mb-3">
            <template #left>
                <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                    {{ $t('§eq_type.maximum_usage_per_a') }}
                    {{ isFieldRequired('maximum_usage_per_a') }}
                </CoreBorderWrapper>
            </template>
            <template #right>
                <StoreAwareInputWrapper
                    :storePath="'unitType.inputs.maximum_usage_per_a'"
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CoreNumberInput
                            :value="value"
                            :validator="v$.maximum_usage_per_a"
                            @input="handleInput"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>

        <hr class="mb-3" />

        <CoreTwoColumnsRow>
            <template #left>
                <p class="text-300 font-bold mb-5">
                    {{ $t('§eq_type.buy_back_option') }}
                </p>
            </template>
            <template #right>
                <StoreAwareInputWrapper
                    :storePath="'unitType.inputs.buy_back'"
                    @update="setInput"
                >
                    <template #default="{ value, handleInput }">
                        <CoreInputSwitch
                            :value="value"
                            @input="handleInput"
                            class="modal-usage-details__buy-back-switch"
                            name="buyBack"
                        />
                    </template>
                </StoreAwareInputWrapper>
            </template>
        </CoreTwoColumnsRow>

        <ModalResidualValueInputs
            v-if="$store.state.unitType.inputs.buy_back"
            @validityChange="handleValidityChange($event)"
        />
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { required, minValue, helpers } from '@vuelidate/validators'
import { notGreaterThan } from '@/helpers/customValidators.js'
import StoreAwareInputWrapper from '@/components/StoreAwareInputWrapper'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreNumberInput from '@/components/core/CoreNumberInput'
import CoreInputSwitch from '@/components/core/CoreInputSwitch'
import ModalResidualValueInputs from './ModalResidualValueInputs'
import CoreDropdown from '@/components/core/CoreDropdown'
import CoreMultiSelect from '@/components/core/CoreMultiSelect.vue'

const atLeastOneSelected = (value) => value.length > 0

export default {
    name: 'ModalUsageDetails',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreNumberInput,
        CoreInputSwitch,
        ModalResidualValueInputs,
        CoreDropdown,
        StoreAwareInputWrapper,
        CoreMultiSelect,
    },

    data() {
        return {
            areRVInputsValid: true,
            v$: {},
        }
    },

    computed: {
        isValid() {
            return !this.v$.$invalid && this.areRVInputsValid
        },

        rules() {
            return {
                planned_usage_per_a: {
                    required,
                    minValue: minValue(1),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message:
                                '§ui.input_error_planned_usage_can_not_be_higher_than_max_usage',
                        }),
                        notGreaterThan(() => this.$store.state.unitType.inputs.maximum_usage_per_a)
                    ),
                },
                maximum_usage_per_a: {
                    required,
                    minValue: minValue(1),
                },
                billing_units: {
                    required,
                    atLeastOneSelected,
                },
            }
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(this.rules, this.$store.state.unitType.inputs)
        })
    },

    methods: {
        setInput({ name, value }) {
            this.$store.commit('unitType/SET_INPUT', { name, value })
        },

        handleValidityChange(isValid) {
            this.areRVInputsValid = isValid
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-usage-details {
    &__buy-back-switch {
        align-self: flex-start;
    }

    &__two-inputs {
        display: flex;
        gap: 0 12px;
        width: 100%;

        & div:first-child {
            flex: 1 1 50%;
        }

        & div:last-child {
            flex: 0 0 50%;
        }
    }
}
</style>
