<template>
    <Button
        :label="getLabel"
        :icon="iconName"
        iconPos="right"
        :loading="loading"
        :class="classes"
        :disabled="disabled"
        @click="handleClick"
    />
</template>

<script>
import Button from 'primevue/button'

export default {
    name: 'CoreButtonSecondary',

    components: { Button },

    props: {
        label: {
            type: String,
            default: '',
        },

        icon: {
            type: String,
            default: null,
        },

        loading: {
            type: Boolean,
            default: false,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        alternative: {
            type: Boolean,
            default: false,
        },

        danger: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        classes() {
            return {
                'core-button-secondary': true,
                'core-button-secondary--disabled': this.disabled,
                'p-button-rounded': true,
                'core-button-secondary--alt': this.alternative,
                'core-button-secondary--danger': this.danger,
            }
        },

        getLabel() {
            return this.$t(this.label)
        },

        iconName() {
            return `pi ${this.icon}`
        },
    },

    methods: {
        handleClick() {
            if (!this.disabled) {
                this.$emit('click')
            }
        },
    },
}
</script>

<style lang="scss">
.p-button.core-button-secondary {
    background: $cn-10;
    padding: 8px 20px;
    border: 1px solid $cs;
    display: flex;

    & > .p-button-label {
        color: $cs;
        font-weight: 300;
        font-size: 1.6rem;
    }

    // icon

    & > .p-button-icon {
        color: $cs;
        font-size: 1.1rem;
        margin-left: 0.8rem;
    }

    & > .p-button-icon-right {
        order: 1;
        align-self: center;
    }

    // hover

    &.p-button:enabled:hover {
        cursor: pointer;
        background: $cn-10;
        border: 1px solid $cp;
    }

    // focus

    &.p-button:focus {
        background: $cn-10;
        border: 1px solid $cp-75;
        outline: 2px solid $cp-75;
    }

    // disabled

    &.core-button-secondary--disabled.p-component:disabled {
        opacity: 1;
    }

    &.core-button-secondary--disabled.p-button {
        cursor: not-allowed;
        background: $cn-10 !important;
        border: 1px solid $cn !important;

        & > .p-button-label {
            color: $cn !important;
        }

        // icon

        & > .p-button-icon {
            color: $cn !important;
        }
    }

    &.core-button-secondary--disabled.p-button:enabled:hover {
        cursor: not-allowed;
        background: $cn-10;
        border: 1px solid $cn;

        & > .p-button-label {
            color: $cn;
        }

        & > .p-button-icon {
            color: $cn;
        }
    }
    &.core-button-secondary--disabled.p-button:focus {
        background: $cn-10;
        outline: none;
    }

    &--alt {
        background: $cn-50;
        border: 1px solid $cn-50;

        & > .p-button-label {
            color: $cs-75;
        }

        & > .p-button-icon {
            color: $cs-75;
        }

        &.p-button:enabled:hover {
            background: $cp-50;
            border: 1px solid $cp-50;

            & > .p-button-label {
                color: $cs-75;
            }

            & > .p-button-icon {
                color: $cs-75;
            }
        }

        &.p-button:focus {
            background: $cn-50;
            border: 1px solid $cn-50;
            outline: 2px solid $cp-75;
        }
    }

    &--danger {
        border: 1px solid $color-error;

        & > .p-button-label {
            color: $cs;
        }

        & > .p-button-icon {
            color: $cs;
        }

        &.p-button:enabled:hover {
            background: $color-error;
            border: 1px solid $color-error;

            & > .p-button-label {
                color: $cn-10;
            }

            & > .p-button-icon {
                color: $cn-10;
            }
        }

        &.p-button:focus {
            background: $color-error;
            border: 1px solid $color-error;
            outline: 2px solid $cs-25;
        }
    }
}
</style>
