<template>
    <div class="reset-form-modal">
        <p class="text-200 mt-6 mb-6">
            {{ $t('§caf.reset_caf_form_paragraph-1') }}
        </p>
        <div
            v-if="showSaveFormButton"
            class="reset-form-modal__save-button mb-7"
        >
            <CoreLinkPrimary
                text="§ui_button.save_calculation"
                iconName="arrow-right"
                @click="handleSaveCalculation"
            />
        </div>
        <div class="reset-form-modal__buttons">
            <CoreButtonSecondary
                label="§ui_button.cancel"
                @click="handleClose"
            />
            <CoreButtonPrimary
                label="§ui_button.reset_calculation"
                @click="handleReset"
            />
        </div>
    </div>
</template>

<script>
import CoreLinkPrimary from '@/components/core/CoreLinkPrimary'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import { FORM_STATUS } from '../../../store/modules/caf'

export default {
    name: 'ResetFormModal',

    components: { CoreLinkPrimary, CoreButtonPrimary, CoreButtonSecondary },

    computed: {
        showSaveFormButton() {
            return this.$store.state.caf.formStatus === FORM_STATUS.calculated
        },
    },

    methods: {
        handleReset() {
            this.$store.commit('caf/RESET_FORM')
            this.$store.commit('modal/SET_CLOSE')
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },

        handleSaveCalculation() {
            this.$store.dispatch('caf/saveContract', { vm: this })
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss" scoped>
.reset-form-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 2.4rem;
    }
}
</style>
