const namespaced = true

const state = () => ({
    isOpen: false,
    name: '',
    size: '',
    title: '',
    iconName: '',
    data: {},
})

const mutations = {
    SET_OPEN(state, { name, size, title, iconName, data }) {
        state.isOpen = true
        state.name = name || ''
        state.size = size || ''
        state.title = title || ''
        state.iconName = iconName || ''
        state.data = data || {}
    },

    SET_TITLE(state, { title }) {
        state.title = title
    },

    SET_CLOSE(state) {
        state.isOpen = false
        state.name = ''
        state.size = ''
        state.title = ''
        state.iconName = ''
        state.data = {}
    },
}

const actions = {}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
