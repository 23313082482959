<template>
    <div
        class="vue-modal-wrapper"
        ref="vue-modal-wrapper"
    >
        <!-- @click="clickOutside" -->
        <div
            data-testid="modal"
            class="vue-modal"
            :class="getSizeClass"
        >
            <div
                class="vue-modal__close"
                @click="handleClose"
            >
                <mdicon
                    name="window-close"
                    size="32"
                />
            </div>
            <div class="vue-modal__heading mb-5 text-500">
                <div
                    v-if="iconName"
                    class="vue-modal__icon"
                >
                    <mdicon
                        :name="iconName"
                        size="42"
                    />
                </div>
                <h2>{{ heading }}</h2>
            </div>
            <component
                :is="component"
                @close-modal="handleClose"
            />
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import modalComponents from '@/components/modalComponents.js'

export default {
    name: 'Modal',

    data() {
        return {
            modalComponents,
        }
    },

    computed: {
        getSizeClass() {
            return `vue-modal--${this.size}`
        },

        modalName() {
            return this.$store.state.modal.name
        },

        iconName() {
            return this.$store.state.modal.iconName !== ''
                ? this.$store.state.modal.iconName
                : false
        },

        size() {
            return this.$store.state.modal.size
        },

        component() {
            return modalComponents[this.modalName]
        },

        heading() {
            return this.$t(this.$store.state.modal.title)
        },
    },

    methods: {
        ...mapMutations({
            closeModal: 'modal/SET_CLOSE',
        }),

        clickOutside(e) {
            if (e.target === this.$refs['vue-modal-wrapper']) {
                this.$store.commit('modal/SET_CLOSE')
            }
        },

        handleClose() {
            this.closeModal()
        },
    },
}
</script>

<style lang="scss" scoped>
.vue-modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba($vc-dark, 0.2);

    z-index: 100;

    display: flex;
    justify-content: center;
    align-items: center;
}

.vue-modal {
    border-radius: 16px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background: rgba(255, 255, 255, 0.95);
    box-shadow: 6px 10px 31px 9px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(30px);

    &__close {
        position: absolute;
        top: 16px;
        right: 16px;

        width: 48px;
        height: 48px;
        border-radius: 100px;
        background: $cn-75;

        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        &:hover {
            background: $cn;
        }
    }

    &__heading {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        gap: 5.6rem;

        .vue-modal__icon {
            width: 80px;
            height: 80px;
            border-radius: 100px;
            background: $cp-25;

            display: flex;
            justify-content: center;
            align-items: center;

            & > span {
                color: $cp;
            }
        }

        & > h2 {
            text-align: center;
            font-weight: 300;
        }
    }
}

.vue-modal--small {
    width: 800px;
    max-width: 90%;

    padding: 32px 24px;
}

.vue-modal--large {
    width: 974px;
    max-width: 90%;

    padding: 40px;
}
</style>
