import { apiGet, apiPost, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const EMAILS_AND_COMPLIANCE_DEFAULT_STATE = {
    'compliance-privacy_data_policy_link_en': '',
    'compliance-privacy_data_policy_link_de': '',
    'compliance-gf1': '',
    'compliance-gf2': '',
    'compliance-GTC_link_de': '',
    'compliance-GTC_link_en': '',
    'mail-data_export_insurance': '',
    'mail-invoice': '',
    'mail-kyc_report_to_officer': '',
    'mail-kyc_unremarkable_transaction': '',
    'mail-lessee': '',
    'mail-oem': '',
    'mail-operations': '',
    'mail-platform': '',
    'mail-sales': '',
    'mail-send_copy_to': '',
}

const CAF_DEFAULT_STATE = {
    'pricing-breakeven_high': null, // percent
    'pricing-breakeven_medium': null, // percent
    'pricing-breakeven_low': null, // percent
    'pricing-funding_spread': null, // percent
    'pricing-min_carry_high': null, // percent
    'pricing-min_carry_medium': null, // percent
    'pricing-min_carry_low': null, // percent
    'pricing-ppu_fee_decimals_threshold': null, // num
    'pricing-residual_value_day1_percent': null, // percent
    'pricing-residual_value_end_percent': null, // percent
}

const INVOICE_DEFAULT_STATE = {
    'invoice-fee_annual_contract_admin': null, // percent
    'invoice-fee_oneoff_contract_handling': null, //percent
    'invoice-fee_overdue_payment': null, // num
    'invoice-insurance_fee': null, // percent
    'invoice-insurance_stationary': null, // percent
    'invoice-insurance_movable': null, // percent
    'invoice-stamp_duty_months': null, // num
    'invoice-stamp_duty_perc': null, // percent
    'invoice-send_to': '', // str
    'invoice-payment_term_standard': null, // num
    'invoice-grace_period1': null, // num
    'invoice-grace_period2': null, // num
    'invoice-grace_period3': null, // num
}

const PERCENTAGES_INPUTS = [
    'fee_annual_contract_admin',
    'insurance_fee',
    'insurance_stationary',
    'insurance_movable',
    'funding_spread',
    'fee_oneoff_contract_handling',
    'stamp_duty_perc',
    'breakeven_high',
    'breakeven_medium',
    'breakeven_low',
    'min_carry_high',
    'min_carry_medium',
    'min_carry_low',
    'residual_value_day1_percent',
    'residual_value_end_percent',
]

function isPercentageInput(key) {
    return PERCENTAGES_INPUTS.findIndex((partialKey) => key.includes(partialKey)) > -1
}

const state = () => ({
    emailsAndCompliance: { ...EMAILS_AND_COMPLIANCE_DEFAULT_STATE },

    invoice: { ...INVOICE_DEFAULT_STATE },

    caf: { ...CAF_DEFAULT_STATE },
    caf_oem: { ...CAF_DEFAULT_STATE },
    oem_list: {},

    selected_oem: 0,
})

export const mutations = {
    SET_INPUT(state, { group, name, value }) {
        Vue.set(state[group], name, value)
    },

    CLEAR_INPUTS(state, { group }) {
        switch (group) {
            case 'caf':
                state[group] = { ...CAF_DEFAULT_STATE }
                break

            case 'invoice':
                state[group] = { ...INVOICE_DEFAULT_STATE }
                break

            default:
                state[group] = { ...EMAILS_AND_COMPLIANCE_DEFAULT_STATE }
                break
        }
    },

    SET_OEM_LIST(state, { oems }) {
        state.oem_list = oems
    },

    SET_OEM_ID(state, { id }) {
        state.selected_oem = id
    },
}

export const actions = {
    async getData({ dispatch, commit, state }, { route, group, vm }) {
        try {
            const data = await apiGet(route)
            commit('CLEAR_INPUTS', { group })
            if (data === null) {
                return
            }

            Object.keys(data).forEach((key) => {
                const name = key.split('.').join('-')
                let value = data[key]

                if (isPercentageInput(key)) {
                    value = Number(value) * 100
                }

                commit('SET_INPUT', {
                    group,
                    name,
                    value,
                })
            })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async saveData({ dispatch, state }, { route, group, vm }) {
        try {
            const data = Object.keys(state[group]).reduce((holder, key) => {
                if (isPercentageInput(key)) {
                    holder[key] = Number(state[group][key]) / 100
                } else {
                    holder[key] = state[group][key]
                }
                return holder
            }, {})

            await apiPost(route, {
                ...data,
            })

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_saved'),
            }

            dispatch('notification/add', notification, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async getOEMList({ dispatch, commit }, { vm }) {
        try {
            const data = await apiGet(vm.route('admin.oem_list'))
            commit('SET_OEM_LIST', { oems: data })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
