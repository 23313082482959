const namespaced = true

let id = 1

const state = () => ({
    notifications: [],
})

export const mutations = {
    NOTIFICATION_PUSH(state, notification) {
        const newNotification = {
            text: notification.text,
            type: notification.type,
            id: id,
        }

        state.notifications = [...state.notifications, newNotification]

        id++
    },

    NOTIFICATION_POP(state, notification) {
        state.notifications = state.notifications.filter(
            (item) => item.id !== notification.id
        )
    },
}

export const actions = {
    add({ commit }, notification) {
        commit('NOTIFICATION_PUSH', notification)
    },
}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
