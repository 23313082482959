<template>
    <DragAndDropUpload
        :fileName="displayFileName"
        :fileId="fileId"
        :filePath="filePath"
        :error="error"
        @delete-file="handleDeleteClick"
        @download-file="handleDownloadClick"
    />
</template>

<script>
import { mapActions } from 'vuex'
import uppyInstance from '@/helpers/uppyInstance'
import DragAndDropUpload from '@/components/DragAndDropUpload'

export default {
    name: 'UploadContainer',
    components: { DragAndDropUpload },
    props: {
        options: {
            type: Object,
            required: true,
        },
        file: {
            required: true,
            validator: (prop) => typeof prop === 'object' || prop === null,
        },
    },
    data() {
        return {
            error: '',
        }
    },
    computed: {
        uppy() {
            return uppyInstance
        },

        fileId() {
            if (this.file === null) {
                return null
            }

            return this.file.id
        },

        filePath() {
            if (this.file && this.file.filePath) return this.file.filePath
            return ''
        },

        displayFileName() {
            if (this.file === null) {
                return ''
            }

            return this.file.name
        },
    },
    watch: {
        fileId(newValue) {
            const isValid = newValue !== null
            this.$emit('isValid', { isValid })
        },
    },
    mounted() {
        this.uppy.on('file-added', this.handleFileAdded)
        this.uppy.on('restriction-failed', this.handleError)
        this.uppy.setOptions({
            restrictions: {
                allowedFileTypes: ['.pdf'],
            },
        })

        this.$emit('isValid', { isValid: false })

        if (this.file !== null) {
            this.$emit('isValid', { isValid: true })
        }
    },
    beforeDestroy() {
        this.uppy.off('file-added')
        this.uppy.off('restriction-failed')
    },
    methods: {
        ...mapActions({ downloadFile: 'contract/downloadContractFile' }),
        checkFileWithSameRelation() {
            const existingSameFile = this.uppy
                .getFiles()
                .reduce((holder, current) => {
                    if (current.relation === this.options.relation) {
                        holder = current.id
                    }
                    return holder
                }, '')
            if (existingSameFile !== '') {
                this.uppy.removeFile(existingSameFile)
            }
        },

        handleFileAdded(file) {
            this.checkFileWithSameRelation()

            file.relation = this.options.relation
            this.uppy.setMeta({
                [this.options.relation]: file.name,
            })

            this.$emit('add-contract-file', {
                id: file.id,
                name: file.name,
                relation: file.relation,
            })
        },

        handleError() {
            this.error = this.$t('contract.only_pdf_allowed')
            const self = this
            setTimeout(() => {
                self.error = ''
            }, 5000)
        },

        handleDownloadClick() {
            this.downloadFile({
                vm: this,
                fileId: this.fileId,
                filename: this.displayFileName,
            })
        },

        handleDeleteClick() {
            this.$emit('delete-contract-file', {
                relation: this.file.relation,
                id: this.fileId,
            })
        },
    },
}
</script>

<style lang="scss">
.contract-creation-upload {
    position: relative;
    margin-bottom: 100px;

    .uppy-Root {
        width: 60%;
        max-width: 208px;
        height: 34px;
        background-color: transparent;
    }

    .uppy-DragDrop-inner {
        display: none;
    }

    &__filename-wrapper,
    &__icon-wrapper {
        position: absolute;
        top: 0px;
        width: 66%;
        max-width: 240px;
        height: 32px;
        padding: 7px 12px;
        border: 1px solid $vc-primary;
        border-radius: 3px;
        background-color: $vc-gray-light;

        display: flex;
        align-items: center;

        font-size: 16px;
        font-family: $vff-primary;
        color: $vc-primary;
    }

    &__display-name {
        width: 90%;
        overflow: hidden;
        white-space: nowrap;
    }

    &__remove-icon {
        width: 10%;
        min-width: 24px;
        color: $vc-primary-light-1;
        cursor: pointer;
    }

    &__button {
        position: absolute;
        left: 260px;
        height: 34px;
    }

    &__info {
        position: absolute;
        top: 30px;
        left: 6px;
        font-size: 13px;
        font-family: $vff-primary;
        color: $vc-primary;
    }

    &__info--error {
        color: $vc-declined;
    }
}
</style>
