import { apiPost, apiGet, handleError, refreshError } from '@/helpers/api'
import { eventBus } from '@/main'

const namespaced = true

const state = () => ({
    users_options: [],
    selected_user: null,
    name: '',
    tokens: [],
})

const mutations = {
    UPDATE_USER_OPTIONS(state, { options }) {
        state.users_options = [...options]
    },
    UPDATE_TOKENS(state, { tokens }) {
        state.tokens = [...tokens]
    },
    UPDATE_SELECTED_USER(state, { selected_user }) {
        state.selected_user = selected_user
    },
    UPDATE_NAME(state, { name }) {
        state.name = name
    },
}

const actions = {
    async fetchUsers({ state, dispatch, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            const data = await apiGet(vm.route('user.owned_users'), {})
            const options = data.users.map((user) => {
                return { label: user.name, key: user.id }
            })
            commit('UPDATE_USER_OPTIONS', { options })
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async createToken({ state, dispatch, commit }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            const response = await apiPost(vm.route('token.store'), {
                user: state.selected_user,
                name: state.name,
            })

            dispatch('hideLoader', null, { root: true })

            if (response.status === 'success') {
                commit(
                    'modal/SET_OPEN',
                    {
                        name: 'ShowTokenModal',
                        size: 'small',
                        title: 'token.modal_heading',
                        data: {
                            accessToken: response.accessToken,
                        },
                    },
                    { root: true }
                )
                eventBus.$emit('pass-token-data-to-modal', {
                    token: response.accessToken,
                })
                commit('UPDATE_SELECTED_USER', { selected_user: null })
                commit('UPDATE_NAME', { name: '' })
                return true
            }

            if (response.status === 'failed') {
                const notification = {
                    type: 'error',
                    text: vm.$t('notifications.error_refresh'),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async revokeToken({ dispatch, state, commit, rootState }, { tokenId, vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            const for_user =
                state.selected_user === null
                    ? rootState.user.user.id
                    : state.selected_user

            const response = await apiGet(
                vm.route('token.revoke', {
                    token: tokenId,
                    for_user,
                })
            )

            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.token_revoked'),
                }

                dispatch('notification/add', notification, { root: true })
                dispatch('hideLoader', null, { root: true })
                return true
            }
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getSelectedUser: (state) => state.selected_user,
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
