<template>
    <div class="contract_creation_asset_service">
        <p>{{ $t('contract.service_of_the_leased_asset') }}:</p>
        <div>
            <div class="checkbox-wrapper">
                <BaseCheckbox
                    name="contract_pdf_lessee_maintenance"
                    :value="formData.contract_pdf_lessee_maintenance"
                    @input="
                        handleChange('contract_pdf_lessee_maintenance', $event)
                    "
                />
                <span class="inputs-label">
                    {{ $t('contract.maintenance') }}
                </span>
            </div>

            <div class="checkbox-wrapper">
                <BaseCheckbox
                    name="contract_pdf_lessee_consumables"
                    :value="formData.contract_pdf_lessee_consumables"
                    @input="
                        handleChange('contract_pdf_lessee_consumables', $event)
                    "
                />
                <span class="inputs-label">
                    {{ $t('contract.consumables') }}
                </span>
            </div>

            <div class="checkbox-wrapper">
                <BaseCheckbox
                    name="contract_pdf_lessee_other"
                    :value="formData.contract_pdf_lessee_other"
                    @input="handleChange('contract_pdf_lessee_other', $event)"
                />
                <span class="inputs-label">
                    {{ $t('contract.other') }}
                </span>
                <div class="contract_creation_asset_service__text_input">
                    <BaseInput
                        :value="formData.contract_pdf_lessee_other_name"
                        size="small"
                        :disabled="isOtherInputDisabled"
                        @input="
                            handleChange(
                                'contract_pdf_lessee_other_name',
                                $event
                            )
                        "
                    />
                </div>
            </div>

            <div class="checkbox-wrapper">
                <BaseCheckbox
                    name="lessee_none"
                    :value="none"
                    @input="handleNone($event)"
                />
                <span class="inputs-label">
                    {{ $t('contract.none') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import uppyInstance from '@/helpers/uppyInstance'

export default {
    name: 'S5_AssetService',
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            none: false,
        }
    },
    computed: {
        areInputsValid() {
            const {
                contract_pdf_lessee_maintenance,
                contract_pdf_lessee_consumables,
            } = this.formData
            return (
                this.isValueTrue(contract_pdf_lessee_maintenance) ||
                this.isValueTrue(contract_pdf_lessee_consumables) ||
                this.isOtherInputValid ||
                this.isValueTrue(this.none)
            )
        },
        isOtherInputValid() {
            return (
                this.isValueTrue(this.formData.contract_pdf_lessee_other) &&
                this.formData.contract_pdf_lessee_other_name.length > 0
            )
        },
        isValid() {
            return this.areInputsValid
        },
        isOtherInputDisabled() {
            return !this.formData.contract_pdf_lessee_other
        },
        uppy() {
            return uppyInstance
        },
        isAnythingExceptNoneSelected() {
            const {
                contract_pdf_lessee_maintenance,
                contract_pdf_lessee_consumables,
                contract_pdf_lessee_other,
            } = this.formData
            return (
                contract_pdf_lessee_maintenance === '1' ||
                contract_pdf_lessee_consumables === '1' ||
                contract_pdf_lessee_other === '1'
            )
        },
        maintenanceDescriptionUploadFileId() {
            return this.uppy.getFiles().reduce((holder, current) => {
                if (current.relation === 'maintenance_description') {
                    holder = current.id
                }
                return holder
            }, '')
        },
    },
    watch: {
        isValid(newValue) {
            this.$emit('isValid', { isValid: newValue })
        },
    },
    mounted() {
        this.$emit('isValid', { isValid: this.isValid })
        if (
            !this.isAnythingExceptNoneSelected &&
            this.maintenanceDescriptionUploadFileId !== ''
        ) {
            this.none = true
        }
    },
    beforeDestroy() {
        this.setBoolValues(
            'contract_pdf_lessee_maintenance',
            this.formData.contract_pdf_lessee_maintenance
        )
        this.setBoolValues(
            'contract_pdf_lessee_consumables',
            this.formData.contract_pdf_lessee_consumables
        )
        this.setBoolValues(
            'contract_pdf_lessee_other',
            this.formData.contract_pdf_lessee_other
        )
        // if user has went trough this part of form, and uploaded rvt
        // and comes back, and selects none, check is rvt pdf uploaded and if it is, delete it
        if (this.none) {
            if (this.maintenanceDescriptionUploadFileId !== '') {
                this.uppy.removeFile(this.maintenanceDescriptionUploadFileId)
            }
        }

        this.$emit('show-maintenance-description-upload-tab', {
            show: !this.none,
        })
    },
    methods: {
        handleChange(key, value) {
            if (this.none === true) this.none = false

            if (key === 'contract_pdf_lessee_other' && value === false) {
                this.handleChange('contract_pdf_lessee_other_name', '')
            }

            this.$emit('input', {
                category: 'formData',
                key,
                value,
            })
        },
        handleNone(value) {
            if (value) {
                this.handleChange('contract_pdf_lessee_maintenance', false)
                this.handleChange('contract_pdf_lessee_consumables', false)
                this.handleChange('contract_pdf_lessee_other', false)
                this.handleChange('contract_pdf_lessee_other_name', '')
            }
            this.none = value
        },
        // set true/false to '1' or '0' to avoid laravel validation error
        setBoolValues(key, boolValue) {
            this.$emit('input', {
                category: 'formData',
                key,
                value: this.convertBoolToString(boolValue),
            })
        },
        convertBoolToString(value) {
            return !value || value === '0' ? '0' : '1'
        },
        isValueTrue(value) {
            return value === true || value === '1' || value === 1
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_asset_service {
    margin-bottom: 40px;

    & > p {
        color: $vc-primary;
        margin-bottom: 20px;
    }

    & > div {
        margin-left: 60px;
    }

    .inputs-label {
        display: inline-block;
        margin-left: 32px;
        color: $vc-primary;
    }

    .checkbox-wrapper {
        display: flex;
        margin-bottom: 7px;
    }

    &__text_input {
        margin-left: 12px;
        margin-top: -4px;
    }
}
</style>