import { apiPost, apiGet, handleError, refreshError } from '@/helpers/api'
import { normalize, flatenObject } from '@/helpers/normalize'

const namespaced = true

const DEFAULT_INPUTS_STATE = {
    email: '',
    client: '',
    role: '',
    valid: 3,
    master: false,
}

const state = () => ({
    inputs: { ...DEFAULT_INPUTS_STATE },
    users: [],
    ownedUsersOptions: [],

    permissions: [],
})

const mutations = {
    UPDATE_INPUTS(state, inputs) {
        state.inputs = { ...inputs }
    },

    SET_INPUT(state, { name, value }) {
        state.inputs[name] = value
    },

    RESET_INPUTS_STATE(state) {
        state.inputs = { ...DEFAULT_INPUTS_STATE }
    },

    // permissions view
    UPDATE_STATE(state, { name, newState }) {
        state[name] = [...newState]
    },
}

export const actions = {
    updateInputs({ commit, state }, { name, value }) {
        const inputs = { ...state.inputs }
        inputs[name] = value
        commit('UPDATE_INPUTS', inputs)
    },

    async resendInvite({ dispatch }, { vm, id }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('invite.resend', { invite: id })
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                const notification = {
                    type: 'success',
                    text: vm.$t('notifications.success_invite_resend'),
                }

                dispatch('notification/add', notification, { root: true })
            }
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async sendInvite({ dispatch, commit, state }, { vm }) {
        try {
            dispatch('showLoader', null, { root: true })

            let params = {...state.inputs};
            params.valid *= 24;

            await apiPost(vm.route('invite.store'), params)

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_sent'),
            }
            dispatch('notification/add', notification, { root: true })

            commit('RESET_INPUTS_STATE')
            dispatch('hideLoader', null, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async updatePermission(
        { dispatch, commit, state },
        { vm, user_id, permission_name, permission_value }
    ) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiPost(
                vm.route('permission.edit', { user: user_id }),
                {
                    permission_name,
                    permission_value,
                }
            )
            dispatch('hideLoader', null, { root: true })
            if (response.status === 'success') {
                const usersCopy = state.users.map((user) => {
                    if (user.id === user_id) {
                        user.user_permissions = permission_value
                            ? [permission_name, ...user.user_permissions]
                            : user.user_permissions.filter(
                                  (permission) => permission !== permission_name
                              )
                    }
                    return user
                })

                commit('UPDATE_STATE', {
                    name: 'users',
                    newState: usersCopy,
                })

                return 'success'
            }
        } catch (error) {
            handleError(dispatch, error)

            return 'fail'
        }
    },

    async impersonate({ dispatch }, { vm, user }) {
        try {
            dispatch('showLoader', null, { root: true })
            const response = await apiGet(
                vm.route('permission.impersonate', { user })
            )

            if (response.success && response.success === true) {
                dispatch('hideLoader', null, { root: true })
                await dispatch(
                    'user/getUserData',
                    { vm },
                    {
                        root: true,
                    }
                )

                vm.$router.push({ name: 'Home' })
            }
        } catch (error) {
            dispatch('hideLoader', null, { root: true })
            refreshError(dispatch, vm)
        }
    },
}

const getters = {
    getClients: (state) => state.clients,
    getRoles: (state) => state.roles,
    // users for permissions view is just an array... so if state is still not fetched, return an empty array
    getUsersForPermissionsView: (state) => state.users,
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
