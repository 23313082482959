<template>
    <div>
        <CoreTwoColumnsRow
            v-for="(email, index) in emails"
            :key="email.index"
            class="mb-1"
        >
            <template #left>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-30"
                    placeholder="§ui.placeholder_input_invoice_email"
                    :condensed="true"
                    :value="email.email"
                    :name="`${group}-email-input`"
                    :disabled="saving"
                    @input="setInput(index, 'email', $event)"
                />
            </template>

            <template #right>
                <div class="flex-grow-1 flex-shrink-1 flex-basis-70">
                    <CoreTwoColumnsRow>
                        <template #left>
                            <CoreDropdown
                                class="flex-grow-1 flex-shrink-1"
                                :style="{
                                    'flex-basis': '36%',
                                }"
                                :options="emailTypeOptions"
                                :filter="false"
                                :condensed="true"
                                :placeholder="$t(placeholder)"
                                :value="email.type"
                                :name="`${group}-type-input`"
                                :disabled="saving"
                                @input="setInput(index, 'type', $event)"
                            />
                        </template>
                        <template #right>
                            <CoreStringInput
                                class="flex-grow-1 flex-shrink-1"
                                :style="{
                                    'flex-basis': '64%',
                                }"
                                placeholder="§ui.placeholder_input_email_contact_person"
                                :condensed="true"
                                :name="`${group}-person-input`"
                                :value="email.name"
                                :disabled="saving"
                                @input="setInput(index, 'name', $event)"
                            />

                            <div
                                class="remove-button"
                                @click="removeEmail(index)"
                            >
                                <mdicon
                                    size="20"
                                    name="minus"
                                />
                            </div>
                        </template>
                    </CoreTwoColumnsRow>
                </div>
            </template>
        </CoreTwoColumnsRow>

        <CoreLinkSecondary
            :text="headingText"
            @click="addNewEmail(group)"
        />
    </div>
</template>

<script>
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreLinkSecondary from '@/components/core/CoreLinkSecondary'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreDropdown from '@/components/core/CoreDropdown'
import InfoAccordion from '@/components/InfoAccordion.vue'
export default {
    name: 'MultiEmailList',

    components: {
        CoreStringInput,
        CoreLinkSecondary,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreDropdown,
        InfoAccordion,
    },

    props: {
        saving: {
            type: Boolean,
            default: false,
        },
        group: {
            type: String,
            required: true,
        },
        headingText: {
            type: String,
            required: true,
        },
        placeholder: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            emailTypeOptions: [
                { value: 'to', label: '§eq_operator.send_to' },
                { value: 'cc', label: '§eq_operator.send_cc' },
                { value: 'bcc', label: '§eq_operator.send_bcc' },
            ],
        }
    },

    computed: {
        emails() {
            return this.$store.state.client.inputs[this.group]
        },
    },

    methods: {
        addNewEmail() {
            this.$store.commit('client/APPEND_TO_EMAILS_ARRAY', { group: this.group })
        },

        setInput(index, name, value) {
            this.$store.commit('client/UPDATE_EMAILS_OBJECT', {
                group: this.group,
                index,
                name,
                value,
            })

            if (name === 'email') {
                const email = value
                const currentName = this.emails[index].name

                if (!currentName && email.includes('@')) {
                    const newName = email.split('@')[0]

                    this.$store.commit('client/UPDATE_EMAILS_OBJECT', {
                        group: this.group,
                        index,
                        name: 'name',
                        value: newName,
                    })
                }
            }
        },

        setIsOpen(value) {
            if (value !== this.isOpen) {
                this.isOpen = value
            }
        },

        removeEmail(index) {
            this.$store.commit('client/REMOVE_FROM_EMAILS_ARRAY', {
                group: this.group,
                index,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.remove-button {
    cursor: pointer;

    width: 24px;
    height: 24px;
    & > span {
        width: 24px;
        height: 24px;
        border-radius: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $color-error;

        color: $cn-10;
    }
}
</style>
