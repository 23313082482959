<template>
    <InputNumber
        v-model="value"
        suffix=" %"
        :name="name"
        :showButtons="showButtons"
        buttonLayout="horizontal"
        :inputStyle="{ 'text-align': 'right' }"
        :class="classes"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        :min="min"
        :max="max"
        :maxFractionDigits="maxFractionDigits"
        :minFractionDigits="minFractionDigits"
        :step="step"
        :locale="this.numberCurrencyDateFormat()"
        :placeholder="$t('§ui.placeholder_input_percentage')"
        :disabled="disabled"
        @focus="onFocus()"
        @blur="onBlur()"
    />
</template>

<script>
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'

export default {
    name: 'CorePercentageInput',

    components: { InputText, InputNumber },

    props: {
        name: {
            type: String,
            default: '',
        },

        inline: {
            type: Boolean,
            default: false,
        },

        min: {
            type: Number,
            default: null,
        },

        max: {
            type: Number,
            default: null,
        },

        maxFractionDigits: {
            type: Number,
            default: 0,
        },

        minFractionDigits: {
            type: Number,
            default: 0,
        },

        step: {
            type: Number,
            default: 1,
        },

        showButtons: {
            type: Boolean,
            default: false,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        validator: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        condensed: {
            type: Boolean,
            default: false,
        },
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        value: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        classes() {
            return {
                'core-percentage-input': true,
                'core-percentage-input--inline': this.inline,
                'core-percentage-input--full-width': this.fullWidth,
                'core-valid': this.validator && !this.validator.$invalid && this.validator.$dirty,
                'core-invalid': this.validator && this.validator.$invalid && this.validator.$dirty,
                'core-warning': false,
                'core-number-input--condensed': this.condensed,
            }
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },
    },

    methods: {
        onFocus() {
            this.isFocused = true
            this.$emit('focus')
        },

        onBlur() {
            this.isFocused = false
            this.$emit('blur')
        },
    },
}
</script>

<style lang="scss">
.core-percentage-input {
    width: 100%;

    & .p-inputtext.p-component::placeholder {
        color: $cs-25;
    }

    &--condensed > .p-inputtext {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }

    &.p-inputnumber-buttons-horizontal {
        .p-inputnumber-button {
            border-radius: 8px;
            border: none;
            color: #040f0f;
        }

        .p-button.p-inputnumber-button-down {
            background: #fafafa;
        }

        .p-button.p-inputnumber-button-up {
            background: #defdf3;
        }

        &.core-percentage-input--inline > .p-inputnumber-input {
            border: 1px solid transparent;
            padding: 0 0.75rem;
            width: 80px;
        }

        &.core-percentage-input--inline > .p-button-icon-only {
            width: 32px;
            height: 33px;
        }
    }
}

.core-invalid {
    &.p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-error;
        }
    }
}
.core-warning {
    .p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $color-warning;
        }
    }
}
.core-valid {
    .p-inputnumber.p-component,
    &.p-inputnumber.p-component:focus,
    &.p-inputnumber.p-component:hover {
        .p-inputtext {
            border: 1px solid $cp;
        }
    }
}
</style>
