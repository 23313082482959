<template>
    <div class="main-header">
        <div class="main-header__content">
            <span class="main-header__logo"
                ><LinxfourLogo @click.native="moveToHome"
            /></span>

            <NavigationMenu />

            <UserMenuButton class="main-header__user-menu-button" />
        </div>
    </div>
</template>

<script>
import LinxfourLogo from '../assets/svg/LinxfourLogo.vue'
import NavigationMenu from '../components/header/NavigationMenu.vue'
import UserMenuButton from '../components/header/UserMenuButton.vue'

export default {
    name: 'Header',
    components: { NavigationMenu, UserMenuButton, LinxfourLogo },

    data() {
        return {}
    },

    computed: {
        showNavigation() {
            if (this.$route.name === 'Home') return false

            return true
        },
    },

    watch: {
        numberOfNavItems() {
            this.onChange()
        },
    },

    methods: {
        moveToHome() {
            this.$move('/app/home')
        },
    },
}
</script>

<style lang="scss" scoped>
.main-header {
    position: sticky;
    width: 100%;
    height: 75px;
    top: 0;
    z-index: 3;
    background-color: $cn-10;

    border-bottom: 1px solid $cn;

    &__content {
        max-width: 1880px;
        margin: 0 auto;

        padding: 15px 40px;

        display: flex;
        align-items: center;
        justify-content: flex-start;
    }

    &__logo {
        width: 170px;
        height: 40px;
        margin-right: 80px;
        cursor: pointer;
    }

    &__user-menu-button {
        margin-left: auto;
    }
}
</style>
