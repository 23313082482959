<template>
    <div class="modal-residual-value-inputs">
        <header class="mb-3">
            <ItemHeader
                name="USAGE"
                :label="$t('§eq_type.dependent_on_usage')"
                :clickable="true"
                :progress="usageProgress"
                @click="handleClick($event)"
            />
            <ItemHeader
                name="USAGE_AND_AGE"
                :label="$t('§eq_type.dependent_on_usage_and_age')"
                :clickable="true"
                :progress="usageAndAgeProgress"
                @click="handleClick($event)"
            />
        </header>

        <div
            v-if="$store.state.unitType.inputs.selectedRvtModel === USAGE"
            class="mb-3"
        >
            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                        {{ $t('§eq_type.residual_value_after_1_year') }}
                        {{ isFieldRequired('residual_value_1_year') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.residual_value_1_year'"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreCurrencyInput
                                :validator="v$.residual_value_1_year"
                                :value="value"
                                :customNotificationMessage="
                                    v$.residual_value_1_year !== undefined &&
                                    v$.residual_value_1_year.notGreaterThan.$invalid
                                        ? v$.residual_value_1_year.notGreaterThan.$message
                                        : null
                                "
                                @input="handleInput"
                                :maxFractionDigits="0"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                        {{ $t('§eq_type.residual_value_after_5_years') }}
                        {{ isFieldRequired('residual_value_5_years') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.residual_value_5_years'"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreCurrencyInput
                                :validator="v$.residual_value_5_years"
                                :customNotificationMessage="
                                    v$.residual_value_5_years !== undefined &&
                                    v$.residual_value_5_years.notGreaterThan.$invalid
                                        ? v$.residual_value_5_years.notGreaterThan.$message
                                        : null
                                "
                                :value="value"
                                @input="handleInput"
                                :maxFractionDigits="0"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </template>
            </CoreTwoColumnsRow>
        </div>

        <div
            v-if="$store.state.unitType.inputs.selectedRvtModel === USAGE_AND_AGE"
            class="mb-3"
        >
            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                        {{ $t('§eq_type.residual_value_after_5_years_and_lower_usage') }}
                        {{ isFieldRequired('residual_value_5_years_lower_usage') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.residual_value_5_years_lower_usage'"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreCurrencyInput
                                :validator="v$.residual_value_5_years_lower_usage"
                                :value="value"
                                :customNotificationMessage="
                                    v$.residual_value_5_years_lower_usage !== undefined &&
                                    v$.residual_value_5_years_lower_usage.notGreaterThan.$invalid
                                        ? v$.residual_value_5_years_lower_usage.notGreaterThan
                                              .$message
                                        : null
                                "
                                @input="handleInput"
                                :maxFractionDigits="0"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                        {{ $t('§eq_type.residual_value_after_5_years_and_full_usage') }}
                        {{ isFieldRequired('residual_value_5_years_full_usage') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.residual_value_5_years_full_usage'"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreCurrencyInput
                                :validator="v$.residual_value_5_years_full_usage"
                                :value="value"
                                :customNotificationMessage="
                                    v$.residual_value_5_years_full_usage !== undefined &&
                                    v$.residual_value_5_years_full_usage.notGreaterThan.$invalid
                                        ? v$.residual_value_5_years_full_usage.notGreaterThan
                                              .$message
                                        : null
                                "
                                @input="handleInput"
                                :maxFractionDigits="0"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow class="mb-3">
                <template #left>
                    <CoreBorderWrapper class="flex-grow-1 flex-shrink-1 flex-basis-45">
                        {{ $t('§eq_type.residual_value_after_5_years_and_higher_usage') }}
                        {{ isFieldRequired('residual_value_5_years_higher_usage') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <StoreAwareInputWrapper
                        :storePath="'unitType.inputs.residual_value_5_years_higher_usage'"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                        @update="setInput"
                    >
                        <template #default="{ value, handleInput }">
                            <CoreCurrencyInput
                                :validator="v$.residual_value_5_years_higher_usage"
                                :value="value"
                                :customNotificationMessage="
                                    v$.residual_value_5_years_higher_usage !== undefined &&
                                    v$.residual_value_5_years_higher_usage.notGreaterThan.$invalid
                                        ? v$.residual_value_5_years_higher_usage.notGreaterThan
                                              .$message
                                        : null
                                "
                                @input="handleInput"
                                :maxFractionDigits="0"
                            />
                        </template>
                    </StoreAwareInputWrapper>
                </template>
            </CoreTwoColumnsRow>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import { requiredIf, requiredUnless, minValue, maxValue, helpers } from '@vuelidate/validators'
import { notGreaterThan } from '@/helpers/customValidators.js'
import StoreAwareInputWrapper from '@/components/StoreAwareInputWrapper'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'
import ItemHeader from '@/components/ItemHeader'

const USAGE = 'USAGE'
const USAGE_AND_AGE = 'USAGE_AND_AGE'

export default {
    name: 'ModalResidualValueInputs',

    components: {
        ItemHeader,
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreCurrencyInput,
        StoreAwareInputWrapper,
    },

    data() {
        return {
            USAGE,
            USAGE_AND_AGE,
            residualInputsValid: false,
            v$: {},
        }
    },

    watch: {
        residualInputsValid: {
            handler() {
                this.$emit('validityChange', this.residualInputsValid)
            },
            immediate: true,
        },
        'v$.$invalid': {
            handler() {
                this.residualInputsValid = !this.v$.$invalid
            },
            immediate: true,
        },
    },

    computed: {
        usageProgress() {
            return this.$store.state.unitType.inputs.selectedRvtModel === this.USAGE ? 1 : 0
        },

        usageAndAgeProgress() {
            return this.$store.state.unitType.inputs.selectedRvtModel === this.USAGE_AND_AGE ? 1 : 0
        },

        inputsStoreMirror() {
            return { ...this.$store.state.unitType.inputs }
        },

        rules() {
            return {
                residual_value_1_year: {
                    requiredIf: requiredIf(() => {
                        return this.usageProgress
                    }),
                    requiredUnless: requiredUnless(() => {
                        return this.usageAndAgeProgress
                    }),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message: '§ui.input_error_rv_can_not_be_higher_than_eq_value',
                        }),
                        notGreaterThan(() => this.$store.state.unitType.inputs.value)
                    ),
                    minValue: minValue(0),
                    maxValue: maxValue(9999999999),
                },
                residual_value_5_years: {
                    requiredIf: requiredIf(() => {
                        return this.usageProgress
                    }),
                    requiredUnless: requiredUnless(() => {
                        return this.usageAndAgeProgress
                    }),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message:
                                '§ui.input_error_residual_value_5_years_can_not_be_higher_than_residual_value_1_year',
                        }),
                        notGreaterThan(
                            () => this.$store.state.unitType.inputs.residual_value_1_year
                        )
                    ),
                    minValue: minValue(0),
                    maxValue: maxValue(9999999999),
                },
                residual_value_5_years_lower_usage: {
                    requiredIf: requiredIf(() => {
                        return this.usageAndAgeProgress
                    }),
                    requiredUnless: requiredUnless(() => {
                        return this.usageProgress
                    }),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message: '§ui.input_error_rv_can_not_be_higher_than_eq_value',
                        }),
                        notGreaterThan(() => this.$store.state.unitType.inputs.value)
                    ),
                    minValue: minValue(0),
                    maxValue: maxValue(9999999999),
                },
                residual_value_5_years_full_usage: {
                    requiredIf: requiredIf(() => {
                        return this.usageAndAgeProgress
                    }),
                    requiredUnless: requiredUnless(() => {
                        return this.usageProgress
                    }),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message:
                                '§ui.input_error_residual_value_5_years_full_usage_can_not_be_higher_than_residual_value_5_years_lower_usage',
                        }),
                        notGreaterThan(
                            () =>
                                this.$store.state.unitType.inputs.residual_value_5_years_lower_usage
                        )
                    ),
                    minValue: minValue(0),
                    maxValue: maxValue(9999999999),
                },
                residual_value_5_years_higher_usage: {
                    requiredIf: requiredIf(() => {
                        return this.usageAndAgeProgress
                    }),
                    requiredUnless: requiredUnless(() => {
                        return this.usageProgress
                    }),
                    notGreaterThan: helpers.withMessage(
                        () => ({
                            message:
                                '§ui.input_error_residual_value_5_years_higher_usage_can_not_be_higher_than_residual_value_5_years_full_usage',
                        }),
                        notGreaterThan(
                            () =>
                                this.$store.state.unitType.inputs.residual_value_5_years_full_usage
                        )
                    ),
                    minValue: minValue(0),
                    maxValue: maxValue(9999999999),
                },
            }
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(this.rules, this.$store.state.unitType.inputs)

            if (
                this.$store.state.unitType.inputs.residual_value_5_years_full_usage !== null ||
                this.$store.state.unitType.inputs.residual_value_5_years_higher_usage !== null ||
                this.$store.state.unitType.inputs.residual_value_5_years_lower_usage !== null
            ) {
                this.setInput({
                    name: 'selectedRvtModel',
                    value: this.USAGE_AND_AGE,
                })
            } else {
                this.setInput({ name: 'selectedRvtModel', value: this.USAGE })
            }
        })
    },

    methods: {
        handleClick(name) {
            this.setInput({ name: 'selectedRvtModel', value: name })

            this.setInput({ name: 'residual_value_1_year', value: null })
            this.setInput({ name: 'residual_value_5_years', value: null })
            this.setInput({
                name: 'residual_value_5_years_lower_usage',
                value: null,
            })
            this.setInput({
                name: 'residual_value_5_years_full_usage',
                value: null,
            })
            this.setInput({
                name: 'residual_value_5_years_higher_usage',
                value: null,
            })

            this.v$.$reset()
        },

        setInput({ name, value }) {
            this.$store.commit('unitType/SET_INPUT', { name, value })
        },
    },
}
</script>

<style scoped lang="scss">
.modal-residual-value-inputs {
    & > header {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 4px;
    }
}
</style>
