<template>
    <div class="upload-protocols-modal-wrapper" @submit.prevent="handleSave">
        <BaseFileUpload
            :uploadRoute="$store.state.service.uploadRouteAuthUser"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
    name: 'UploadProtocolsModal',
    computed: {
        getHeaderText() {
            return 'upload protocols'
        },
    },
    beforeDestroy() {
        this.updateInput({ fieldName: 'uploadRouteAuthUser', value: '' })
    },
    methods: {
        ...mapActions({
            updateInput: 'service/updateInput',
        }),
    },
}
</script>

<style lang="scss" scoped>
.upload-protocols-modal-wrapper {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: flex-start;
    }
}
</style>
