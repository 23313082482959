import Vue from 'vue'
import Vuex from 'vuex'

import address from './modules/address'
import caf from './modules/caf'
import client from './modules/client'
import contract from './modules/contract'
import contractFiles from './modules/contract_files'
import contractAdminApproval from './modules/contract_admin_approval'
import country from './modules/country'
import industry from './modules/industry'
import billingUnit from './modules/billing_unit'
import invite from './modules/invite'
import invoices from './modules/invoices'
import modal from './modules/modal'
import notification from './modules/notification'
import pagination from './modules/pagination'
import service from './modules/service'
import settings from './modules/settings'
import token from './modules/token'
import unit from './modules/unit'
import unitType from './modules/unit_type'
import user from './modules/user'

import { apiPost, apiDelete, handleError } from '@/helpers/api'

import { setInState, areViewsVarsInState, addNewItem, removeItem } from '@/helpers/viewVars'

Vue.use(Vuex)

export const actions = {
    async saveViewVarsItem({ dispatch, commit }, { vm, type, route, name }) {
        try {
            dispatch('showLoader', null, { root: true })

            commit('modal/SET_CLOSE', null, { root: true })

            const response = await apiPost(vm.route(route), {
                name,
            })

            dispatch('hideLoader', null, { root: true })

            const item = {
                value: response.id,
                label: response.name,
            }
            dispatch('addItemToViewVars', { listName: type, item })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async deleteViewVarsItem({ dispatch, commit }, { vm, propsToPass }) {
        try {
            dispatch('showLoader', null, { root: true })
            commit('modal/SET_CLOSE', null, { root: true })
            await apiDelete(vm.route(propsToPass.route, propsToPass.id))
            dispatch('hideLoader', null, { root: true })

            dispatch(
                'removeItemFromViewVars',
                {
                    listName: propsToPass.type,
                    item: {
                        value: propsToPass.id,
                        label: propsToPass.name,
                    },
                },
                { root: true }
            )

            const notification = {
                type: 'success',
                text: vm.$t('§ui.notification_success_deleted'),
            }

            dispatch('notification/add', notification, { root: true })
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    setViewsVars({ commit }, { lists }) {
        setInState({ lists, vm: this._vm, commit })
    },

    addItemToViewVars({ state, commit }, { listName, item }) {
        addNewItem({ state, commit, listName, item })
    },

    removeItemFromViewVars({ state, commit }, { listName, item }) {
        removeItem({ state, commit, listName, item })
    },

    updateItemInViewVars({ state, commit }, { listName, item }) {
        removeItem({ state, commit, listName, item })
        addNewItem({ state, commit, listName, item })
    },

    showLoader({ commit }) {
        commit('SHOW_LOADER')
    },
    hideLoader({ commit }) {
        commit('HIDE_LOADER')
    },
    goToPage({ dispatch }, { path }) {
        dispatch('showLoader')
        window.location.pathname = path
    },
}

export const mutations = {
    SHOW_LOADER(state) {
        state.loader = true
    },
    HIDE_LOADER(state) {
        state.loader = false
    },

    SET_VIEWS_VARS(state, { module, listName, list }) {
        state[module][listName] = [...list]
    },

    TOGGLE_USER_MENU(state, { menuOffsetTop }) {
        if (state.showMobileNavigation) state.showMobileNavigation = false

        state.showUserMenu = !state.showUserMenu
        state.menuOffsetTop = state.showUserMenu ? menuOffsetTop : 0
    },

    TOGGLE_MOBILE_NAVIGATION(state, { menuOffsetTop }) {
        if (state.showUserMenu) state.showUserMenu = false

        state.showMobileNavigation = !state.showMobileNavigation
        state.menuOffsetTop = state.showMobileNavigation ? menuOffsetTop : 0
    },

    SET_NUMBER_OF_NAV_ITEMS(state, { value }) {
        state.numberOfNavItems = value
    },
}

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
    state: {
        loader: false,
        showUserMenu: false,
        showMobileNavigation: false,
        menuOffsetTop: 0,
        numberOfNavItems: 10,
    },

    mutations,

    actions,

    getters: {
        loader: (state) => state.loader,

        isUserDataSet: (state) => {
            const userState = state.user.user

            if (userState.id === null || userState.email === '') {
                return false
            }

            if (!areViewsVarsInState({ state })) {
                return false
            }

            return true
        },
    },

    modules: {
        address,
        caf,
        client,
        contract,
        contractFiles,
        contractAdminApproval,
        country,
        industry,
        billingUnit,
        invite,
        invoices,
        modal,
        notification,
        pagination,
        service,
        settings,
        token,
        unit,
        unitType,
        user,
    },
})
