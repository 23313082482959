const namespaced = true

const state = () => ({
    billingUnitOptions: [],
})

export const mutations = {}

export const actions = {}

const getters = {}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
