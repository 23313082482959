<template>
    <div class="contract-creation-equipment-description-upload">
        <div v-if="isFileDisplayShown">
            <p>{{ $t('contract.saved_equipment_description_file') }}:</p>
        </div>
        <div v-else>
            <p>{{ $t('contract.please_attach_description_of_equipment') }}:</p>
        </div>
        <UploadContainer
            :options="getOptions"
            :file="file"
            @isValid="handleValidChange"
            @delete-contract-file="$emit('delete-contract-file', $event)"
            @add-contract-file="$emit('add-contract-file', $event)"
        />
    </div>
</template>

<script>
import UploadContainer from './UploadContainer.vue'

const options = {
    relation: 'equipment_description',
}

export default {
    name: 'S7_EquipmentDescription',
    components: { UploadContainer },
    props: {
        savedFiles: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        getOptions() {
            return options
        },
        file() {
            const filteredFile = this.savedFiles.filter(
                (savedFile) => savedFile.relation === 'equipment_description'
            )
            return filteredFile.length > 0 ? filteredFile[0] : null
        },
        isFileDisplayShown() {
            return this.file !== null
        },
    },
    methods: {
        handleValidChange({ isValid }) {
            this.$emit('isValid', { isValid })
        },
        handleDelete() {
            this.$emit('delete-contract-file', { relation: options.relation })
        },
    },
}
</script>

<style scoped lang="scss">
.contract-creation-equipment-description-upload {
    margin-bottom: 40px;

    & p {
        color: $vc-primary;
        margin-bottom: 20px;
    }

    & label {
        & input {
            display: none;
        }

        & p {
            display: inline-block;
            padding: 5px 14px;
            border-radius: 20px;

            font-family: $vff-primary;
            font-size: 20px;
            font-weight: $vfw-medium;
            line-height: 23px;

            cursor: pointer;

            border: 1px solid $vc-secondary;
            background: transparent;

            color: $vc-secondary;

            &:focus {
                outline: none;
            }

            &:hover,
            &:active {
                box-shadow: 0 0 2px rgba(0, 0, 0, 0.45);
            }
        }
    }
}
</style>