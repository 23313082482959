<template>
    <router-link v-if="to !== null" :class="classes" :to="to">
        <mdicon class="core-link-secondary__icon" name="plus" />
        <span class="core-link-secondary__text">{{ translatedText }}</span>
    </router-link>
    <div v-else :class="classes" @click="handleClick">
        <mdicon class="core-link-secondary__icon" name="plus" />
        <span class="core-link-secondary__text">{{ translatedText }}</span>
    </div>
</template>

<script>
export default {
    name: 'CoreLinkSecondary',

    props: {
        text: {
            required: true,
            type: String,
        },

        to: {
            type: Object,
            default: null,
        },
    },

    computed: {
        classes() {
            return {
                'core-link-secondary': true,
            }
        },

        translatedText() {
            return this.$t(this.text)
        },
    },

    methods: {
        handleClick() {
            if (this.to === null) {
                this.$emit('click')
            }
        },
    },
}
</script>

<style scoped lang="scss">
.core-link-secondary,
.core-link-secondary:visited {
    text-decoration: none;

    border: 1px solid transparent;
    display: flex;
    align-items: center;
    gap: 2.2rem;

    cursor: pointer;

    & > span.core-link-secondary__text {
        font-size: 1.6rem;
        padding: 0.1rem;
        line-height: 140%;
    }

    & > .core-link-secondary__icon {
        width: 32px;
        height: 32px;
        background-color: $cp-75;
        border-radius: 100px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &:hover .core-link-secondary__icon {
        background: $cp-50;
    }

    &:focus {
        border: 1px solid $cp-75;
    }

    &:active {
        color: $cp-75;
        border-bottom: 1px solid $cp-75;
    }
}
</style>
