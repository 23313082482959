<template>
    <div :class="classes" @click="handleClick">
        <p>{{ label }}</p>
        <div class="item-header__progress-wrapper">
            <div
                class="item-header__progress-bar"
                :style="{ width: progressPercent }"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: 'ItemHeader',

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },

        clickable: {
            type: Boolean,
            default: false,
        },

        progress: {
            type: Number,
            default: null,
        },

        startProgress: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        classes() {
            return {
                'item-header': true,
                'item-header--clickable': this.clickable,
            }
        },

        progressPercent() {
            const itemProgress = this.progress - this.startProgress

            if (itemProgress === null || itemProgress < 0) return '0%'

            if (itemProgress > 0.99) return '100%'

            return `${itemProgress * 100}%`
        },
    },

    methods: {
        handleClick() {
            if (this.clickable) {
                this.$emit('click', this.name)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.item-header {
    display: flex;
    flex-direction: column;

    & > p {
        padding: 24px 24px 20px 24px;
        background: $cn-50;

        font-size: 1.8rem;
        font-weight: 300;
        line-height: 140%;
    }

    &__progress-wrapper {
        height: 4px;
        width: 100%;

        background: $cn;
    }

    &__progress-bar {
        height: 100%;
        background: $cp-75;
    }
}

.item-header--clickable:hover {
    cursor: pointer;

    & p {
        background: $cp-25;
    }
}
</style>
