<template>
    <div class="contract-create-modal">
        <component
            :is="formSteps[currentStep].component"
            :step-index="formSteps[currentStep].id"
            :formData="formData"
            :bus="bus"
            :savedFiles="savedFiles"
            @input="handleInput"
            @isValid="handleValidChange"
            @show-delivery-address-save-button="handleShowDeliveryAddressSaveButton"
            @show-maintenance-description-upload-tab="handleMaintenanceDescriptionTab"
            @add-contract-file="handleFileAdded"
            @delete-contract-file="handleDeleteFile"
        />
        <div
            v-if="showCloseButton"
            class="contract-create-modal__buttons"
        >
            <BaseButton
                buttonClass="button--primary"
                @click="handleClose"
            >
                {{ $t('ui.button_close') }}
            </BaseButton>
        </div>
        <div
            v-else
            class="contract-create-modal__buttons"
        >
            <DisplayTabNumber
                v-if="isTabNumberShown"
                :currentTab="currentTab.id"
                :lastTab="lastTabNumber"
            />
            <BaseBackButton
                :emitClick="true"
                @click="backStep"
            />
            <BaseButton
                v-if="showDeliveryAddressSaveButton"
                buttonClass="button--primary"
                @click="saveDeliveryAddress"
                :disabled="!isValid"
            >
                {{ $t('ui.button_save_address') }}
            </BaseButton>
            <BaseButton
                v-else
                buttonClass="button--primary"
                @click="nextStep"
                :disabled="!isValid || $store.state.contract.contractData.isExpired"
            >
                {{ nextButtonText }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import uppyInstance from '@/helpers/uppyInstance'
import {
    S1_DeliveryDate,
    S2_Delivery,
    S3_Insurance,
    S4_PurchaseOption,
    S4a_ResidualValueTable,
    S5_AssetService,
    S5a_AssetMaintenance,
    S6_DataInterface,
    S7_EquipmentDescription,
    S8_TermsAndConditions,
    S9_Contract,
} from '@/views/Contract/ContractCreateModal'

import DisplayTabNumber from '@/views/Contract/ContractCreateModal/DisplayTabNumber'

const CONTRACT_TAB_TITLE = 'contract.s9-contract'

const STEPS = [
    {
        title: 'contract.s1-expected_delivery_date',
        component: S1_DeliveryDate,
        id: 1,
    },
    {
        title: 'contract.s2-delivery',
        component: S2_Delivery,
        id: 2,
    },
    {
        title: 'contract.s3-insurance',
        component: S3_Insurance,
        id: 3,
    },
    {
        title: 'contract.s4-purchase_option',
        component: S4_PurchaseOption,
        id: 4,
    },
    {
        title: 'contract.s5-service_of_the_asset',
        component: S5_AssetService,
        id: 5,
    },
    {
        title: 'contract.s6-data_interface',
        component: S6_DataInterface,
        id: 6,
    },
    {
        title: 'contract.s7-description_of_equipment',
        component: S7_EquipmentDescription,
        id: 7,
    },
    {
        title: CONTRACT_TAB_TITLE,
        component: S9_Contract,
        id: null,
    },
]

const RVT_UPLOAD_TAB = {
    title: 'contract.s4a-residual_value_table',
    component: S4a_ResidualValueTable,
    id: null,
}

const MAINTENANCE_DESCRIPTION_UPLOAD_TAB = {
    title: 'contract.s5a-description_of_the_asset_maintenance',
    component: S5a_AssetMaintenance,
    id: null,
}

const GTC_UPLOAD_TAB = {
    title: 'contract.s8-changes_general_terms_and_conditions',
    component: S8_TermsAndConditions,
    id: null,
}

export default {
    name: 'ContractCreateModal',
    components: { DisplayTabNumber },
    data() {
        return {
            formSteps: [...STEPS],
            currentStep: 0,
            formData: {
                contract_pdf_expected_delivery_date: '',
                contract_pdf_delivery_location: '',
                contract_pdf_lessee_insurance_responsibility: '',
                contract_pdf_buy_back: null,
                contract_pdf_lessee_maintenance: '0',
                contract_pdf_lessee_consumables: '0',
                contract_pdf_lessee_other: '0',
                contract_pdf_lessee_other_name: '',
                contract_pdf_asset_data_interface: null,
                contract_pdf_asset_data_interface_description: '',
            },
            isValid: false,
            showCloseButton: false,
            showDeliveryAddressSaveButton: false,
            bus: new Vue(),
            // fetched data for already saved contracts
            savedFiles: null,
        }
    },
    computed: {
        ...mapGetters({
            can: 'user/can',
        }),

        isAdmin() {
            return this.can('admin')
        },

        isOem() {
            return this.can('oem')
        },

        nextButtonText() {
            const nextTabTitle =
                this.formSteps[this.currentStep + 1] && this.formSteps[this.currentStep + 1].title
            if (nextTabTitle === CONTRACT_TAB_TITLE) {
                return this.$t('ui.button_finalize_contract')
            }
            return this.$t('ui.button_confirm')
        },

        currentTab() {
            return this.formSteps[this.currentStep]
        },

        isTabNumberShown() {
            return this.currentTab.id !== null
        },

        lastTabNumber() {
            return this.formSteps.filter((tab) => tab.id !== null).length
        },

        uppy() {
            return uppyInstance
        },
    },

    watch: {
        'formData.contract_pdf_buy_back'(newValue) {
            if (newValue == 1) {
                const buyBackTabIndex = this.getIndexByTitle('contract.s4-purchase_option')
                this.addTabToList(this.formSteps[buyBackTabIndex], {
                    ...RVT_UPLOAD_TAB,
                })
            } else if (newValue == 0) {
                this.removeTabFromList(RVT_UPLOAD_TAB)
            }
        },

        'currentStep'(newState) {
            this.setTitle({ title: this.formSteps[newState].title })
        },
    },

    async mounted() {
        this.getContractCreateData({ vm: this }).then((data) => {
            if (data.contract_data.contract_pdf_expected_delivery_date) {
                this.formData = { ...data.contract_data }
            }
            const savedFiles = Object.values(data.files).map((file) => {
                return {
                    id: file.id,
                    name: file.name,
                    relation: file.type,
                    filePath: file.type,
                }
            })

            this.savedFiles = [...savedFiles]
        })

        this.$emit('isValid', { isValid: this.isValidDate })

        if (this.isAdmin) {
            const tabBeforeGtcTabIndex = this.getIndexByTitle(
                'contract.s7-description_of_equipment'
            )
            this.addTabToList(this.formSteps[tabBeforeGtcTabIndex], {
                ...GTC_UPLOAD_TAB,
            })
        }
    },

    beforeDestroy() {
        const addedFiles = this.uppy.getFiles()
        addedFiles.forEach((file) => this.uppy.removeFile(file.id))
        this.uppy.close({ reason: 'unmount' })

        this.resetAddress()
    },

    methods: {
        ...mapMutations({
            resetAddress: 'address/RESET',
            setTitle: 'modal/SET_TITLE',
        }),

        ...mapActions({
            getContractCreateData: 'contract/getContractCreateData',
        }),

        handleClose() {
            this.$emit('close-modal')
        },

        nextStep() {
            this.currentStep += 1
            if (this.currentTab.title === CONTRACT_TAB_TITLE) {
                this.showCloseButton = true
            }
        },

        backStep() {
            this.currentStep -= 1
        },

        handleInput({ category, key, value }) {
            this[category][key] = value
        },

        handleValidChange({ isValid }) {
            this.isValid = isValid
        },

        handleShowDeliveryAddressSaveButton({ show }) {
            this.showDeliveryAddressSaveButton = show
        },

        handleMaintenanceDescriptionTab({ show }) {
            if (show) {
                const s5Index = this.getIndexByTitle('contract.s5-service_of_the_asset')
                const currentTabObj = this.formSteps[s5Index]
                this.addTabToList(currentTabObj, {
                    ...MAINTENANCE_DESCRIPTION_UPLOAD_TAB,
                })
            } else {
                this.removeTabFromList(MAINTENANCE_DESCRIPTION_UPLOAD_TAB)
            }
        },

        getIndexByTitle(title) {
            return this.formSteps.findIndex((el) => {
                return el.title === title
            })
        },

        addTabToList(tabBefore, tabObject) {
            const formStepsCopy = [...this.formSteps]
            const startIndex = this.getIndexByTitle(tabBefore.title) + 1
            if (startIndex !== -1) {
                formStepsCopy.splice(startIndex, 0, tabObject)
                this.formSteps = [...formStepsCopy]
            }
        },

        removeTabFromList(tabToRemove) {
            const formStepsCopy = [...this.formSteps]
            const indexOfResidualValueTable = this.getIndexByTitle(tabToRemove.title)

            if (indexOfResidualValueTable !== -1) {
                this.formSteps = [
                    ...formStepsCopy.slice(0, indexOfResidualValueTable),
                    ...formStepsCopy.slice(indexOfResidualValueTable + 1),
                ]
            }
        },

        saveDeliveryAddress() {
            this.bus.$emit('save-address')
        },

        handleFileAdded(file) {
            this.savedFiles = [...this.savedFiles, file]
        },

        handleDeleteFile({ relation, id }) {
            this.savedFiles = this.savedFiles.filter((file) => file.relation !== relation)

            this.uppy.removeFile(id)
        },
    },
}
</script>

<style lang="scss" scoped>
.contract-create-modal {
    padding: 62px 80px;
    font-family: $vff-primary;

    &__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 10px;
    }
}
</style>
