export const notGreaterThan = (getComparisonValue) => (value) => {
    const threshold = getComparisonValue()

    const isNumeric = (num) => !isNaN(num) && isFinite(num)
    if (!isNumeric(threshold) || !isNumeric(value)) {
        return false
    }

    return !value || !threshold || parseFloat(value) <= parseFloat(threshold)
}
