<template>
    <div
        v-if="icon === ''"
        class="core-string-input-wrapper"
        :style="styles"
    >
        <InputText
            v-model="value"
            ref="inputElement"
            :name="name"
            :placeholder="$t(placeholder)"
            :class="classes"
            :style="styles"
            :disabled="disabled"
            @focus="onFocus()"
            @blur="onBlur()"
        />

        <InputNotification
            v-if="isFocused && validator !== null"
            :validator="validator"
        />
    </div>
    <div
        v-else
        class="core-string-input-wrapper"
        :style="styles"
    >
        <span class="p-input-icon-right">
            <InputText
                v-model="value"
                ref="inputElement"
                :name="name"
                :placeholder="$t(placeholder)"
                :class="classes"
                :disabled="disabled"
                @focus="onFocus()"
                @blur="onBlur()"
            />
            <i :class="iconClass" />
        </span>

        <InputNotification
            v-if="isFocused && validator !== null"
            :validator="validator"
        />
    </div>
</template>

<script>
import InputText from 'primevue/inputtext'
import InputNotification from '@/components/InputNotification.vue'

export default {
    name: 'CoreStringInput',

    components: {
        InputText,
        InputNotification,
    },

    props: {
        name: {
            type: String,
            default: '',
        },

        placeholder: {
            type: String,
            default: null,
        },

        fullWidth: {
            type: Boolean,
            default: false,
        },

        icon: {
            type: String,
            default: '',
        },

        condensed: {
            type: Boolean,
            default: false,
        },

        width: {
            type: Number,
            default: null,
        },

        validator: {
            type: Object,
            default: null,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isFocused: false,
        }
    },

    mounted() {
        if (this.validator) {
            if (this.value === null || this.value === '') {
                this.validator.$reset()
            } else {
                this.validator.$touch()
            }
        }
    },

    computed: {
        value: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)

                if (this.validator) {
                    this.validator.$touch()
                }
            },
        },

        classes() {
            return {
                'core-string-input': true,
                'core-string-input--full-width': this.fullWidth,
                'core-string-input--condensed': this.condensed,
                'core-valid': this.isValid,
                'core-invalid': this.isInvalid,
                'core-warning': this.isWarning,
            }
        },

        isValid() {
            if (this.validator !== null && !this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isInvalid() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'error'
            ) {
                return true
            }

            if (this.validator !== null && this.validator.$invalid && this.validator.$dirty) {
                return true
            }

            return false
        },

        isWarning() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined &&
                this.customNotificationMessage.severity === 'warn'
            ) {
                return true
            }

            return false
        },

        validatorErrors() {
            if (this.validator.$error === undefined) {
                return {}
            }
            return this.validator.$error
        },

        validatorParams() {
            if (this.validator.$params === undefined) {
                return {}
            }
            return this.validator.$params
        },

        iconClass() {
            return `core-string-input__icon pi ${this.icon}`
        },

        styles() {
            const stylesObject = {}

            if (this.width !== null && typeof this.width === 'number') {
                stylesObject.width = `${this.width}px`
            }

            return stylesObject
        },
    },

    methods: {
        onFocus() {
            this.isFocused = true
            this.$emit('focus')
        },

        onBlur() {
            this.isFocused = false
            this.$emit('blur')
        },

        focus() {
            this.$refs.inputElement.$el.focus()
        },
    },
}
</script>

<style lang="scss">
.core-string-input-wrapper {
    position: relative;
}

.p-input-icon-right {
    position: relative;
}

.core-string-input {
    position: relative;

    width: 100%;

    &__icon {
        position: absolute;
        top: 50%;
        transform: translate(-12px, -50%);
    }

    &.p-inputtext.p-component::placeholder {
        color: $cs-25;
    }

    &--condensed {
        padding: 1.4rem !important;
        font-size: 1.6rem !important;
    }
}

.core-invalid {
    &.p-inputtext.p-component,
    &.p-inputtext.p-component:focus,
    &.p-inputtext.p-component:hover {
        border: 1px solid $color-error;
    }
}
.core-warning {
    &.p-inputtext.p-component,
    &.p-inputtext.p-component:focus,
    &.p-inputtext.p-component:hover {
        border: 1px solid $color-warning;
    }
}
.core-valid {
    &.p-inputtext.p-component,
    &.p-inputtext.p-component:focus,
    &.p-inputtext.p-component:hover {
        border: 1px solid $cp;
    }
}
</style>
