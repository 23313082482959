<template>
    <div class="equipment-notes-modal">
        <JsonEditorVue
            v-model="notes"
            :stringified="false"
            mode="text"
            class="json-editor mb-5"
        />

        <CoreButtonPrimary
            label="§ui_button.update"
            icon="pi-check"
            @click="update"
        />
    </div>
</template>

<script>
import JsonEditorVue from 'json-editor-vue'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'

export default {
    name: 'EquipmentNotesModal',

    components: {
        JsonEditorVue,
        CoreButtonPrimary,
    },

    data() {
        return {
            notes: '',
        }
    },

    mounted() {
        this.setData()
    },

    methods: {
        setData() {
            this.notes = JSON.parse(this.$store.state.unit.inputs.notes)
        },

        update() {
            this.$store.commit('unit/SET_INPUT', {
                name: 'notes',
                value: JSON.stringify(this.notes),
            })

            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss">
.equipment-notes-modal {
    .json-editor {
        & .jse-json-node.jse-root.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm.svelte-6mjnsm,
        .jse-main.svelte-ybuk0j {
            min-height: 500px;
        }
    }
}
</style>
