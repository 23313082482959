<template>
    <div id="user-menu" class="user-menu" :style="{ top: `${top}px` }">
        <div class="user-menu__items">
            <div
                class="user-menu__item"
                data-testid="user-menu-profile-button"
                @click="handleClick"
            >
                <mdicon class="icon" size="32" name="account-edit" />
                {{ $t('navigation.profile') }}
            </div>
            <div class="hr" />
            <div
                class="user-menu__item"
                data-testid="user-menu-logout-button"
                @click="handleLogout"
            >
                <mdicon class="icon" size="32" name="logout" />
                {{ $t('navigation.log out') }}
            </div>
        </div>
    </div>
</template>

<script>
const USER_MENU_OFFSET_ZERO = 0

export default {
    name: 'UserMenu',

    computed: {
        top() {
            return this.$store.state.menuOffsetTop
        },
    },

    methods: {
        handleClick() {
            this.$move({ name: 'UserProfile' })

            this.$store.commit('TOGGLE_USER_MENU', {
                menuOffsetTop: USER_MENU_OFFSET_ZERO,
            })
        },

        handleLogout() {
            this.$store.dispatch('user/logout', { vm: this })

            this.$store.commit('TOGGLE_USER_MENU', {
                menuOffsetTop: USER_MENU_OFFSET_ZERO,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.user-menu {
    height: 100%;
    width: 100%;

    border-radius: 16px;
    border: 1px solid $vc-white;
    background: linear-gradient(to bottom right, $cp, lighten($cp, 10%));

    padding: 30px 30px 20px 20px;

    &__item {
        margin-bottom: 20px;
        color: $cs;
        display: flex;
        align-items: center;

        cursor: pointer;

        & > .icon {
            margin-right: 12px;
        }
    }
}
</style>
