<template>
    <div class="contract_creation_delivery">
        <p>{{ $t('contract.place_of_delivery_or_handover') }}:</p>
        <div class="contract_creation_delivery__radio-options mb-40">
            <BaseRadio
                :options="radioOptions"
                :checked-value="formData.contract_pdf_delivery_location"
                @input="
                    handleChange(
                        'formData',
                        'contract_pdf_delivery_location',
                        $event
                    )
                "
            />
        </div>
        <div
            v-if="showNewAddressInputs"
            class="address"
        >
            <div class="mb-20">
                <span class="address__label">{{ $t('contract.street') }}</span>
                <div class="address__input">
                    <BaseInput
                        :value="$store.state.address.street"
                        :validator="v$.street"
                        size="small"
                        @input="handleAddressChange('street', $event)"
                    />
                </div>
            </div>
            <div class="mb-20">
                <span class="address__label">
                    {{ $t('contract.postal_code') }}
                </span>
                <div class="address__input">
                    <BaseInput
                        :value="$store.state.address.postal_code"
                        :validator="v$.postal_code"
                        size="small"
                        @input="handleAddressChange('postal_code', $event)"
                    />
                </div>
            </div>
            <div class="mb-20">
                <span class="address__label">{{ $t('contract.city') }}</span>
                <div class="address__input">
                    <BaseInput
                        :value="$store.state.address.city"
                        :validator="v$.city"
                        size="small"
                        @input="handleAddressChange('city', $event)"
                    />
                </div>
            </div>
            <div class="mb-20">
                <span class="address__label">{{ $t('contract.country') }}</span>
                <div class="address__input">
                    <BaseSelect
                        :value="$store.state.address.country"
                        placeholder=""
                        :options="
                            $store.state.country
                                .countryFinancingAvailableOptions
                        "
                        size="small"
                        @input="handleAddressChange('country', $event)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength } from '@vuelidate/validators'

const NEW_ADDRESS_DEFAULT_ID = 12345678910

export default {
    name: 'S2_Delivery',

    props: {
        formData: {
            type: Object,
            required: true,
        },
        bus: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            newAddressId: NEW_ADDRESS_DEFAULT_ID,
            isAddressDataChanged: false,

            v$: {},
        }
    },

    computed: {
        isDeliveryLocationDefault() {
            return (
                Number(this.formData.contract_pdf_delivery_location) ===
                Number(NEW_ADDRESS_DEFAULT_ID)
            )
        },

        radioOptions() {
            return [
                {
                    label: this.$t('contract.address_of_the_lessee'),
                    value: this.$store.state.contract.contractData
                        .client_address_id,
                },
                {
                    label: this.$t('contract.address_of_the_supplier'),
                    value: this.$store.state.contract.contractData
                        .oem_address_id,
                },
                {
                    label: this.$t('contract.delivery_other_provisions'),
                    value: this.newAddressId,
                },
            ]
        },

        showNewAddressInputs() {
            if (
                Number(this.formData.contract_pdf_delivery_location) ===
                Number(this.newAddressId)
            ) {
                return true
            }
            return false
        },

        rules() {
            return {
                street: {
                    required,
                    maxLength: maxLength(200),
                },
                postal_code: {
                    required,
                    maxLength: maxLength(32),
                },
                city: {
                    required,
                    maxLength: maxLength(100),
                },
                country: {
                    required,
                },
            }
        },
    },

    watch: {
        'formData.contract_pdf_delivery_location'(newValue) {
            // show/hide address save button
            if (this.isClientOrOwnerAddressSelected(newValue)) {
                // if client or owner address id selected, do no show save button
                this.$emit('show-delivery-address-save-button', { show: false })
                this.$emit('isValid', { isValid: true })
            } else if (this.isSavedAddressSelected(newValue)) {
                // if the id of the saved address is selected, do not show save button
                this.$emit('show-delivery-address-save-button', { show: false })
                this.$emit('isValid', { isValid: true })
            } else if (
                // if contract_pdf_delivery_location value is same as default value  show save button
                this.isDeliveryLocationDefault
            ) {
                this.$emit('show-delivery-address-save-button', { show: true })
                this.$emit('isValid', { isValid: !this.v$.$invalid })
            }
        },

        isAddressDataChanged(newValue) {
            // if address data is changed, show save address button
            if (newValue) {
                this.$emit('show-delivery-address-save-button', { show: true })
                this.$emit('isValid', { isValid: !this.v$.$invalid })
            } else {
                this.$emit('show-delivery-address-save-button', { show: false })
                this.$emit('isValid', { isValid: !this.v$.$invalid })
            }
        },

        'v$.$invalid'(newValue) {
            if (
                !this.isClientOrOwnerAddressSelected(
                    this.formData.contract_pdf_delivery_location
                )
            ) {
                this.$emit('isValid', { isValid: !newValue })
            }
        },
    },

    created() {
        this.bus.$on('save-address', this.handleAddressSave)
    },

    mounted() {
        this.v$ = useVuelidate(this.rules, this.$store.state.address)

        // when remounted, if there is a saved address with id
        if (
            this.formData.contract_pdf_delivery_location !== '' &&
            this.isSavedAddressSelected(
                this.formData.contract_pdf_delivery_location
            )
        ) {
            this.newAddressId = this.id
        }

        const isValid = this.formData.contract_pdf_delivery_location !== ''
        this.$emit('isValid', { isValid })
    },

    destroyed() {
        this.bus.$off('save-address')
    },

    methods: {
        ...mapActions({
            saveAddress: 'address/saveAddress',
            updateAddress: 'address/updateAddress',
        }),
        ...mapMutations({
            setAddressInput: 'address/SET_INPUT',
        }),

        fetchCountries() {
            if (!this.$store.state.country.countriesList.length) {
                this.fetchCountriesList({ vm: this })
            }
        },

        handleChange(category, key, value) {
            this.$emit('input', {
                category,
                key,
                value,
            })
        },

        handleAddressChange(key, value) {
            this.setAddressInput({
                key,
                value,
            })
            if (this.id !== null) {
                this.isAddressDataChanged = true
            }

            this.v$[key].$touch()
        },

        async handleAddressSave() {
            if (this.isAddressDataChanged) {
                await this.updateAddress({ vm: this })
                this.isAddressDataChanged = false
            } else {
                await this.saveAddress({ vm: this })
                if (this.id !== null) {
                    this.newAddressId = this.id
                    this.handleChange(
                        'formData',
                        'contract_pdf_delivery_location',
                        this.id
                    )
                }
            }
        },

        isClientOrOwnerAddressSelected(addressId) {
            const { client_address_id } =
                this.$store.state.contract.contractData
            const { oem_address_id } = this.$store.state.contract.contractData
            if (
                Number(addressId) === Number(client_address_id) ||
                Number(addressId) === Number(oem_address_id)
            ) {
                return true
            }
            return false
        },

        isSavedAddressSelected(addressId) {
            return (
                Number(this.id) === Number(addressId) &&
                !this.isClientOrOwnerAddressSelected(addressId)
            )
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_delivery {
    font-family: $vff-primary;
    color: $vc-primary;
    margin-bottom: 40px;

    & > p {
        margin-bottom: 20px;
    }

    &__radio-options {
        margin-left: 60px;
    }

    .address {
        width: 100%;
        max-width: 500px;
        display: flex;
        flex-direction: column;

        & > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__label {
            display: inline-block;
            margin-right: 12px;
            align-self: stretch;
            line-height: 30px;
        }

        &__input {
            min-width: 224px;
        }
    }
}
</style>
