import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

import Vue from 'vue'
window.Vue = Vue

export const eventBus = new Vue()
const modules = import.meta.glob('./components/base/*.vue')

Object.entries(modules).forEach(([path, m]) => {
    const componentName = upperFirst(
        camelCase(
            path
                .split('/')
                .pop()
                .replace(/\.\w+$/, '')
        )
    )

    Vue.component(componentName, m)
})
