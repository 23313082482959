<template>
    <InputSwitch
        v-model="checked"
        :class="classes"
        :name="name"
        :disabled="disabled"
    />
</template>

<script>
import InputSwitch from 'primevue/inputswitch'
import InputText from "primevue/inputtext";

export default {
    name: 'CoreInputSwitch',

    components: {
        InputText,
        InputSwitch,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },

        enlarged: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        checked: {
            get() {
                return Boolean(this.$attrs.value)
            },
            set(value) {
                this.$emit('input', value ? 1 : 0)
            },
        },

        classes() {
            return {
                'core-input-switch': true,
                'core-input-switch--enlarged': this.enlarged
            }
        },
    },
}
</script>

<style lang="scss">
.core-input-switch {
    user-select: none;
    
    .p-hidden-accessible {
        display: none;
    }

    &.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
        background-color: $cp;
    }

    &--enlarged {
        &.p-inputswitch .p-inputswitch-slider {
            width: 4.8rem;
            height: 2.4rem;
            margin: 11px 0;

            &:before {
                width: 1.6rem;
                height: 1.6rem;
                left: 0.5rem;
                margin-top: -0.8rem;
            }
        }
        &.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
            &:before {
                transform: translateX(2.3rem);
            }
        }
    }
}
</style>
