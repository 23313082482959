<template>
    <div class="inputs-additional-info">
        <CoreDropdown
            class="flex-grow-1 flex-shrink-1 flex-basis-45 mb-3"
            name="country"
            :placeholder="$t('§ui.placeholder_select_country_required')"
            :condensed="true"
            :options="$store.state.country.countryFinancingAvailableOptions"
            :validator="v$.country_id"
            :value="inputs.country_id"
            @input="setInput('country_id', $event)"
        />

        <CoreStringInput
            class="flex-grow-1 flex-shrink-1 flex-basis-45 mb-3"
            placeholder="§ui.placeholder_eq_operator_street"
            name="street"
            :condensed="true"
            :value="inputs.street"
            :validator="v$.street"
            @input="setInput('street', $event)"
        />

        <div class="flex-grow-1 flex-shrink-1 flex-basis-45 flex mb-3">
            <CoreStringInput
                class="flex-basis-40 inputs-additional-info__postal-code-input"
                placeholder="§ui.placeholder_eq_operator_postal"
                name="postal_code"
                :condensed="true"
                :validator="v$.postal_code"
                :value="inputs.postal_code"
                @input="setInput('postal_code', $event)"
            />
            <CoreStringInput
                class="flex-basis-60"
                placeholder="§ui.placeholder_eq_operator_city"
                name="city"
                :condensed="true"
                :validator="v$.city"
                :value="inputs.city"
                @input="setInput('city', $event)"
            />
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import rules from '../validationRules.js'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreDropdown from '@/components/core/CoreDropdown'

const IS_MODAL = true

export default {
    name: 'InputsAdditionalInfo',

    name: 'ClientItemModalDetails',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreDropdown,
    },

    data() {
        return { v$: {} }
    },

    computed: {
        inputs() {
            return this.$store.state.client.inputs
        },

        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules(IS_MODAL), this.$store.state.client.inputs)
        })
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_INPUT', { name, value })
        },
    },
}
</script>

<style lang="scss" scoped>
.inputs-additional-info__postal-code-input {
    width: 6.4rem;
    margin-right: 16px;
}
</style>
