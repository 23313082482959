<template>
    <div
        class="confirm-unit-type-rts-update-modal-wrapper"
        @submit.prevent="handleSave"
    >
        <BaseLabel weight="normal" size="small">
            {{ $t('unitTypes.modal_confirm_update_warning') }}
        </BaseLabel>
        <div class="buttons">
            <BaseButton buttonClass="button--danger" @click="handleSave">
                {{ $t('ui.button_update') }}
            </BaseButton>
            <BaseButton buttonClass="button--secondary" @click="handleClose">
                {{ $t('ui.button_cancel') }}
            </BaseButton>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { eventBus } from '@/main'

export default {
    name: 'ConfirmUnitTypeUpdateModal',
    methods: {
        ...mapActions({
            updateUnitType: 'unitType/updateUnitType',
        }),
        handleSave() {
            this.updateUnitType({ vm: this })
            this.$emit('close-modal')
        },
        handleClose() {
            this.$emit('close-modal')
        },
    },
}
</script>

<style lang="scss" scoped>
.confirm-unit-type-rts-update-modal-wrapper {
    padding: 40px;

    .header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 40px;

        h3 {
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 28px;

            color: rgba($vc-primary, 0.7);
        }

        .close {
            position: relative;
            width: 20px;
            height: 20px;

            cursor: pointer;

            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(45deg);
            }
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                height: 20px;
                width: 2px;
                background: rgba($vc-primary, 0.7);

                transform: rotate(-45deg);
            }
        }
    }
}
.buttons {
    width: 100%;
    display: flex;
    justify-content: flex-start;

    & > button {
        margin-right: 12px;
    }
}
</style>
