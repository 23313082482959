<template>
    <div
        v-if="isContractApproved"
        class="invoice-upload-modal-wrapper"
    >
        <div class="invoice-upload-modal-wrapper__content mb-5">

            <CoreTwoColumnsRow>
                <template #left>
                    <CoreBorderWrapper
                        :noBorder="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    >
                        {{ $t('§invoice.select_invoice_type') }}
                    </CoreBorderWrapper>
                </template>

                <template #right>
                    <CoreDropdown
                        v-model="invoice_type"
                        :options="invoiceTypes"
                        :condensed="true"
                        :fullWidth="true"
                        :filter="false"
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    />
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow>
                <template #left>
                    <CoreBorderWrapper
                        :noBorder="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    >
                        {{ $t('§invoice.set_period_begin_and_period_end') }}
                    </CoreBorderWrapper>
                </template>

                <template #right>
                    <CoreDateInput
                        v-model="dates"
                        selectionMode="range"
                        :condensed="true"
                        :fullWidth="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    />
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow>
                <template #left>
                    <CoreBorderWrapper
                        :noBorder="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    >
                        {{ $t('§invoice.amount') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <CoreCurrencyInput
                        v-model="amount"
                        :condensed="true"
                        :maxFractionDigits="2"
                        class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    />
                </template>
            </CoreTwoColumnsRow>

            <CoreTwoColumnsRow>
                <template #left>
                    <CoreBorderWrapper
                        :noBorder="true"
                        class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    >
                        {{ $t('§invoice.is_paid') }}
                    </CoreBorderWrapper>
                </template>
                <template #right>
                    <div class="invoice-upload-modal__input-switch-wrapper">
                        <div>
                            {{ $t(is_paid ? '§invoice.yes' : '§invoice.no') }}
                        </div>
                        <CoreInputSwitch
                            v-model="is_paid"
                            name="isPaid"
                        />
                    </div>
                </template>
            </CoreTwoColumnsRow>

            <DragAndDropUpload
                :fileName="displayFileName"
                :filePath="filePath"
                :fileId="fileId"
                :error="error"
                :disabled="isFileSelectDisabled"
                @delete-file="handleDeleteClick"
                class="mb-3"
            />
        </div>

        <div class="invoice-upload-modal-wrapper__buttons">
            <CoreButtonSecondary
                @click="handleClose"
                label="§ui_button.cancel"
            />

            <CoreButtonPrimary
                @click="handleUpload"
                label="§ui_button.upload"
                icon="pi-arrow-up-right"
                :disabled="isUploadButtonDisabled"
            />
        </div>
    </div>
    <div
        v-else
        class="invoice-upload-modal-wrapper"
    >
        <p class="text-200 mb-6">
            {{
                $t('§invoice.cannot_upload_invoice_as_contract_is_not_approved')
            }}
        </p>

        <div class="invoice-upload-modal-wrapper__buttons">
            <CoreButtonSecondary
                @click="handleClose"
                label="§ui_button.cancel"
            />
        </div>
    </div>
</template>

<script>
import { format, isDate } from 'date-fns'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreDropdown from '@/components/core/CoreDropdown'
import CoreCheckbox from '@/components/core/CoreCheckbox'
import CoreDateInput from '@/components/core/CoreDateInput'
import CoreCurrencyInput from '@/components/core/CoreCurrencyInput'
import CoreNumberInput from '@/components/core/CoreNumberInput'
import CoreInputSwitch from '@/components/core/CoreInputSwitch'
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'
import uppyInstance from '@/helpers/uppyInstance'
import DragAndDropUpload from '../../components/DragAndDropUpload.vue'

export default {
    name: 'InvoiceUploadModal',

    components: {
        CoreTwoColumnsRow,
        CoreBorderWrapper,
        DragAndDropUpload,
        CoreDropdown,
        CoreDateInput,
        CoreInputSwitch,
        CoreCurrencyInput,
        CoreNumberInput,
        CoreCheckbox,
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    data() {
        return {
            fileId: null,
            uppyFileName: '',
            amount: null,
            invoice_type: '',
            is_paid: false,
            dates: [],

            invoiceTypes: [
                { value: 'credit_note', label: 'Credit Note' },
                { value: 'custom', label: 'Custom' },
                { value: 'initial', label: 'Initial' },
                { value: 'second', label: 'Second' },
                { value: 'monthly', label: 'Monthly' },
                { value: 'anniversary', label: 'Anniversary' },
                { value: 'next_to_last', label: 'Next To Last' },
                { value: 'last', label: 'Last' },
            ],

            error: '',
        }
    },

    computed: {
        uppy() {
            return uppyInstance
        },

        filePath() {
            return ''
        },

        displayFileName() {
            if (this.uppyFileName !== '') {
                return this.uppyFileName
            }

            return ''
        },

        isFileSelectDisabled() {
            return (
                this.invoice_type === '' ||
                !isDate(this.dates[0]) ||
                !isDate(this.dates[1])
            )
        },

        isAmountInvalid() {
            return this.amount === null || Number(this.amount) <= 0
        },

        isFileNotSelected() {
            return this.uppyFileName === ''
        },

        isUploadButtonDisabled() {
            return (
                this.isFileNotSelected ||
                this.isAmountInvalid
            )
        },

        contract() {
            return this.$store.state.contract.contractData.id
        },

        isContractApproved() {
            const { contractData } = this.$store.state.contract
            if (contractData.approved && Number(contractData.approved) === 1) {
                return true
            }

            return false
        },
    },

    created() {
        this.uppy.on('file-added', this.handleFileAdded)
        this.uppy.on('file-removed', this.handleFileRemoved)
        this.uppy.on('upload-success', this.handleSuccess)

        this.uppy.setOptions({
            restrictions: {
                maxNumberOfFiles: 1,
                allowedFileTypes: ['.pdf'],
            },
        })
    },

    beforeDestroy() {
        this.uppy.off('file-added')
        this.uppy.off('file-removed')
        this.uppy.off('upload-success')

        this.removeUppyFiles()
        this.uppy.close({ reason: 'unmount' })
    },

    methods: {
        handleFileAdded(file) {
            this.fileId = file.id
            this.uppyFileName = file.name
            this.isSaveButtonDisabled = false
        },

        handleFileRemoved() {
            this.fileId = null
            this.uppyFileName = ''
            this.isSaveButtonDisabled = true
        },

        handleDeleteClick() {
            if (this.uppyFileName) {
                this.removeUppyFiles()
            }
        },

        formatDateForBackEnd(date) {
            return format(date, 'yyyy-MM-dd')
        },

        handleUpload() {
            const formData = {
                invoice_type: this.invoice_type,
                is_paid: this.is_paid ? 1 : 0,
                period_begin: this.formatDateForBackEnd(this.dates[0]),
                period_end: this.formatDateForBackEnd(this.dates[1]),
                amount: Number(this.amount),
            }

            this.$store.dispatch('invoices/uploadInvoice', {
                vm: this,
                uppy: this.uppy,
                contract: this.contract,
                formData,
            })
        },

        async handleSuccess(file, response) {
            if (response.status === 200) {
                this.$store.commit('modal/SET_CLOSE')
            }
        },

        removeUppyFiles() {
            const addedFiles = this.uppy.getFiles()
            addedFiles.forEach((file) => this.uppy.removeFile(file.id))
            this.uppyFileName = ''
            this.fileId = null
        },

        handleClose() {
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss" scoped>
.invoice-upload-modal-wrapper {
    padding: 40px;
    height: 100%;

    &__buttons {
        display: flex;
        justify-content: flex-end;
        gap: 12px;
    }
}

.invoice-upload-modal__payment-term {
    display: flex;
}

.invoice-upload-modal__input-switch-wrapper {
    display: flex;
    align-items: center;
    gap: 1.2rem;
}
</style>
