<template>
    <div class="contract-approval-requested-modal">
        <p class="text-200 mt-4 mb-6">
            {{
                $t('§caf.contract_approval_requested_modal-paragraph_1', [
                    contractId,
                ])
            }}
        </p>

        <div class="contract-approval-requested-modal__buttons">
            <CoreButtonSecondary
                label="§ui_button.back_to_caf"
                @click="goToCaf"
            />
            <CoreButtonPrimary
                label="§ui_button.continue_to_contract_overview"
                icon="pi-arrow-right"
                @click="goToContractView"
            />
        </div>
    </div>
</template>

<script>
import CoreButtonPrimary from '@/components/core/CoreButtonPrimary'
import CoreButtonSecondary from '@/components/core/CoreButtonSecondary'

export default {
    name: 'ContractApprovalRequestedModal',

    components: {
        CoreButtonPrimary,
        CoreButtonSecondary,
    },

    computed: {
        contractId() {
            return this.$store.state.modal.data.contractId
        },
    },

    destroyed() {
        if (this.$route.name !== 'ContractShow') {
            this.$store.commit('caf/SET_FORM_STATUS', {
                status: 'valid',
            })
        }
    },

    methods: {
        goToCaf() {
            this.$store.commit('caf/SET_FORM_STATUS', {
                status: 'valid',
            })
            this.$store.commit('modal/SET_CLOSE')
        },

        goToContractView() {
            this.$router.push({
                name: 'ContractShow',
                params: { id: this.contractId },
            })
            this.$store.commit('modal/SET_CLOSE')
        },
    },
}
</script>

<style lang="scss" scoped>
.contract-approval-requested-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__buttons {
        display: flex;
        justify-content: center;
        gap: 16px;
    }
}
</style>
