<template>
    <Message
        v-if="isShown"
        :class="classes"
        :severity="setSeverity"
        :closable="false"
        :icon="icon"
    >
        {{ $t(textContent) }}
    </Message>
</template>

<script>
import Message from 'primevue/message'

export default {
    name: 'InputNotification',

    components: { Message },

    props: {
        validator: {
            type: Object,
            default: () => ({}),
        },

        severity: {
            type: String,
            default: 'error',
        },

        customNotificationMessage: {
            type: Object,
            default: null,
        },
    },

    computed: {
        classes() {
            return {
                'input-notification': true,
            }
        },

        isShown() {
            if (this.customNotificationMessage !== null) return true

            if (
                this.validator !== null &&
                this.validator.$dirty &&
                this.validator.$error
            )
                return true

            return false
        },

        setSeverity() {
            if (
                this.customNotificationMessage !== null &&
                this.customNotificationMessage.severity !== undefined
            ) {
                return this.customNotificationMessage.severity
            }

            return this.severity
        },

        icon() {
            switch (this.setSeverity) {
                case 'warn':
                    return 'pi pi-question-circle'

                case 'success':
                    return 'pi pi-check-circle'

                case 'info':
                    return 'pi pi-info-circle'

                default:
                    return 'pi pi-exclamation-circle'
            }
        },

        textContent() {
            if (this.customNotificationMessage !== null)
                return this.customNotificationMessage.message

            const { validator } = this

            if (
                validator.minLength !== undefined &&
                validator.minLength.$invalid
            ) {
                return `${this.$t('§ui.input_error_min_length')} ${
                    validator.minLength.$params.min
                }`
            }

            if (
                validator.maxLength !== undefined &&
                validator.maxLength.$invalid
            ) {
                return `${this.$t('§ui.input_error_max_length')} ${
                    validator.maxLength.$params.max
                }`
            }

            if (
                validator.minValue !== undefined &&
                validator.minValue.$invalid
            ) {
                return `${this.$t('§ui.input_error_min_value')} ${
                    validator.minValue.$params.min
                }`
            }

            if (
                validator.maxValue !== undefined &&
                validator.maxValue.$invalid
            ) {
                return `${this.$t('§ui.input_error_max_value')} ${
                    validator.maxValue.$params.max
                }`
            }

            if (
                validator.required !== undefined &&
                validator.required.$invalid
            ) {
                return `${this.$t('§ui.input_error_required')}`
            }

            if (validator.email !== undefined && validator.email.$invalid) {
                return `${this.$t('§ui.input_error_valid_email')}`
            }

            if (
                validator.sameAsPassword !== undefined &&
                validator.sameAsPassword.$invalid
            ) {
                return `${this.$t('§ui.input_error_do_not_match_password')}`
            }

            return ''
        },
    },
}
</script>

<style lang="scss">
.input-notification {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(-8px);

    z-index: 1;

    &.p-message.p-component {
        border: none;

        &.p-message-info {
            background: $cn-75;
            color: $cs;
        }

        &.p-message-error {
            background: $color-error;
            color: $cn-50;
        }

        &.p-message-warn {
            background: $color-warning;
            color: $cs;
        }

        &.p-message-success {
            background: $cp;
            color: $cs;
        }

        & > .p-message-wrapper {
            padding: 0.75rem 1.75rem;

            & > .p-message-icon.pi {
                margin-right: 1rem;
                color: inherit;
            }

            & > .p-message-text {
                font-size: 1.4rem;
                line-height: 1.2;
            }
        }
    }
}
</style>
