<template>
    <div class="inputs-basic-info">
        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    :condensed="true"
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                >
                    {{ $t('§eq_operator.name') }}
                    {{ isFieldRequired('name') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_eq_operator_name"
                    ref="nameInput"
                    name="name"
                    :condensed="true"
                    :validator="v$.name"
                    :value="inputs.name"
                    @input="setInput('name', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.operator_industry') }}
                    {{ isFieldRequired('enduser_industry_id') }}
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreDropdown
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    name="industry"
                    :placeholder="$t('§ui.placeholder_select_industry')"
                    :condensed="true"
                    :options="$store.state.industry.endUserIndustryOptions"
                    :validator="v$.enduser_industry_id"
                    :value="inputs.enduser_industry_id"
                    @input="setInput('enduser_industry_id', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.vat_identification_number') }}
                    {{ isFieldRequired('vat_identification_number') }}
                    &nbsp;&nbsp;&nbsp;
                    <span class="text-100 font-italic">
                        {{ $t('§eq_operator.optional') }}
                    </span>
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_vat_id_number"
                    name="vat"
                    :condensed="true"
                    :validator="v$.vat"
                    :value="inputs.vat_identification_number"
                    @input="setInput('vat_identification_number', $event)"
                />
            </template>
        </CoreTwoColumnsRow>

        <CoreTwoColumnsRow>
            <template #left>
                <CoreBorderWrapper
                    class="flex-grow-1 flex-shrink-1 flex-basis-55"
                    :condensed="true"
                >
                    {{ $t('§eq_operator.company_registration_number') }}
                    {{ isFieldRequired('company_registration_number') }}
                    &nbsp;&nbsp;&nbsp;
                    <span class="text-100 font-italic">
                        {{ $t('§eq_operator.optional') }}
                    </span>
                </CoreBorderWrapper>
            </template>

            <template #right>
                <CoreStringInput
                    class="flex-grow-1 flex-shrink-1 flex-basis-45"
                    placeholder="§ui.placeholder_input_company_registration_number"
                    name="company_registration_number"
                    :condensed="true"
                    :validator="v$.vat"
                    :value="inputs.company_registration_number"
                    @input="setInput('company_registration_number', $event)"
                />
            </template>
        </CoreTwoColumnsRow>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import rules from '../validationRules.js'
import CoreBorderWrapper from '@/components/core/CoreBorderWrapper'
import CoreTwoColumnsRow from '@/components/core/CoreTwoColumnsRow'
import CoreStringInput from '@/components/core/CoreStringInput'
import CoreDropdown from '@/components/core/CoreDropdown'

const IS_MODAL = true

export default {
    name: 'InputsBasicInfo',

    components: {
        CoreBorderWrapper,
        CoreTwoColumnsRow,
        CoreStringInput,
        CoreDropdown,
    },

    data() {
        return { v$: {} }
    },

    computed: {
        inputs() {
            return this.$store.state.client.inputs
        },

        isValid() {
            return !this.v$.$invalid
        },
    },

    watch: {
        isValid: {
            handler(newValue) {
                this.$emit('validityChange', { isValid: newValue })
            },
            immediate: true,
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.v$ = useVuelidate(rules(IS_MODAL), this.$store.state.client.inputs)

            if (this.$refs.nameInput && typeof this.$refs.nameInput.focus === 'function') {
                this.$refs.nameInput.focus()
            }
        })
    },

    methods: {
        setInput(name, value) {
            this.$store.commit('client/SET_INPUT', { name, value })
        },
    },
}
</script>

<style lang="scss" scoped>
.inputs-basic-info {
    display: relative;
    z-index: 101;
}
</style>
