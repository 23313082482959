<template>
    <div class="contract_creation_final">
        <p v-if="status === 'success'">
            <template v-if="isAdmin">
                <div class="contract_creation_final__buttons">
                    <BaseButton
                        buttonClass="button--primary"
                        @click="downloadContract"
                    >
                        {{ $t('ui.button_download') }}
                    </BaseButton>
                    <BaseButton
                        buttonClass="button--primary"
                        @click="finalizePdf"
                    >
                        {{ $t('ui.contract_pdf_finalized') }}
                    </BaseButton>
                    <BaseButton
                        buttonClass="button--primary"
                        @click="sendContract(0)"
                    >
                        {{ $t('ui.button_send_to_oem') }}
                    </BaseButton>
                    <BaseButton
                        buttonClass="button--primary"
                        @click="sendContract(1)"
                    >
                        {{ $t('ui.button_send_to_lessee') }}
                    </BaseButton>
                </div>
                <div class="contract_creation_final__buttons">
                    <BaseButton
                        buttonClass="button--secondary"
                        @click="sendContract"
                    >
                        {{ $t('ui.button_send_to_oem_and_lessee') }}
                    </BaseButton>
                </div>
            </template>
            <template v-else>
                {{ $t('contract.contract_creation_last_tab-p1') }}
                <br />
                {{ $t('contract.contract_creation_last_tab-p2') }}
            </template>
        </p>
        <p v-else-if="status === 'sending'">
            <BaseTextLoader :text="loadingText" />
            {{ $t('contract.please_do_not_close_window_until_it_is_finished') }}
        </p>
        <p v-else-if="status === 'error'">
            {{
                $t(
                    'contract.contract_creation_saving_data_something_went_wrong'
                )
            }}
            <br />
            {{ $t('contract.contract_creation_saving_data_try_again') }}
        </p>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import uppyInstance from '@/helpers/uppyInstance'

export default {
    name: 'S9_Contract',
    props: {
        formData: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            status: 'sending',
            contractFileName: '',
        }
    },
    computed: {
        ...mapGetters({
            can: 'user/can',
        }),
        isAdmin() {
            return this.can('admin')
        },
        isOem() {
            return this.can('oem')
        },
        uppy() {
            return uppyInstance
        },
        loadingText() {
            return this.$t('ui.uploading_files')
        },
    },
    created() {
        this.uppy.on('upload-success', (file, response) => {
            this.status = 'success'
            this.contractFileName = response.body.contract_file_name
        })
        this.uppy.on('upload-error', () => {
            this.status = 'error'
        })
    },
    mounted() {
        this.startUpload()
    },
    beforeDestroy() {
        this.uppy.off('upload-success')
        this.uppy.off('upload-error')
    },
    methods: {
        ...mapActions({
            createContract: 'contract/createContract',
            downloadFinalContract: 'contract/downloadFinalContract',
            sendFinalContract: 'contract/sendFinalContract',
            finalizeContractPdf: 'contract/finalizeContractPdf',
        }),

        async startUpload() {
            const response = await this.createContract({
                formData: this.formData,
                vm: this,
                uppy: this.uppy,
            })

            if (response !== undefined) {
                this.status = response.status
                this.contractFileName = response.contractFileName
            }
        },

        downloadContract() {
            this.downloadFinalContract({
                vm: this,
                contractFileName: this.contractFileName,
            })
        },

        finalizePdf() {
            this.finalizeContractPdf({ vm: this })
        },

        // 0 for oem, 1 for lessee, null to both
        sendContract(trigger = null) {
            this.sendFinalContract({ vm: this, trigger })
        },
    },
}
</script>

<style scoped lang="scss">
.contract_creation_final {
    color: $vc-primary;
    margin-bottom: 40px;

    &__buttons {
        margin: 6px 0;
    }
}
</style>
