export const LOCALE_NAMES = {
    enGB: 'en-GB',
    enIE: 'en-IE',
    enUS: 'en-US',
    enEU: 'en-EU',
    deDE: 'de-DE',
    huHU: 'hu-HU',
}

export const localeOptions = (pdfLanguages = false) => {
    const localeObjects = [
        { value: LOCALE_NAMES.enGB, label: '§user.locale_option_en_GB' },
        { value: LOCALE_NAMES.enIE, label: '§user.locale_option_en_IE' },
        { value: LOCALE_NAMES.deDE, label: '§user.locale_option_de_DE' },
    ]

    if (pdfLanguages) {
        localeObjects.push({
            value: LOCALE_NAMES.huHU,
            label: '§user.locale_option_hu_HU',
        })
    } else {
        return [
            ...localeObjects,
            { value: LOCALE_NAMES.enUS, label: '§user.locale_option_en_US' },
            { value: LOCALE_NAMES.enEU, label: '§user.locale_option_en_EU' },
        ]
    }

    return localeObjects
}
