import { apiGet, apiPost, handleError, refreshError } from '@/helpers/api'

const namespaced = true

const state = () => ({
    countries: [],
    countryOptions: [],
    countryFinancingAvailableOptions: [],
})

const mutations = {
    UPDATE_COUNTRIES(state, { countries }) {
        state.countries = [...countries]
    },

    UPDATE_COUNTRIES_OPTIONS(state, { countries }) {
        const countriesList = Object.keys(countries).map((name) => {
            return {
                label: name,
                key: countries[name],
            }
        })
        state.countriesList = [...countriesList]
    },
}

const actions = {

    async toggleMasterRequirementsStatus({ dispatch, commit, state }, { vm, country }) {
        try {
            await apiGet(
                vm.route('countries.toggle_mr_status', { country })
            )
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async toggleHighRiskStatus({ dispatch, commit, state }, { vm, country }) {
        try {
            await apiGet(
                vm.route('countries.toggle_high_risk_status', { country })
            )
        } catch (error) {
            refreshError(dispatch, vm)
        }
    },

    async saveRegionalAdjustment({ dispatch, commit, state }, { vm, country, value }) {
        try {
            await apiPost(
                vm.route('countries.set_regional_adjustment', { country }),
                { regional_adjustment: value }
            )
        } catch (error) {
            handleError(dispatch, error)
        }
    },

    async saveVAT({ dispatch, commit, state }, { vm, country, value }) {
        try {
            await apiPost(
                vm.route('countries.set_vat', { country }),
                { vat: value }
            )
        } catch (error) {
            handleError(dispatch, error)
        }
    },

}

const getters = {
    getCountries: (state) => (name) =>
        state.countries.filter((country) =>
            country.name.toLowerCase().includes(name)
        ),
    getCountryListItemById: (state) => (id) =>
        state.countryOptions.filter(
            (country) => Number(country.value) === Number(id)
        )[0],
}

export default {
    namespaced,
    state,
    getters,
    mutations,
    actions,
}
